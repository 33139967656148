(function() {

  'use strict';

  var userGroupCardList = {
    templateUrl: 'app/entities/prop-admin/user-console/user-group-card-list.html',
    bindings: {
      userGroups: '<',
      user: '<',
      onRemoveUserGroup: '&',
      onAddUserGroup: '&',
      propertyId: '<'
    },
    controller: 'UserGroupCardListController',
  }

  angular
    .module('tyallsApp')
    .component('userGroupCardList', userGroupCardList); 
  ;
}());
