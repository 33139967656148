(function() {
  'use strict';

  UserInviteService.$inject = ['$resource'];

  function UserInviteService($resource) {
    var service = $resource('api/properties/invite-user', {}, {});

    return service;
  }

  angular
    .module('tyallsApp')
    .factory('UserInviteService', UserInviteService);
})();
