/*
 * Routed Component
 */
(function () {

    'use strict';

    var audit = {
        templateUrl: 'app/entities/audit/audit.html',
        bindings: {
            properties: '<',   // [THIS.RESOLVE]
        },
        controller: 'AuditController',
    };

    angular
        .module('tyallsApp')
        .component('audit', audit)
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('app.audit', {
                    url: '/audit',
                    component: 'audit',
                    data: {
                        authorities: [],
                        pageTitle: 'Audit'
                    },
                    resolve: {
                        properties: ['PropertyService', function (PropertyService) {
                            return PropertyService.getAllPropertiesForUser();
                        }]
                    }
                });

        }]);
})();
