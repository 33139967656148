(function() {
  'use strict';

  PropertyProfileService.$inject = ['$http'];
  function PropertyProfileService($http) {
    var service = {
      getAllPropertyProfilesForAdmin: getAllPropertyProfilesForAdmin,
      query: query,
      getWithPropertyId: getWithPropertyId,
    };

    return service;

    function getWithPropertyId(propertyId) {
      return $http.get('/api/properties/' + propertyId + '/profiles');
    }

    function getAllPropertyProfilesForAdmin() {
      // GET /admin/property-profiles
      return $http.get('/admin/property-profiles');
    }

    function query() {
      return getAllPropertyProfilesForAdmin();
    }
  }

  angular
    .module('tyallsApp')
    .factory('PropertyProfileService', PropertyProfileService);
})();
