(function() {

  'use strict';

  UploadFileService.$inject = ['$http', '$sce', 'Upload', 'getHeaderFilename', '$log', '$document', 'moment', '$window'];

  function UploadFileService($http, $sce, Upload, getHeaderFilename, $log, $document, moment, $window) {
    var service = {
      addUploadFile: addUploadFile,
      getUploadFile: getUploadFile,
      getUploadStream: getUploadStream,
      handleUpload: handleUpload,
      handleSupportingUpload: handleSupportingUpload,
      deleteSupportingFile: deleteSupportingFile
    };

    return service;

    function getUploadFile(propertyId, uploadFileId) {
      // GET /api/properties/{propertyId}/files/{uploadFileId}
      return $http.get('/api/properties/' + propertyId + '/files/' + uploadFileId, {responseType:'arraybuffer'})
        .then(function onSuccess(response) {
          var file = new Blob([(response.data)], {type: 'application/pdf'});
          var fileURL = URL.createObjectURL(file);
          return $sce.trustAsResourceUrl(fileURL);
          // return file;
        });
    }

    function getUploadStream(propertyId, supportingFileId) {
      // GET /api/properties/{propertyId}/supporting-files/{supportingFileId}
      // This is different from getUploadFile by virtue of not-restricted-to-pdf
      // This is used for the supporting files
      return $http.get('/api/properties/' + propertyId + '/supporting-files/' + supportingFileId, {
        responseType:'arraybuffer',
        transformResponse: function(data, headers) {
          return {
            data: data,
            filename: getHeaderFilename(headers)
          };
        }
      })
        .success(function(result) {

          if ($window.navigator.msSaveOrOpenBlob) {
            $window.navigator.msSaveOrOpenBlob(new Blob([result.data]), result.filename);
          }
          else {
            var url = URL.createObjectURL(new Blob([result.data]));
            var a = $document[0].createElement('a');
            a.href = url;
            a.download = result.filename;
            a.target = '_blank';
            a.click();
          }
        })
        .error(function(error) {
          $log.error(error.data);
        });
    }

    function addUploadFile(file, propertyId, documentRecordId) {
      var url = '/api/properties/' + propertyId + '/document-records/' + documentRecordId;
      var fields = {};
      return handleUploadHelper(file, url, fields);
    }


    function handleUpload(propertyId, workflowId, dueDate, file) {
      // POST /api/files
      var url = '/api/properties/' + propertyId + '/files';
      var fields = { 
        'workflowId': workflowId,
        'dueDate': moment(dueDate).format('YYYY-MM-DD')
      };
      return handleUploadHelper(file, url, fields);
    }

    function handleSupportingUpload(propertyId, documentRecordId, file) {
      // POST /api/properties/{propertyId}/supporting-files
      var url = '/api/properties/' + propertyId + '/supporting-files';
      var fields = { 
        'documentRecordId': documentRecordId 
      };
      return handleUploadHelper(file, url, fields);
    }

    function handleUploadHelper(file, url, fields) {
      return Upload.upload({
        url: url, 
        fields: fields,
        file: file
      }).progress(function(evt) {
        var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
        $log.info('progress: ', progressPercentage + '% ' + evt.config.file.name);
      }).success(function(data, status, headers, config) {
        $log.info('file ' + config.file.name + 'uploaded. Response: ' + data);
      });
    }

    function deleteSupportingFile(id) {
      $http.delete('/api/supporting-files' + id)
        .success(function(result) {
        })
        .error(function(error) {
        });
    }
  }

  angular 
    .module('tyallsApp')
    .factory('UploadFileService', UploadFileService);
})();
