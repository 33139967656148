(function () {

    'use strict';

    UploadPrimaryDocumentDialogController.$inject = ['onUploadPrimaryDocument', '$uibModalInstance'];

    function UploadPrimaryDocumentDialogController(onUploadPrimaryDocument, $uibModalInstance) {
        var ctrl = this;

        ctrl.$onInit = function () {
            ctrl.newPrimaryDocument = {};

            ctrl.serverMessage = undefined;
            ctrl.onUploadPrimaryDocument = onUploadPrimaryDocument.value;
        }

        ctrl.clear = function () {
            $uibModalInstance.dismiss('cancel');
        }

        ctrl.save = function () {
            ctrl.isSaving = true;

            ctrl.onUploadPrimaryDocument({
                primaryDocument: ctrl.newPrimaryDocument,
            })
                .then(onSaveSuccess)
                .catch(onSaveError)
                .finally(function () {
                    ctrl.isSaving = false;
                });

        }

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
        }

        function onSaveError(reason) {
            ctrl.serverMessage = reason.data.message;
        }

    }

    angular.module('tyallsApp')
        .controller('UploadPrimaryDocumentDialogController', UploadPrimaryDocumentDialogController);

})();
