/*
 * Routed Component
 */
(function() {
  'use strict';

  var appAdmin = {
    templateUrl: 'app/entities/app-admin/app-admin.html',
    bindings: {
    },
    controller: 'AppAdminController'
  };

  angular
    .module('tyallsApp')
    .component('appAdmin', appAdmin)
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider
        .state('app.app-admin', {
          url: '/app-admin',
          redirectTo: 'app.app-admin.user-admin-console',
          data: {
            authorities: [],
            pageTitle: 'AppAdmin'
          },
          views: {
            '@^': 'appAdmin'
          },
        })

    }]);
})();
