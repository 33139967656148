/*
 * Modal Routed Component
 */
(function() {
  'use strict';

  angular
    .module('tyallsApp')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider
        .state('app.prop.prop-admin.user-console.list-user.confirm-remove-property-profile-dialog', {
          // url: '',  // modal -- no url
          params: {
            onPositiveConfirm: null,
            titleText: null,
            bodyText: null,
          }, // non-url params
          resolve: {
            titleText: ['$transition$', function($transition$) {
              return $transition$.params().titleText;
            }],
            bodyText: ['$transition$', function($transition$) {
              return $transition$.params().bodyText;
            }],
            onPositiveConfirm: ['$transition$', function($transition$) {
              return $transition$.params().onPositiveConfirm;
            }]
          },
          data: {
            authorities: []
          },
          onEnter: ['onPositiveConfirm', 'titleText', 'bodyText', '$state', '$uibModal', function(onPositiveConfirm, titleText, bodyText, $state, $uibModal) {
            $uibModal.open({   
              templateUrl: 'app/components/util/confirm-modal.html',
              controller: 'ConfirmModalController',
              controllerAs: '$ctrl',
              backdrop: 'static',   
              size: 'md',      
              resolve: {
                // passing native Strings, so need objects
                titleText: { value: titleText },
                bodyText: { value: bodyText },
                onPositiveConfirm: { value: onPositiveConfirm },
              }                
            }).result.then(function() {
              $state.go('^');
            }, function() {    
              $state.go('^');  
            });
          }]
        }) 

    }]);

})();  
