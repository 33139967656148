(function() {
  'use strict'

  PropertySelectorController.$inject = ['$cookies'];

  function PropertySelectorController($cookies) {
    var ctrl = this;

    ctrl.$onInit = function () {
      ctrl.disabled = false;

      ctrl.defaultOptionText = '-- select a property --';
      ctrl.defaultPropertyId = angular.copy(Number(ctrl.defaultPropertyId));
      if (ctrl.idToPropertyLookup) {
        if (ctrl.idToPropertyLookup.length === 1) {
          var p = ctrl.idToPropertyLookup[0].id
          ctrl.defaultPropertyId = p;
          ctrl.selectProperty(p);
        } else if ($cookies.get('selectedProp')) {

        } else if (ctrl.defaultPropertyId) {
          var q = ctrl.idToPropertyLookup.filter(function (value) {
            return value.id === +ctrl.defaultPropertyId;
          })[0].id;
          ctrl.defaultPropertyId = q;
          ctrl.selectProperty(q);
        }
      }
    }

    ctrl.selectProperty = function(property) {
      var propId = Number(property)
      $cookies.put('selectedProp', propId);
      ctrl.onSelectProperty({
        $event: {
          property: propId,
        }
      });
    }

    ctrl.$onChanges = function(changes) {
      // watch the defaultPropertyId
      if( changes.defaultPropertyId && changes.defaultPropertyId.currentValue ) {
        ctrl.defaultPropertyId = Number(changes.defaultPropertyId.currentValue);
      }

      if (!!changes.idToPropertyLookup && !!changes.idToPropertyLookup.currentValue && !changes.idToPropertyLookup.isFirstChange()) {
        if (changes.idToPropertyLookup.currentValue.length ===1) {
          ctrl.selectProperty(changes.idToPropertyLookup.currentValue[0].id);
          ctrl.defaultPropertyId = changes.idToPropertyLookup.currentValue[0].id;

        }
        ctrl.idToPropertyLookup = angular.copy(changes.idToPropertyLookup.currentValue);
      }

    }
  }

  angular
    .module('tyallsApp')
    .controller('PropertySelectorController', PropertySelectorController);
}());
