(function () {

    'use strict';

    PrimaryDocumentViewerController.$inject = ['$timeout', 'CacheFactory'];

    function PrimaryDocumentViewerController($timeout, CacheFactory) {
        var ctrl = this;

        ctrl.$onInit = function () {
            ctrl.update = false;
            ctrl.isEditing = false;
            ctrl.isSaving = false;
            ctrl.annotationsToRender = ctrl.savedAnnotations ? ctrl.savedAnnotations.slice() : [];
        };

        ctrl.$onChanges = function (changes) {
            if (changes.savedAnnotations && changes.savedAnnotations.currentValue) {
                ctrl.annotationsToRender = angular.copy(changes.savedAnnotations.currentValue);
            }
        };

        ctrl.clearCache = function () {
            var cache = CacheFactory.get("appCache");
            if (cache) {
                cache.removeAll()
            }
        }

        ctrl.clearUnsavedAnnotations = function () {
            // has this actually changed?
            ctrl.update = true;

            if (ctrl.savedAnnotations.length === ctrl.annotationsToRender.length) return;
            ctrl.annotationsToRender = ctrl.savedAnnotations.slice();
        };

        ctrl.saveAnnotations = function () {
            ctrl.update = false;
            ctrl.isEditing = false;
            ctrl.isSaving = true;
            $timeout(function () {
                    ctrl.onSaveAnnotations({
                        annotations: ctrl.annotationsToRender
                    })
                }, 2000
            ).then(function () {
                ctrl.isSaving = false;
                ctrl.clearCache();
            });
        };

        ctrl.editClick = function () {
            ctrl.isEditing = true;
        };

        ctrl.commentClick = function () {
            ctrl.isEditing = true;
        };

        ctrl.appliedSaveButtonClass = function () {
            if (ctrl.isEditing) {
                return 'btn btn-primary';
            } else {
                return 'btn btn-default';
            }
        }
    }

    angular
        .module('tyallsApp')
        .controller('PrimaryDocumentViewerController', PrimaryDocumentViewerController);
})();

