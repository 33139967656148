(function () {
    'use strict';

    TaskController.$inject = ['$state',
        '$q',
        'AnnotationService',
        'bsLoadingOverlayService',
        'TaskDTOService',
        'UploadFileService',
        '$location',
        '$anchorScroll',
        'CommentService',
        'CacheFactory'];

    function TaskController($state, $q, AnnotationService,
                            bsLoadingOverlayService, TaskDTOService,
                            UploadFileService, $location, $anchorScroll,
                            CommentService, CacheFactory) {

        var ctrl = this;

        ctrl.$onInit = function () {
            ctrl.toggle = true;

            ctrl.onResolveDisabled({
                $event: {
                    disabled: true
                }
            });

            ctrl.onResolveProperties({
                $event: {
                    properties: ctrl.properties
                }
            });

            ctrl.selectTask(ctrl.tasks[0]);
            if (ctrl.account) {
                ctrl.username = ctrl.account.username;
            }
        }

        ctrl.clearCache = function () {
            var cache = CacheFactory.get("appCache");
            if (cache) {
                cache.removeAll()
            }
        }

        ctrl.goToUploadPrimaryDocument = function () {
            $state.go('app.prop.task.upload-primary-document-dialog', {
                propertyId: ctrl.currentTaskDTO.propertyId,
                documentRecordId: ctrl.currentTaskDTO.documentRecord.id,
                onUploadPrimaryDocument: ctrl.uploadPrimaryDocument,
            });
        }

        ctrl.uploadPrimaryDocument = function ($event) {
            return UploadFileService.addUploadFile(
                $event.primaryDocument,
                ctrl.currentTaskDTO.propertyId,
                ctrl.currentTaskDTO.documentRecord.id
            )
                .then(function () {
                    ctrl.selectTask(ctrl.currentTaskDTO);
                });
        }

        ctrl.doReturn = function () {
            ctrl.clearCache();
            ctrl.returnTo();
        }

        ctrl.toggleNav = function () {
            ctrl.toggle = !ctrl.toggle;
        }


        ctrl.doPositiveReview = function () {
            return TaskDTOService
                .completeTask(
                    ctrl.currentTaskDTO.propertyId,
                    ctrl.currentTaskDTO.id)
                .then(function (response) {
                    // update the ctrl.tasks array
                    angular.forEach(ctrl.tasks, function (task) {
                        if (task.id === ctrl.currentTaskDTO.id) {
                            task.completed = true;
                        }
                    });

                    ctrl.doReturn();
                })
                .catch(function (reason) {

                });
        }

        ctrl.createComment = function ($event) {

            $event.comment.createdBy = ctrl.username;
            $event.comment.documentRecord = ctrl.currentTaskDTO.documentRecord.id;

            return CommentService
                .saveComment(
                    ctrl.currentTaskDTO.propertyId,
                    $event.comment)
                .then(function (response) {
                    ctrl.currentTaskDTO.documentRecord.comments.push(response.data);
                });
        }

        ctrl.createSupportingDocument = function ($event) {

            $event.supportingDocument.createdBy = ctrl.username;
            $event.supportingDocument.createdDate = moment().format('YYYY-MM-DDTHH:mm:ss');

            return ctrl.doUpload($event.supportingDocument);
        }

        ctrl.doUpload = function (newSupportingDoc) {
            return UploadFileService
                .handleSupportingUpload(
                    ctrl.currentTaskDTO.propertyId,
                    ctrl.currentTaskDTO.documentRecord.id,
                    newSupportingDoc.selectedFile
                )
                .success(function (response) {
                    ctrl.uploading = false;
                    ctrl.currentTaskDTO.documentRecord.supportingFiles.push(
                        {
                            createdDate: response.createdDate,
                            createdBy: newSupportingDoc.createdBy,
                            id: response.id,
                            filename: response.filename
                        }
                    );
                });
        }

        ctrl.selectTask = function (task) {
            bsLoadingOverlayService.start();

            // populate task summary
            ctrl.currentTaskDTO = task;

            // fetch the taskDTO
            TaskDTOService
                .getTaskDTO(
                    ctrl.currentTaskDTO.propertyId,
                    ctrl.currentTaskDTO.id
                )
                .then(function onTaskDTOSuccess(response) {
                    ctrl.currentTaskDTO = response.data;
                    ctrl.formatedDueDate = ctrl.currentTaskDTO.dueDate.format('D MMM yyyy')

                }, function onTaskDTOFailure(response) {
                    ctrl.currentTaskDTO = null;
                    $log.error('oops1');
                    bsLoadingOverlayService.stop();
                })
                .then(function onSuccess() {

                    // if there is an uploadfile associated with this, fetch it
                    if (!!ctrl.currentTaskDTO.documentRecord.uploadFile) {
                        UploadFileService
                            .getUploadFile(
                                ctrl.currentTaskDTO.propertyId,
                                ctrl.currentTaskDTO.documentRecord.uploadFile.id
                            )
                            .then(function onSuccess(response) {
                                ctrl.currentFileData = response;

                                // scroll into view
                                var newHash = 'review-section';
                                if ($location.hash() !== newHash) {
                                    //                $location.hash('review-section');
                                } else {
                                    $anchorScroll();
                                }

                                bsLoadingOverlayService.stop();
                            }, function onFailure() {
                                $log.error('oops');
                                bsLoadingOverlayService.stop();
                            });
                    }

                    bsLoadingOverlayService.stop();
                });
        }


        ctrl.saveAnnotations = function (annotations) {
            // find the annotations in the currentTaskDTO
            var newAnnotations = annotations.filter(function (el) {
                return el.hasOwnProperty('id') === false;
            });

            var existingAnnotations = annotations.filter(function (el) {
                return el.hasOwnProperty('id') === true;
            });

            // set the uploadFile id
            angular.forEach(newAnnotations, function (value) {
                value.uploadFile = ctrl.currentTaskDTO.documentRecord.uploadFile.id;
            })

            var promises = [];
            for (var i = 0; i < newAnnotations.length; i++) {
                promises.push(AnnotationService.saveAnnotation(ctrl.currentTaskDTO.propertyId, newAnnotations[i]));
            }

            $q.all(promises)
                .then(function onSuccess(values) {

                    angular.forEach(values, function (value) {
                        existingAnnotations.push(value.data);
                    });
                    ctrl.currentTaskDTO.documentRecord.uploadFile.annotations = existingAnnotations;
                });

        }
    }

    angular
        .module('tyallsApp')
        .controller('TaskController', TaskController);
})();
