(function() {
  'use strict';


  AppAdminCreateScheduleDialogController.$inject = ['ProcessDefinitionDTOService', '$scope', 'onCreateSchedule', 'properties', '$uibModalInstance'];

  function AppAdminCreateScheduleDialogController(ProcessDefinitionDTOService, $scope, onCreateSchedule, properties, $uibModalInstance) {
    var ctrl = this;

    ctrl.$onInit = function() {
      ctrl.selectedSchedule = {
      };
      ctrl.timezoneId = null;
      ctrl.workflowDefinitionKeys = [];
      ctrl.serverMessage = undefined;
      ctrl.properties = properties.value;
      ctrl.onCreateSchedule = onCreateSchedule.value;
    }

    $scope.$watch(function() { return ctrl.selectedSchedule.propertyId}, function(newValue) {
      if (newValue) {
        ProcessDefinitionDTOService
          .getAllNonDailyProcessDefinitionDTO(newValue)
          .then(function(response) {
            (response.data);
            ctrl.workflowDefinitions = [];
            angular.forEach(response.data, function(value) {
              ctrl.workflowDefinitions.push(value.key);
            });

            // set the timezoneid
            ctrl.timezoneId = ctrl.properties.filter(function(item){ return item.id === newValue; })[0].timezoneId;

            (ctrl.timezoneId);


          })
          .catch(function(reason) {

          });
      }
    });

    ctrl.clear = function() {
      $uibModalInstance.dismiss('cancel');
    }

    ctrl.save = function() {
      ctrl.isSaving = true;

      ctrl.onCreateSchedule({
        schedule: ctrl.selectedSchedule
      })
        .then(onSaveSuccess)
        .catch(onSaveError)
        .finally(function() {
          ctrl.isSaving = false;
        });
    }

    function onSaveSuccess (result) {
      $uibModalInstance.close(result);
    }

    function onSaveError (reason) {
      ctrl.serverMessage = reason.data.message;
    }
  }

  angular
    .module('tyallsApp')
    .controller('AppAdminCreateScheduleDialogController', AppAdminCreateScheduleDialogController);
})();
