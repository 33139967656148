(function () {

    'use strict';

    InboxController.$inject = ['$state',
        'TaskDTOService',
        '$q',
        '$log',
        '$filter',
        'CacheFactory'];

    function InboxController($state, TaskDTOService, $q, $log, $filter, CacheFactory) {
        // function InboxController() {
        var ctrl = this;

        ctrl.$onInit = function () {
        }

        ctrl.getGroups = function () {
            return $filter('filter')(ctrl.account.userGroups, {property: ctrl.selectedPropertyIdRefresh}, true);
        }

        ctrl.requestClaimAll = function () {
            var titleText = 'Confirm Claim All';
            var bodyText = 'Are you sure you want to claim these ' + ctrl.inboxTasks.length + " tasks?";
            // confirm that the user actually want this
            $state.go('app.prop.reviewer-month.confirm-claim-all', {
                titleText: titleText,
                bodyText: bodyText,
                onPositiveConfirm: ctrl.claimAll
            });
        }

        ctrl.invalidateCache = function () {
            console.log("Clearing Cache!");
            var cache = CacheFactory.get("appCache");
            if (cache) {
                console.log("Cache Cleared!");
                cache.removeAll()
            }
        }
        ctrl.claimAll = function () {
            // need to return a promise
            var promises = [];
            angular.forEach(ctrl.inboxTasks, function (task) {
                this.push(ctrl.claim(task));
                ctrl.invalidateCache();
            }, promises);

            return $q.all(promises);
        }

        ctrl.claim = function (task) {
            return TaskDTOService.claim(task.propertyId, task.id)
                .then(function () {
                        // remove from the list
                        var index = ctrl.inboxTasks.indexOf(task);
                        ctrl.inboxTasks.splice(index, 1);
                        // propagate this back up to the parent controller
                        ctrl.onClaim({
                            $event: {
                                task: task
                            }
                        });
                        ctrl.invalidateCache();
                    },
                    function () {
                        alert('Unable to claim task, please try again or contact support')
                    }).finally(function () {
                    ctrl.invalidateCache();
                });
        }

        ctrl.$onChanges = function (changes) {
            // if (changes.selectedPropertyIdRefresh && !changes.selectedPropertyIdRefresh.isFirstChange()) {
            if (changes.selectedPropertyIdRefresh.currentValue) {
                ctrl.inboxTasks = [];
                var promisesByGroup = [];

                var filteredUserGroups = ctrl.getGroups();

                angular.forEach(filteredUserGroups, function (userGroup) {
                    $log.debug("UserGroup: ", userGroup);
                    var promise = TaskDTOService.getCandidateTasksForGroup(userGroup.id);
                    this.push({'userGroup': userGroup, 'promise': promise});
                }, promisesByGroup);

                var promises = [];
                angular.forEach(promisesByGroup, function (value) {
                    this.push(value.promise);
                }, promises);

                $q.all(promises)
                    .then(function onSuccess(resp) {
                        // loop over array of results
                        angular.forEach(resp, function (valueArray, index) {
                            // loop over each of the DTOs
                            angular.forEach(valueArray.data, function (taskDTO) {
                                // enrich with group
                                taskDTO.group = promisesByGroup[index].userGroup;
                                this.push(taskDTO);
                            }, ctrl.inboxTasks);
                        });
                    });
            }
        }

    }

    angular
        .module('tyallsApp')
        .controller('InboxController', InboxController);

})();
