(function() {

  'use strict';

  UserConsoleController.$inject = ['UserService'];

  function UserConsoleController(UserService, users) {
    var ctrl = this;

    ctrl.$onInit = function () {
      if (users) {
        ctrl.users = users;
        console.log('Users in ' + users);
      }
    }

    ctrl.$onChanges = function (changes) {
      if (changes.propertyId) {
        UserService.getWithPropertyId(ctrl.propertyId)
            .then(function (response) {
              ctrl.users = response.data;
            })
            .catch()
        ;
      }

    }

  }

  angular
    .module('tyallsApp')
    .controller('UserConsoleController', UserConsoleController);
}());

