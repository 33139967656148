(function() {

  'use strict';

  var userGroupConsole = {
    templateUrl: 'app/entities/app-admin/user-group-console/user-group-console.html',
    bindings: {
      propertyId: '<',
    },
    controller: 'UserGroupConsoleController',
  }

  angular
    .module('tyallsApp')
    .component('userGroupConsole', userGroupConsole)
  ;
}());
