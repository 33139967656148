(function () {
    'use strict';

    var primaryDocumentViewer = {
        templateUrl: 'app/entities/shared/primary-document-viewer/primary-document-viewer.html',
        bindings: {
            account: '<',
            savedAnnotations: '<',
            onSaveAnnotations: '&',
            data: '<',
            documentId: '<',
            updateUi: '=',
            isSaving: '=?'
        },
        controller: 'PrimaryDocumentViewerController',
    };

    angular
        .module('tyallsApp')
        .component('primaryDocumentViewer', primaryDocumentViewer)
    ;
})();
