(function() {
  'use strict';

  AppController.$inject = [];

  function AppController() {
    var ctrl = this;

    ctrl.$onInit = function() {
    }

  };

  angular
    .module('tyallsApp')
    .controller('AppController', AppController);
}());
