(function () {

    'use strict';

    var wfdAdminConsole = {
        templateUrl: 'app/entities/app-admin/wfd-admin-console/wfd-admin-console.html',
        bindings: {
            // users: '<',
            properties: '<',
            workflowDefinitions: '<',
        },
        controller: 'WfdAdminConsoleController',
    }

    angular
        .module('tyallsApp')
        .component('wfdAdminConsole', wfdAdminConsole)
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('app.app-admin.wfd-admin-console', {
                    url: '/wfds',
                    views: {
                        'wfd-admin-console@^': {
                            component: 'wfdAdminConsole',
                        }
                    },
                    resolve: {
                        properties: ['PropertyService', function (PropertyService) {
                            return PropertyService.getAllPropertiesForAdmin()
                                .then(function (response) {
                                    return response.data;
                                });
                        }],
                        workflowDefinitions: ['WorkflowDefinitionService', function (WorkflowDefinitionService) {
                            return WorkflowDefinitionService.getAllWfds()
                                .then(function (response) {
                                    return response.data;
                                });
                        }],
                    }
                })

        }]);
}());
