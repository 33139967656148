(function () {

    'use strict';

    var inviteConsole = {
        templateUrl: 'app/entities/app-admin/invite-console/invite-console.html',
        bindings: {
            propertyId: '<',
        },
        controller: 'InviteConsoleController',
    }

    angular
        .module('tyallsApp')
        .component('inviteConsole', inviteConsole)
    ;
}());
