(function () {
    'use strict';

    angular.module('tyallsApp', [
        'ngStorage',
        'ui.router',
        'bsLoadingOverlay',
        'bsLoadingOverlaySpinJs',
        'ui.bootstrap',
        'ngAnimate',
        'ngFileUpload',
        'ngResource',
        'ngMessages',
        'ngCacheBuster',
        'ui.select',
        'ngSanitize',
        'smart-table',
        'ngCookies',
        'angular-cache',
    ])
        .run(run);

    run.$inject = ['$sce',
        '$transitions',
        'stateHandler',
        'bsLoadingOverlayService',
        '$anchorScroll',
        '$http',
        'CacheFactory'
    ];

    function run($sce, $transitions, stateHandler, bsLoadingOverlayService, $anchorScroll, $http, CacheFactory) {
        stateHandler.initialize();

        var cache = CacheFactory('appCache', {
            maxAge: 15 * 60 * 1000, // Items added to this cache expire after 5 minutes
            cacheFlushInterval: 30 * 60 * 1000, // This cache will clear itself every hour
            deleteOnExpire: 'aggressive', // Items will be deleted from this cache when they expire
            storageMode: 'localStorage',
            onExpire: function (key, value) {
                $http.get(key).success(function (data) {
                    cache.put(key, data);
                });
            }
        });

        $http.defaults.cache = cache

        bsLoadingOverlayService.setGlobalConfig({
            templateUrl: 'app/loading-overlay-template.html',
        });

        $anchorScroll.yOffset = 50; // scroll by 50 extra pixels

        $transitions.onStart({}, function (trans) {
            var bsLoadingOverlayService = trans.injector().get('bsLoadingOverlayService');
            bsLoadingOverlayService.start();
            trans.promise.finally(function () {
                bsLoadingOverlayService.stop();
            });
        });

    }
})();

