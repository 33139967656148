(function() {
  'use strict';


  AnnotationService.$inject = ['$http'];
  function AnnotationService($http) {
    var service = {
      saveAnnotation: saveAnnotation,
    };

    return service;

    function saveAnnotation(propertyId, annotation) {
      // POST /api/properties/{propertyId}/annotations
      return $http.post('/api/properties/' + propertyId + '/annotations', annotation);
    }
  }

  angular
    .module('tyallsApp')
    .factory('AnnotationService', AnnotationService);
})();
