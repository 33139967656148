(function () {

    'use strict';

    SharedDateCreateCommentDialogController.$inject = ['onCreateComment',
        '$uibModalInstance',
        'candidates',
        'CacheFactory'];

    function SharedDateCreateCommentDialogController(onCreateComment, $uibModalInstance, candidates, CacheFactory) {
        var ctrl = this;

        ctrl.$onInit = function () {
            ctrl.candidates = candidates;

            ctrl.newComment = {
                createdDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
                mentions: []
            };

            ctrl.serverMessage = undefined;
            ctrl.onCreateComment = onCreateComment.value;
        }

        ctrl.clearCache = function () {
            var cache = CacheFactory.get("appCache");
            if (cache) {
                cache.removeAll()
            }
        }

        ctrl.addMention = function (mention) {
            ctrl.newComment.mentions.push(mention);
        }

        ctrl.deleteMention = function (mention) {
            var idx = ctrl.newComment.mentions.map(function (e) {
                return e.userId;
            }).indexOf(mention.userId);
            if (idx >= 0) {
                ctrl.newComment.mentions.splice(idx, 1);
            }
        }

        ctrl.clear = function () {
            $uibModalInstance.dismiss('cancel');
        }

        ctrl.save = function () {
            ctrl.isSaving = true;

            ctrl.onCreateComment({
                comment: ctrl.newComment,
            })
                .then(onSaveSuccess)
                .catch(onSaveError)
                .finally(function () {
                    ctrl.isSaving = false;
                    ctrl.clearCache();
                });
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
        }

        function onSaveError(reason) {
            ctrl.serverMessage = reason.data.message;
        }
    }

    angular.module('tyallsApp')
        .controller('SharedDateCreateCommentDialogController', SharedDateCreateCommentDialogController);

})();
