/*
 * Modal Routed Component
 */
(function () {
    'use strict'

    angular
        .module('tyallsApp')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('app.prop.task-list.tasklist-primary-document-dialog', {
                    // url: '',  // modal -- no url
                    params: {
                        onUploadPrimaryDocument: null,
                    }, // non-url params
                    resolve: {
                        onUploadPrimaryDocument: ['$transition$', function ($transition$) {
                            return $transition$.params().onUploadPrimaryDocument;
                        }],
                    },
                    onEnter: ['$state', '$uibModal', 'onUploadPrimaryDocument', function ($state, $uibModal, onUploadPrimaryDocument) {
                        $uibModal.open({
                            templateUrl: 'app/entities/shared/tasklist-primary-document-dialog/tasklist-primary-document-dialog.html',
                            controller: 'TaskListPrimaryDocumentDialogController',
                            controllerAs: '$ctrl',
                            backdrop: 'static',
                            size: 'md',
                            resolve: {
                                onUploadPrimaryDocument: {value: onUploadPrimaryDocument},
                            }
                        }).result.then(function (result) {
                            $state.go('^');
                        }, function () {
                            $state.go('^');
                        });
                    }]
                })
        }]);

}());
