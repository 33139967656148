/*
 * Modal Routed Component
 */
(function() {
  'use strict';

  angular
    .module('tyallsApp')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider
        .state('app.app-admin.create-schedule-dialog', {
          // url: '/user/create',  // modal -- no url
          params: {
            onCreateSchedule: null,
          },
          resolve: {
            properties: [ 'PropertyService', function( PropertyService ) {
              return PropertyService.getAllPropertiesForAdmin()
                .then(function(response) {
                  return response.data; 
                });
            }],
            onCreateSchedule: ['$transition$', function($transition$) {
              return $transition$.params().onCreateSchedule;
            }]

          },
          data: {
            authorities: []
          },
          onEnter: ['onCreateSchedule', 'properties', '$state', '$uibModal', function(onCreateSchedule, properties, $state, $uibModal) {
            $uibModal.open({
              templateUrl: 'app/entities/app-admin/create-schedule-dialog/app-admin-create-schedule-dialog.html',
              controller: 'AppAdminCreateScheduleDialogController',
              controllerAs: '$ctrl',
              backdrop: 'static',
              size: 'md',
              resolve: {
                properties: { value: properties },
                onCreateSchedule: { value: onCreateSchedule },
              }
            }).result.then(function(response) {
              $state.go('^');
            }, function() {
              $state.go('^');
            });
          }]
        })

    }]);

})();
