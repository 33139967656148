/*
 * Routed Component
 */
(function () {
    'use strict';

    var app = {
        templateUrl: 'app/app.html',
        bindings: {
            account: '<',  // [THIS.RESOLVE]
        },
        controller: 'AppController'
    };

    angular
        .module('tyallsApp')
        .component('app', app)
        .config(['$stateProvider', function ($stateProvider) {

            $stateProvider
                .state('app', {
                    abstract: true,
                    component: 'app',
                    resolve: {
                        account: ['PrincipalService', function (PrincipalService) {
                            return PrincipalService.identity();
                        }]
                    }
                });
        }]);
}());
