(function() {

  'use strict';
  
  UserGroupConsoleController.$inject = [ 'UserGroupService', 'PropertyService', 'bsLoadingOverlayService' ];

  function UserGroupConsoleController( UserGroupService, PropertyService, bsLoadingOverlayService  ) {
    var ctrl = this; 

    ctrl.$onInit = function() {
      ctrl.userGroups = [];
      ctrl.idToPropertyLookup = {};

      bsLoadingOverlayService.start();
      PropertyService
        .getAllPropertiesForAdmin()
        .then( function(response) {
          ctrl.idToPropertyLookup = {};
          angular.forEach(response.data, function(value) {
            this[value.id] = value;
          }, ctrl.idToPropertyLookup);
        })
        .catch(function(reason) {
            console.log('oops');
        })
        .finally(function() {
          bsLoadingOverlayService.stop();
        });
    }

    ctrl.$onChanges = function(changes) {
      if (changes.propertyId) {
        // refresh the userGroup data
        bsLoadingOverlayService.start();

        UserGroupService
          .getWithPropertyId(changes.propertyId.currentValue)
          .then( function(response) {
            ctrl.userGroups = response.data; 
          })
          .catch(function(response) {
            console.log('oops');
          })
          .finally(function() {
            bsLoadingOverlayService.stop();
          });
      }; 
    }
  }

  angular
    .module('tyallsApp')
    .controller('UserGroupConsoleController', UserGroupConsoleController);
}());

