(function() {
  'use strict';

  isAssignee.$inject = ['PrincipalService'];

  function isAssignee(PrincipalService) {
    var directive = {
      restrict: 'A',
      link: linkFunc
    };

    return directive;

    function linkFunc(scope, element, attrs) {

      attrs.$observe('isAssignee', function(newAssignee) {
        if (angular.isDefined(newAssignee)) {
          var assignee = newAssignee.replace(/\s+/g, '');

          if (assignee.length > 0) {
            defineVisibility(true, assignee);

            scope.$watch(function() {
              return PrincipalService.isAuthenticated();
            }, function() {
              defineVisibility(true, assignee);
            });
          }
        }
      });

      var setVisible = function () {
        element.removeClass('disabled');
      };
      var setHidden = function () {
        element.addClass('disabled');
      };
      var defineVisibility = function (reset, assignee) {

        if (reset) {
          setVisible();
        }

        // get the username from the principal
        PrincipalService.hasUsername(assignee)
          .then(function (result) {
            if (result) {
              setVisible();
            } else {
              setHidden();
            }
          });
      };

    }
  }

  angular
    .module('tyallsApp')
    .directive('isAssignee', isAssignee);

})();
