(function() {

  'use strict';

  ListAuditBasketController.$inject = [];

  function ListAuditBasketController() {
    var ctrl = this; 

    ctrl.$onInit = function() {
    } 

    ctrl.$onChanges = function(changes) {
    }     

  }       

  angular 
    .module('tyallsApp')
    .controller('ListAuditBasketController', ListAuditBasketController);
}());

