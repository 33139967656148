(function () {
    'use strict';

    angular
        .module('tyallsApp')
        .factory('stateHandler', stateHandler);

    stateHandler.$inject = ['$rootScope', '$state', '$window', 'AuthService', 'PrincipalService', 'VERSION', '$injector'];

    function stateHandler($rootScope, $state, $window, AuthService, PrincipalService, VERSION, $injector) {
        return {
            initialize: initialize
        };

        function initialize() {

            $state.defaultErrorHandler(function (error) {

                if (error.type === 6) {
                    if (error.detail.status === 401) {
                        console.log("API call got 401, attempting login")
                        var LoginService = $injector.get('LoginService');
                        LoginService.open();
                    }
                } else {
                    console.error(error);
                }
            });
            $rootScope.VERSION = VERSION;

            var stateChangeStart = $rootScope.$on('$stateChangeStart', function (event, toState, toStateParams, fromState) {
                $rootScope.toState = toState;
                $rootScope.toStateParams = toStateParams;
                $rootScope.fromState = fromState;

                // Redirect to a state with an external URL (http://stackoverflow.com/a/30221248/1098564)
                if (toState.external) {
                    event.preventDefault();
                    $window.open(toState.url, '_self');
                }

                if (PrincipalService.isIdentityResolved()) {
                    AuthService.authorize();
                }
            });

            var stateChangeSuccess = $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
                // scroll to the top of the page
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            });

            $rootScope.$on('$destroy', function () {
                if (angular.isDefined(stateChangeStart) && stateChangeStart !== null) {
                    stateChangeStart();
                }
                if (angular.isDefined(stateChangeSuccess) && stateChangeSuccess !== null) {
                    stateChangeSuccess();
                }
            });
        }
    }
})();
