(function() {
  'use strict';

  ScheduleService.$inject = ['$http'];

  function ScheduleService($http) {
    var service = {
      createSchedule: createSchedule, 
    };

    return service;

    function enrich(response) {
      // fix up the membership field from the propertyProfiles
      var newResponse = angular.copy(response);
      if (response.data) {

        // loop over users
        angular.forEach(response.data, function(user, index) {
          newResponse.data[index].memberships = [];
          // loop over propertyProfiles
          angular.forEach(user.propertyProfiles, function(propertyProfile) {
            if (propertyProfile.profileName === 'USER') {
              newResponse.data[index].memberships.push(angular.copy(propertyProfile));
            } 
          });
        })
      }
      return newResponse;
    }

    function getWithPropertyId(propertyId) {
      return $http.get('/api/users?propertyId=' + propertyId)
        .then( function(response) {

          var enrichedResponse = enrich(response);
          return Promise.resolve(enrichedResponse); 
        })
      ;
    }
    
    function getAuditorsWithPropertyId(propertyId) {
      return $http.get('/api/users?propertyId=' + propertyId + "&filter=auditor")
        .then( function(response) {
          return Promise.resolve(response.data); 
        })
      ;
    }

    function getWithAppAdminPropertyId(propertyId) {
      return $http.get('/admin/users?propertyId=' + propertyId)
        .then( function(response) {

          var enrichedResponse = enrich(response);
          return Promise.resolve(enrichedResponse); 
        })
      ;
    }
    
    function listByUserAndProperty(propertyId) {
      return $http.get('/api/users?propertyId=' + propertyId);
    }

    function removeUserFromUserGroup(userId, userGroupId) {
      // DELETE /admin/users/userId/user-groups/userGroupId
      return $http.delete('/api/users/' + userId + '/user-groups/' + userGroupId);
    }

    function removeUserFromPropertyProfile(userId, propertyProfileId) {
      // DELETE /admin/users/userId/property-profiles/propertyProfileId
      return $http.delete('/api/users/' + userId + '/property-profiles/' + propertyProfileId);
    }

    function get(id) {
      // GET /api/users/id
      return $http.get('/api/users/' + id);
    }

    function query() {
      // GET /admin/users
      return $http.get('/admin/users');
    }

    function createSchedule(schedule) {
      return $http.post('/admin/schedules', schedule);
    }

    function updateUser(user) {
      return $http.put('/admin/users/', user);
    }

    function disableUser(user) {
      return $http.put('/admin/users/' + user.id + '/disable');
    }

    function enableUser(user) {
      return $http.put('/admin/users/' + user.id + '/enable');
    }

    function addUserToUserGroup(userId, userGroupId) {
      return $http.put('/api/users/' + userId + '/user-groups/' + userGroupId);
    }

    function addUserToPropertyProfile(userId, propertyProfileId) {
      return $http.put('/api/users/' + userId + '/property-profiles/' + propertyProfileId);
    }
  }

  angular
    .module('tyallsApp')
    .factory('ScheduleService', ScheduleService);

})();
