(function() {

  'use strict';

  UserGroupCardController.$inject = ['$state'];

  function UserGroupCardController($state) {
    var ctrl = this; 
    
    ctrl.requestRemoveUserGroup = function(userGroup) {
      var titleText = 'Confirm User Group removal';
      var bodyText = 'Are you sure you want to remove user (' + ctrl.user.username + ') from group (' + userGroup.name + ')?';
      // confirm that the user actually want this
      $state.go('.confirm-remove-user-group-dialog', {
        titleText: titleText,
        bodyText: bodyText,
        onPositiveConfirm: ctrl.removeUserGroup
      });  
    }
    
    ctrl.removeUserGroup = function($event) {
      return ctrl.onRemoveUserGroup({
        $event: {
          userGroupId: ctrl.userGroup.id,
        }
      });
    }
  }

  angular
    .module('tyallsApp')
    .controller('UserGroupCardController', UserGroupCardController);
}());

