(function() {

  'use strict';

  PropertyProfileCardController.$inject = ['$state'];

  function PropertyProfileCardController($state) {
    var ctrl = this; 

    ctrl.requestRemovePropertyProfile = function(propertyProfile) {
      var titleText = 'Confirm Property Profile removal';
      var bodyText = 'Are you sure you want to remove user (' + ctrl.user.username + ') from property profile (' + propertyProfile.profileName + ')?';
      // confirm that the user actually want this
      $state.go('.confirm-remove-property-profile-dialog', {
        titleText: titleText,
        bodyText: bodyText,
        onPositiveConfirm: ctrl.removePropertyProfile
      });  
    }

    ctrl.removePropertyProfile = function($event) {
      return ctrl.onRemovePropertyProfile({
        $event: {
          propertyProfileId: ctrl.propertyProfile.id
        }
      });
    }
  }

  angular
    .module('tyallsApp')
    .controller('PropertyProfileCardController', PropertyProfileCardController);
}());

