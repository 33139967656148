(function() {

  'use strict';

  var userConsole = {
    templateUrl: 'app/entities/prop-admin/user-console/user-console.html',
    bindings: {
      propertyId: '<',         // [PARENT.RESOLVE]
    },
    controller: 'UserConsoleController',
  }

  angular
    .module('tyallsApp')
    .component('userConsole', userConsole)
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider
        .state('app.prop.prop-admin.user-console', {
          url: '/users',
          redirectTo: 'app.prop.prop-admin.user-console.list-user',
          views: {
            'user-console@^': {
              component: 'userConsole',
            }
          },
        })

    }]);
}());
