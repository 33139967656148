(function () {

    'use strict';

    var mentionDetail = {
        templateUrl: 'app/entities/shared/create-comment-dialog/mention-detail.html',
        bindings: {
            mention: '<',
            onDeleteMentionUserId: '&',
            idToUserLookup: '<',
        },
        controller: 'MentionDetailController',
    }

    angular
        .module('tyallsApp')
        .component('mentionDetail', mentionDetail)
    ;
})();
