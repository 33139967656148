/*
 * Modal Routed Component
 */
(function () {
    'use strict';

    angular
        .module('tyallsApp')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('app.app-admin.create-wfd-dialog', {
                    // url: '/user/create',  // modal -- no url
                    params: {
                        onCreateWfd: null,
                    },
                    resolve: {
                        properties: ['PropertyService', function (PropertyService) {
                            return PropertyService.getAllPropertiesForAdmin()
                                .then(function (response) {
                                    return response.data;
                                });
                        }],
                        onCreateWfd: ['$transition$', function ($transition$) {
                            return $transition$.params().onCreateWfd;
                        }]

                    },
                    data: {
                        authorities: []
                    },
                    onEnter: ['onCreateWfd', 'properties', '$state', '$uibModal', function (onCreateWfd, properties, $state, $uibModal) {
                        $uibModal.open({
                            templateUrl: 'app/entities/app-admin/create-wfd-dialog/app-admin-create-wfd-dialog.html',
                            controller: 'AppAdminCreateWfdDialogController',
                            controllerAs: '$ctrl',
                            backdrop: 'static',
                            size: 'md',
                            resolve: {
                                properties: {value: properties},
                                onCreateWfd: {value: onCreateWfd},
                            }
                        }).result.then(function (response) {
                            $state.go('^');
                        }, function () {
                            $state.go('^');
                        });
                    }]
                })

        }]);

})();
