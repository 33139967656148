(function () {
    'use strict';

    angular
        .module('tyallsApp')
        .factory('delayInterceptor', delayInterceptor);

    delayInterceptor.$inject = ['$timeout'];

    function delayInterceptor($timeout) {
        return {
            response:
                function (response) {
                    console.log(response.config.url);

                    // only put a delay for specific URLS
                    if (response.config.url.match(/user-wfi-summary/g)) {
                        return $timeout(function () {
                            return response;
                        }, 2500);
                    } else {
                        return response;
                    }
                }
        }
    }
})();
