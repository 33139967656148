(function() {

  'use strict';

  var propertyProfileCard = {
    templateUrl: 'app/entities/prop-admin/user-console/property-profile-card.html',
    bindings: {
      user: '<',
      propertyProfile: '<',
      onRemovePropertyProfile: '&'
    },
    controller: 'PropertyProfileCardController',
  }

  angular
    .module('tyallsApp')
    .component('propertyProfileCard', propertyProfileCard)
  ;
}());
