(function() {
  'use strict';

  PasswordResetFinishService.$inject = ['$resource'];

  function PasswordResetFinishService($resource) {
    var service = $resource('api/account/reset-password/finish', {}, {});

    return service;
  }

  angular
    .module('tyallsApp')
    .factory('PasswordResetFinishService', PasswordResetFinishService);

})();
