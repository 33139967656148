(function () {
    'use strict';


    TaskListController.$inject = ['$state',
        'UploadFileService',];

    function TaskListController($state, UploadFileService) {

        var ctrl = this;

        ctrl.$onInit = function () {

            ctrl.currentTaskDTO = null;

            ctrl.onResolveDisabled({
                $event: {
                    disabled: true
                }
            });

            ctrl.onResolveProperties({
                $event: {
                    properties: ctrl.properties
                }
            });

            ctrl.tasks = ctrl.setDetailData(ctrl.tasks);
            console.log("Date " + ctrl.date);
            console.log("Task Date " + ctrl.taskDate);
            ctrl.formatedDate = moment(ctrl.taskDate).format('dddd, MMMM Do YYYY')
        }

        ctrl.doReturn = function () {
            ctrl.returnTo();
        }

        ctrl.doPrevious = function () {
            var previousDate = moment(ctrl.taskDate).subtract(1, "days").format('YYYY-MM-DD');
            ctrl.onIteration({
                date: previousDate
            });
        }

        ctrl.doNext = function () {
            var nextDate = moment(ctrl.taskDate).add(1, "days").format('YYYY-MM-DD');
            ctrl.onIteration({
                date: nextDate
            });
        }

        var returnTo = function (propertyId, date, tasks, onIteration) {
            console.log("Property ID " + propertyId + "Date " + date);
            console.log(tasks);
            return function () {
                $state.go('app.prop.task-list', {
                    'propertyId': propertyId,
                    'date': date,
                    'tasks': tasks,
                    'returnTo': function () {
                        ctrl.doReturn();
                    },
                    'onIteration': onIteration
                });
            }
        };

        ctrl.goToTaskDetail = function (task) {
            //console.log(task);
            //console.log("Task Due Date" +task.dueDate)
            var date = task.dueDate.format('YYYY-MM-DD');
            //console.log("Task Due Date formated " +date)
            $state.go('app.prop.task', {
                'propertyId': ctrl.propertyId,
                'date': date,
                'tasks': new Array(task),
                'returnTo': returnTo(
                    ctrl.propertyId,
                    ctrl.taskDate,
                    ctrl.tasks,
                    ctrl.onIteration),
            });
        }

        ctrl.goToUploadPrimaryDocument = function (task) {
            ctrl.currentTaskDTO = task;
            $state.go('app.prop.task-list.tasklist-primary-document-dialog', {
                propertyId: ctrl.propertyId,
                documentRecordId: task.documentId,
                onUploadPrimaryDocument: ctrl.uploadPrimaryDocument,
            });
        }

        ctrl.uploadPrimaryDocument = function ($event) {
            return UploadFileService.addUploadFile(
                $event.primaryDocument,
                ctrl.currentTaskDTO.propertyId,
                ctrl.currentTaskDTO.documentId
            ).then(function () {
                ctrl.currentTaskDTO.primaryDocument = true;
            });
        }

        ctrl.setDetailData = function (detailData) {
            // need to filter the tasks so that only the most
            // 'relevant' is shown.  This should be the 'head' of
            // the workflow
            var tasks = angular.copy(detailData);
            // group tasks by processId
            var groupedTasks = {};
            angular.forEach(detailData, function (value) {

                value.lastReviewTimestamp = moment(value.lastReviewTimestamp).format('YYYY-MM-DD HH:mm')
                if (!(value.processInstanceId in groupedTasks)) {
                    groupedTasks[value.processInstanceId] = value;
                } else {

                    // populate with latest non completed task
                    if (value.completed === false) {
                        groupedTasks[value.processInstanceId] = value;
                    } else {
                        // true cannot override false
                        var thisTask = groupedTasks[value.processInstanceId];
                        if (thisTask.completed === true && (new Date(thisTask.completedTimestamp) < new Date(value.completedTimestamp))) {
                            groupedTasks[value.processInstanceId] = value;
                        }
                    }
                }

            }, groupedTasks);

            detailData = [];
            for (var o in groupedTasks) {
                detailData.push(groupedTasks[o]);
            }
            return detailData;
        }
    }

    angular
        .module('tyallsApp')
        .controller('TaskListController', TaskListController);
})();
