(function () {
    'use strict';

    DailySummaryService.$inject = ['$http', '$filter', '$log'];


    function DailySummaryService($http, $filter, $log) {
        var service = {
            getDailySummary: getDailySummary,

            getDailyTasks: getDailyTasks,
            getDailyTasksReporter: getDailyTasksReporter,
            getDailySummaryForProperty: getDailySummaryForProperty,
            getDailySummaryDetailForProperty: getDailySummaryDetailForProperty,
            getTaskForMention: getTaskForMention,
        };

        return service;

        function getDailySummaryForProperty(propertyId, start, end) {
            // GET /api/properties/{propertyId}/wfi-summary?start={yyyy-mm-dd}&end={yyyy-mm-dd}
            return $http.get('/api/properties/' + propertyId + '/wfi-summary?start=' + start + '&end=' + end, {cache: false}).then(function onSuccess(resp) {
                return processResponse(resp);
            });
        }

        function getDailySummaryDetailForProperty(propertyId, start, end) {
            // GET /api/properties/{propertyId}/wfi-summary-detail?start={yyyy-mm-dd}&end={yyyy-mm-dd}
            return $http.get('/api/properties/' + propertyId + '/wfi-summary-detail?start=' + start + '&end=' + end, {cache: false}).then(function onSuccess(resp) {
                return processResponse(resp);
            }, function onFailure(error) {
                $log.error("Unable to get Daily Summary for " + propertyId);
                $log.error(error);
                return {};
            });
        }

        function getDailySummary(userId, propertyId, start, end) {
            // GET /api/properties/{propertyId}/user-wfi-summary?start={yyyy-mm-dd}&end={yyyy-mm-dd}
            return $http.get('/api/properties/' + propertyId + '/user-wfi-summary?start=' + start + '&end=' + end, {cache: false}).then(function onSuccess(resp) {
                return processResponse(resp);
            }, function onFailure(error) {
                $log.error('oops');
                $log.error(error);
            });
        }

        function getTaskForMention(propertyId, taskId) {
            // GET /api/properties/{propertyId}/user-wfi-summary?id={taskId}
            return $http.get('/api/properties/' + propertyId + '/user-wfi-detail?id=' + taskId, {cache: false}).then(function onSuccess(resp) {
                return resp;
            }, function onFailure(error) {
                $log.error('oops');
                $log.error(error);
            });
        }

        function getDailyTasks(userId, propertyId, date) {
            // GET /api/properties/{propertyId}/user-wfi-detail?date={yyyy-mm-dd}
            return $http.get('/api/properties/' + propertyId + '/user-wfi-detail?date=' + date, {cache: false}).then(function onSuccess(resp) {
                // format dates
                angular.forEach(resp.data, function (task) {

                    task.dueDate = moment().parseZone(task.dueDate);
                });
                return resp;
            }, function onFailure(error) {
                $log.error('oops');
                $log.error(error);
            });
        }

        function getDailyTasksReporter(propertyId, date) {
            // GET /api/properties/{propertyId}/user-wfi-detail?date={yyyy-mm-dd}
            // return $http.get('/api/properties/' + propertyId + '/task-detail?date=' + date).then( function onSuccess(resp) {
            return $http.get('/api/properties/' + propertyId + '/user-wfi-detail?date=' + date + '&reporter=true', {cache: false}).then(function onSuccess(resp) {
                // format dates
                angular.forEach(resp.data, function (task) {
                    task.dueDate = moment().parseZone(task.dueDate);
                });
                return resp;
            }, function onFailure(error) {
                $log.error('oops');
                $log.error(error);
            });
        }

        function processResponse(resp) {
            // process the data to have the correct lookups
            var processedData = {};

            angular.forEach(resp.data, function (value) {
                var modifiedResult = {};

                if (angular.isDefined(typeof value)) {
                    modifiedResult.taskCount = value.taskCount;
                    modifiedResult.wiCount = 0;
                    var wiCompleted = {};
                    var wiNonCompleted = {};
                    var wiOverdue = {};

                    //The below is some magic to get the right time zone data

                    var dueDate = moment.parseZone(value.dueDate);
                    var nowAtProperty = moment().utcOffset(dueDate.utcOffset());
                    var daysTillDue = dueDate.diff(nowAtProperty, 'days');

                    if (value.summaryStatus === 'OUTSTANDING') {


                        if (daysTillDue >= -5) {
                            modifiedResult.summary = 'fa-cogs text-warning';
                        } else {
                            modifiedResult.summary = 'fa-exclamation-triangle text-danger';

                        }
                    }
                    if (value.summaryStatus === 'COMPLETE') {
                        modifiedResult.summary = 'fa-check text-success';
                    }

                    // add original data to modifiedResult
                    modifiedResult.data = value;

                    modifiedResult.data.dueDate = moment.parseZone(value.dueDate);
                    // process the dates to be java Date types
                    angular.forEach(modifiedResult.data.tasks, function (task) {
                        // store keys as unique processInstances; values as whether all task in processInstance are complete
                        var taskDueDate = moment.parseZone(task.dueDate);
                        var daysTillTaskDue = taskDueDate.diff(nowAtProperty, 'days');
                        if (task.completed === true) {
                            wiCompleted[task.processInstanceId] = (wiCompleted[task.processInstanceId] || 0) + 1;
                        } else {
                            if (daysTillTaskDue >= -5) {
                                wiNonCompleted[task.processInstanceId] = (wiNonCompleted[task.processInstanceId] || 0) + 1;

                            } else {
                                wiOverdue[task.processInstanceId] = (wiOverdue[task.processInstanceId] || 0) + 1;

                            }
                        }

                        task.dueDate = taskDueDate;
                    });

                    // summarise the 3 hashes
                    var wiSummary = {};
                    angular.forEach(wiCompleted, function (value, key) {
                        wiSummary[key] = 'COMPLETED';
                    });
                    angular.forEach(wiNonCompleted, function (value, key) {
                        wiSummary[key] = 'NONCOMPLETED';
                    });
                    angular.forEach(wiOverdue, function (value, key) {
                        wiSummary[key] = 'OVERDUE';
                    });

                    modifiedResult.wiCount = Object.keys(wiSummary).length;
                    modifiedResult.wiCompletedCount = countForValue(Object.values(wiSummary), 'COMPLETED');
                    modifiedResult.wiNonCompletedCount = countForValue(Object.values(wiSummary), 'NONCOMPLETED');
                    modifiedResult.wiOverdueCount = countForValue(Object.values(wiSummary), 'OVERDUE');
                }
                var dateString = $filter('date')(modifiedResult.data.dueDate.format('yyyy-MM-DD'), 'yyyy-MM-dd');
                console.log(dateString)
                this[dateString] = modifiedResult;
            }, processedData);

            return processedData;
        }

        function countForValue(arry, value) {
            var result = 0;
            for (var x = 0; x < arry.length; x++) {
                if (arry[x] === value) {
                    result++;
                }
            }
            return result;

        }
    }

    angular
        .module('tyallsApp')
        .factory('DailySummaryService', DailySummaryService);

})();
