(function () {
    'use strict';

    var wfiSelector = {
        templateUrl: 'app/entities/shared/wfi-selector/wfi-selector.html',
        bindings: {
            tasks: '<',
            currentTaskDto: '<',
            onSelectTask: '&',
        },

    }

    angular
        .module('tyallsApp')
        .component('wfiSelector', wfiSelector)
    ;
})();
