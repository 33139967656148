(function() {

	'use strict';

	AuditBasketViewerController.$inject = [];

	function AuditBasketViewerController() {
		var ctrl = this; 

		ctrl.$onInit = function() {
			ctrl.onResolveDisabled({
				$event: {
					disabled: false
				}
			});

			ctrl.onResolveProperties({
				$event: {
					properties: ctrl.properties
				}
			});

		} 

		ctrl.$onChanges = function(changes) {
		}
	}

	angular
		.module('tyallsApp')
		.controller('AuditBasketViewerController', AuditBasketViewerController);
}()); 
