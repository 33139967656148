(function() {

  'use strict';

  var scheduleAdminConsole = {
    templateUrl: 'app/entities/app-admin/schedule-admin-console/schedule-admin-console.html',
    bindings: {
      // users: '<',
      properties: '<',
    },
    controller: 'ScheduleAdminConsoleController',
  }

  angular
    .module('tyallsApp')
    .component('scheduleAdminConsole', scheduleAdminConsole) 
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider
        .state('app.app-admin.schedule-admin-console', {
          url: '/schedules',
          views: {
            'schedule-admin-console@^': {
              component: 'scheduleAdminConsole',
            }
          },
          resolve: {
            properties: [ 'PropertyService', function( PropertyService ) {
              return PropertyService.getAllPropertiesForAdmin()
                .then(function(response) {
                  return response.data; 
                });
            }],
            // users: [ 'UserService', function( UserService ) {
            //
            //   return UserService.query()
            //     .then(function(response){
            //       return response.data; 
            //     });
            // }],
          }
        })

    }]);
}());
