(function () {

    'use strict';

    DailyLauncherController.$inject = [
        'WorkflowInstanceService',
        'ProcessDefinitionDTOService',
        'UploadFileService',
        'bsLoadingOverlayService',
        'CacheFactory'];

    function DailyLauncherController(WorkflowInstanceService,
                                     ProcessDefinitionDTOService,
                                     UploadFileService,
                                     bsLoadingOverlayService,
                                     CacheFactory) {
        var ctrl = this;

        ctrl.$onInit = function () {
            ctrl.selectedDueDate = null;
            ctrl.launched = [];
            ctrl.launchList = [];
            ctrl.availableWfds = [];

            activate();
        }

        ctrl.clearCache = function () {
            console.log("Clearing Cache!");
            var cache = CacheFactory.get("appCache");
            if (cache) {
                console.log("Cache Cleared!");
                cache.removeAll()
            }
        }
        ctrl.hasConfirmedLaunchRequest = function () {
            if (!ctrl.launchRequests) return false;

            // confirmed launch;
            var confirmedLaunch = false;

            angular.forEach(ctrl.launchRequests, function (request) {
                confirmedLaunch = confirmedLaunch || (request.confirm && request.confirm === true);
            }, confirmedLaunch);

            return confirmedLaunch;
        }

        var activate = function () {
            ctrl.launchRequests = [];

            // build out the launchRequests collection
            angular.forEach(ctrl.availableWfds, function (value, key) {
                var request = {};
                request.processDefinitionId = value.id;
                request.processDefinitionName = value.name;
                request.isLaunched = ctrl.isLaunched(request) ? 'Launched' : 'No';
                this.push(request);

            }, ctrl.launchRequests);

        }

        ctrl.doUpload = function () {
            var promises = [];
            try {
                for (var key in ctrl.launchRequests) {
                    try {
                        if (!!ctrl.launchRequests[key].confirm) {
                            ctrl.launchRequests[key].status = 'launching...';
                            UploadFileService
                                .handleUpload(
                                    ctrl.propertyId,
                                    ctrl.launchRequests[key].processDefinitionId,
                                    ctrl.selectedDueDate,
                                    ctrl.launchRequests[key].file)
                                .then(function (response) {
                                    angular.forEach(ctrl.launchRequests, function (request) {
                                        if (request.processDefinitionId === response.data.processDefinitionId) {
                                            request.status = 'done';
                                            request.isLaunched = 'Launched';
                                            reset(response.data.processDefinitionId);
                                        }
                                    });
                                    ctrl.clearCache();
                                });
                        }
                    } catch (e) {
                        console.log("Error Doing Upload " + key, e)
                    }
                }
            } catch (e) {
                console.log("Error Doing Upload", e)
            } finally {
                ctrl.clearCache();
            }
        }

        var reset = function (processDefinitionId) {
            angular.forEach(ctrl.launchRequests, function (request) {
                if (request.processDefinitionId === processDefinitionId) {
                    request.confirm = false;
                }
            });
        }

        ctrl.selectProperty = function (event) {
            // ctrl.selectedProperty = ctrl.property.filter(function(value) { return value.id === event.propertyId})[0];
        }

        ctrl.getLaunchedWfis = function () {
            // fetch the ProcessDefinitions
            bsLoadingOverlayService.start();
            ProcessDefinitionDTOService
                .getAllDailyProcessDefinitionDTO(ctrl.propertyId)
                .then(function (response) {
                    ctrl.availableWfds = response.data;
                })
                .catch(function (reason) {

                });

            WorkflowInstanceService
                .getWorkflowInstanceByPropertyAndDueDate(ctrl.propertyId, ctrl.selectedDueDate)
                .then(function (response) {
                    ctrl.launched = response.data;
                    activate();
                })
                .catch(function (reason) {
                    console.log('oops %o', reason);
                })
                .finally(function () {
                    bsLoadingOverlayService.stop();
                });

        }

        ctrl.back = function () {

            ctrl.availableWfds = [];
        };

        ctrl.dateSearch = function () {
            ctrl.getLaunchedWfis();
        }

        ctrl.isLaunched = function (available) {
            if (available === null || ctrl.launched === null) return false;

            return ctrl
                .launched
                .filter(function (value) {
                    return value.processDefinitionId === available.processDefinitionId;
                }).length > 0;

        }
    }

    angular
        .module('tyallsApp')
        .controller('DailyLauncherController', DailyLauncherController);
})();
