(function() {
  'use strict';

  isAdmin.$inject = ['PrincipalService'];

  function isAdmin(PrincipalService) {
    var directive = {
      restrict: 'A',
      link: linkFunc
    };

    return directive;

    function linkFunc(scope, element, attrs) {

      var setVisible = function () {
        element.removeClass('hidden');
      },
        setHidden = function () {
          element.addClass('hidden');
        },
        defineVisibility = function (reset) {

          if (reset) {
            setVisible();
          }

          // PrincipalService.isAdmin(authority)
          PrincipalService.isAdmin()
            .then(function (result) {
              if (result) {
                setVisible();
              } else {
                setHidden();
              }
            });
        };

      defineVisibility(true);

      scope.$watch(function() {
        return PrincipalService.isAuthenticated();
      }, function() {
        defineVisibility(true);
      });
    }
  }
  
  angular
    .module('tyallsApp')
    .directive('isAdmin', isAdmin);

})();
