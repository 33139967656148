(function() {
  'use strict';

  AppAdminDetailUserDialogController.$inject = ['onAddUserToProperty', 'onRemoveUserFromProperty', 'user', 'properties', '$uibModalInstance'];

  function AppAdminDetailUserDialogController(onAddUserToProperty, onRemoveUserFromProperty, user, properties, $uibModalInstance) {
    var ctrl = this;

    ctrl.$onInit = function() {
      ctrl.selectedProperty = {
      };

      ctrl.serverMessage = undefined;
      ctrl.user = user.value;

      // only show properties not already a member
      ctrl.properties = properties.value.filter(function(obj) {
    return !ctrl.user.propertyProfiles.some(function(obj2) {
        return obj.id == obj2.propertyId && obj2.profileName === 'USER';
    });
});
      ctrl.onAddUserToProperty = onAddUserToProperty.value;
      ctrl.onRemoveUserFromProperty = onRemoveUserFromProperty.value;

      ctrl.propertyLookup = {};
      properties.value.forEach(function(value) {
        ctrl.propertyLookup[value.id] = value;
      });
    }

    ctrl.clear = function() {
      $uibModalInstance.dismiss('cancel');
    }

    ctrl.removeUserFromProperty = function(propertyId) {
      ctrl.isSaving = true;

      ctrl.onRemoveUserFromProperty({
        user: ctrl.user,
        property: ctrl.propertyLookup[propertyId]
      })
        .then(onSaveSuccess)
        .catch(onSaveError)
        .finally(function() {
          ctrl.isSaving = false;
        });
    }

    ctrl.save = function() {
      ctrl.isSaving = true;

      ctrl.onAddUserToProperty({
        user: ctrl.user,
        property: ctrl.propertyLookup[ctrl.selectedProperty.propertyId]
      })
        .then(onSaveSuccess)
        .catch(onSaveError)
        .finally(function() {
          ctrl.isSaving = false;
        });
    }

    function onSaveSuccess (result) {
      $uibModalInstance.close(result);
    }

    function onSaveError (reason) {
      ctrl.serverMessage = reason.data.message;
    }
  }

  angular
    .module('tyallsApp')
    .controller('AppAdminDetailUserDialogController', AppAdminDetailUserDialogController);
})();
