(function () {
    'use strict';

    InviteController.$inject = ['InviteService'];

    function InviteController(InviteService) {
        var ctrl = this;

        ctrl.$onInit = function () {

            InviteService
                .query()
                .then(function (response) {
                    ctrl.invites = response.data;
                })
                .catch(function (reason) {

                });
        }

        ctrl.accept = function (invite) {
            InviteService
                .acceptInvite(invite)
                .then(function (response) {
                })
                .catch(function (reason) {

                });
        }

    }

    angular
        .module('tyallsApp')
        .controller('InviteController', InviteController);

})();
