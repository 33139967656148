(function() {
    'use strict';

    AddWorkflowInstanceDialogController.$inject = ['onSuccessfulEdit', 'AuditService', 'availableWorkflowDefinitions', '$uibModalInstance'];

    function AddWorkflowInstanceDialogController(onSuccessfulEdit, AuditService, availableWorkflowDefinitions, $uibModalInstance) {
        var ctrl = this;

        ctrl.$onInit = function() {
            ctrl.workflowInstances = [];
            ctrl.availableWorkflowDefinitions = availableWorkflowDefinitions;

            ctrl.serverMessage = undefined;
            ctrl.onSuccessfulEdit = onSuccessfulEdit.value;
        };

        ctrl.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        ctrl.back = function() {

            ctrl.workflowInstances = [];
        };

        ctrl.doSearch = function() {

      // get all the selected wfds
            var wfds = [];
            angular.forEach(ctrl.availableWorkflowDefinitions, function (value) {
                if (value.selected === true) {
                    this.push(value.id);
                }
            }, wfds);

            var propCodes = [];
            for (var i = 0; i < wfds.length; i++) {
                var propCode = wfds[i].split("-")[0];
                propCodes.push(propCode);
            }
            var criteria = {
                'properties': propCodes,
                'workflowDefinitionIds': wfds,
                'startDate': moment(ctrl.startDate).format('DD/MM/YYYY'),
                'endDate': moment(ctrl.endDate).format('DD/MM/YYYY'),
            };


            return AuditService.searchAudit(criteria)
          .then(function (response) {
              ctrl.workflowInstances = response.data;  
          })
        .catch(function(reason){

        });
        };

        ctrl.save = function() {
            ctrl.isSaving = true;

            var selectedWfis = [];

            angular.forEach( ctrl.workflowInstances, function(value) {
                if (value.selected === true) {
                    this.push(value);
                }
            }, selectedWfis);
      
            ctrl.onSuccessfulEdit({
                workflowInstances: selectedWfis, 
            })
        .then(onSaveSuccess)
        .catch(onSaveError)
        .finally(function() {
            ctrl.isSaving = false;
        });
        };

        function onSaveSuccess (result) {
            $uibModalInstance.close(result);
        }

        function onSaveError (reason) {
            ctrl.serverMessage = reason.data.message;
        }

    }

    angular
    .module('tyallsApp')
    .controller('AddWorkflowInstanceDialogController', AddWorkflowInstanceDialogController);
})();
