(function() {

  'use strict';

  AuditorConsoleController.$inject = [];

  function AuditorConsoleController() {
    var ctrl = this; 

    ctrl.$onInit = function() {
    }
    
    ctrl.$onChanges = function(changes) {
    }
  }
  
  angular
    .module('tyallsApp')
    .controller('AuditorConsoleController', AuditorConsoleController);
}());

