(function() {

  'use strict';

  var userContainer = {
    templateUrl: 'app/entities/prop-admin/user-console/user-container.html',
    bindings: {
      users: '<',
      propertyId: '<',
      onRemovePropertyProfile: '&',
      onRemoveUserGroup: '&',
      onAddPropertyProfile: '&',
      onAddUserGroup: '&',
    },
    controller: 'UserContainerController',
  }
  
  angular
    .module('tyallsApp')
    .component('userContainer', userContainer)
  ;
}());
