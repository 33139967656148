(function() {
  'use strict';

  ConfirmModalController.$inject = ['$uibModalInstance', 'titleText', 'bodyText', 'onPositiveConfirm'];

  function ConfirmModalController($uibModalInstance, titleText, bodyText, onPositiveConfirm) {
    var ctrl = this;

    ctrl.$onInit = function() {

      ctrl.serverMessage = undefined;
      ctrl.bodyText = bodyText.value;
      ctrl.titleText = titleText.value;
      ctrl.onPositiveConfirm = onPositiveConfirm.value;
    }

    ctrl.clear = function() {
      $uibModalInstance.dismiss('cancel');
    }

    ctrl.confirm = function() {
      ctrl.isSaving = true;

      ctrl.onPositiveConfirm()
        .then(onSaveSuccess)
        .catch(onSaveError)
        .finally(function() {
          ctrl.isSaving = false;
        });

    }

    function onSaveSuccess (result) {
      $uibModalInstance.close(result);
    }

    function onSaveError (reason) {
      ctrl.serverMessage = reason.data.message;
    }

  }

  angular
    .module('tyallsApp')
    .controller('ConfirmModalController', ConfirmModalController);

})();  
