(function() {
  'use strict';

  CommentService.$inject = ['$http'];
  
  function CommentService($http) {
    var service = {
      saveComment: saveComment,
    };

    return service;

    function saveComment(propertyId, comment) {
      // POST /api/properties/{propertyId}/comments
      return $http.post('/api/properties/' + propertyId + '/comments', comment);
    }
  }

  angular
    .module('tyallsApp')
    .factory('CommentService', CommentService);

})();
