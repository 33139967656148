(function() {
  'use strict';

  UserService.$inject = ['$http'];
  function UserService($http) {
    var service = {
      get: get,
      query: query,
      disableUser: disableUser, 
      enableUser: enableUser, 
      createUser: createUser, 
      addUserToProperty: addUserToProperty, 
      removeUserFromProperty: removeUserFromProperty, 
      updateUser: updateUser,
      addUserToUserGroup: addUserToUserGroup, 
      removeUserFromUserGroup: removeUserFromUserGroup, 
      listByUserAndProperty: listByUserAndProperty,
      addUserToPropertyProfile: addUserToPropertyProfile, 
      removeUserFromPropertyProfile: removeUserFromPropertyProfile, 
      getWithPropertyId: getWithPropertyId,
      getAuditorsWithPropertyId: getAuditorsWithPropertyId,
      getWithAppAdminPropertyId: getWithAppAdminPropertyId,
    };

    return service;

    function enrich(response, propertyId) {
      // fix up the membership field from the propertyProfiles
      var newResponse = angular.copy(response);
      if (response.data) {

        // loop over users
        angular.forEach(response.data, function (user, index) {
          newResponse.data[index].propertyProfiles = [];
          newResponse.data[index].userGroups = [];
          newResponse.data[index].memberships = [];
          angular.forEach(user.userGroups, function (userGroup) {
            if (propertyId && userGroup.property === propertyId) {
              newResponse.data[index].userGroups.push(angular.copy(userGroup));
            }
          });
          // loop over propertyProfiles
          angular.forEach(user.propertyProfiles, function (propertyProfile) {

            if (propertyId && propertyProfile.propertyId === propertyId) {

              if (propertyProfile.profileName === 'USER') {

                newResponse.data[index].memberships.push(angular.copy(propertyProfile));
              }
              newResponse.data[index].propertyProfiles.push(angular.copy(propertyProfile));
            }
          });
        });
      }
      return newResponse;
    }

    function getWithPropertyId(propertyId) {
      return $http.get('/api/users?propertyId=' + propertyId, {cache: true})
          .then(function (response) {

            var enrichedResponse = enrich(response, propertyId);
            return Promise.resolve(enrichedResponse);
          })
          ;
    }
    
    function getAuditorsWithPropertyId(propertyId) {
      return $http.get('/api/users?propertyId=' + propertyId + "&filter=auditor", {cache: true})
          .then(function (response) {
            var enrichedResponse = enrich(response, propertyId);
            return Promise.resolve(enrichedResponse);
          })
          ;
    }

    function getWithAppAdminPropertyId(propertyId) {
      return $http.get('/admin/users?propertyId=' + propertyId, {cache: true})
          .then(function (response) {

            var enrichedResponse = enrich(response, propertyId);
            return Promise.resolve(enrichedResponse);
          })
          ;
    }
    
    function listByUserAndProperty(propertyId) {
      return $http.get('/api/users?propertyId=' + propertyId, {cache: true});
    }

    function removeUserFromUserGroup(userId, userGroupId) {
      // DELETE /admin/users/userId/user-groups/userGroupId
      return $http.delete('/api/users/' + userId + '/user-groups/' + userGroupId);
    }

    function removeUserFromPropertyProfile(userId, propertyProfileId) {
      // DELETE /admin/users/userId/property-profiles/propertyProfileId
      return $http.delete('/api/users/' + userId + '/property-profiles/' + propertyProfileId);
    }

    function get(id) {
      // GET /api/users/id
      return $http.get('/api/users/' + id, {cache: true});
    }

    function query() {
      // GET /admin/users
      return $http.get('/admin/users', {cache: true});
    }

    function createUser(user) {
      return $http.post('/api/users/', user);
    }
    
    function addUserToProperty(user, property) {
      return $http.put('/api/users/' + user.id + '/properties/' + property.id);
    }
    
    function removeUserFromProperty(user, property) {
      return $http.delete('/api/users/' + user.id + '/properties/' + property.id);
    }

    function updateUser(user) {
      return $http.put('/admin/users/', user);
    }

    function disableUser(user) {
      return $http.put('/admin/users/' + user.id + '/disable');
    }

    function enableUser(user) {
      return $http.put('/admin/users/' + user.id + '/enable');
    }

    function addUserToUserGroup(userId, userGroupId) {
      return $http.put('/api/users/' + userId + '/user-groups/' + userGroupId);
    }

    function addUserToPropertyProfile(userId, propertyProfileId) {
      return $http.put('/api/users/' + userId + '/property-profiles/' + propertyProfileId);
    }
  }

  angular
    .module('tyallsApp')
    .factory('UserService', UserService);

})();
