/*
* Routed Component
*/
(function() {

  'use strict';

  var listUser = {
    templateUrl: 'app/entities/prop-admin/user-console/list-user.html',
    bindings: {
      propertyId: '<',         // [PARENT.RESOLVE]
      users: '<',              // [UI-VIEW.ATTR] 
    },
    controller: 'ListUserController',
  }

  angular
    .module('tyallsApp')
    .component('listUser', listUser)
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider
        .state('app.prop.prop-admin.user-console.list-user', {
          // url: '/users',
          data: {
            authorities: [],
            pageTitle: 'User Console'
          },
          views: {
            'user-console-content@^': {
              component: 'listUser',
            }
          },
        })

    }]);
}());
