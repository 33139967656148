(function() {
  'use strict';

  PasswordResetInitService.$inject = ['$resource'];

  function PasswordResetInitService($resource) {
    var service = $resource('api/account/reset-password/init', {}, {});

    return service;
  }

  angular
    .module('tyallsApp')
    .factory('PasswordResetInitService', PasswordResetInitService);

})();
