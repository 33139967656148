(function() {
  'use strict';

  AuthServerProvider.$inject = ['$http', '$localStorage' ];

  function AuthServerProvider ($http, $localStorage ) {
    var service = {
      getToken: getToken,
      hasValidToken: hasValidToken,
      login: login,
      logout: logout
    };

    return service;

    function getToken () {
      var token = $localStorage.authenticationToken;
      return token;
    }

    function hasValidToken () {
      var token = this.getToken();
      return !!token;
    }

    function login (credentials) {
      var data = 'username_param=' + encodeURIComponent(credentials.username) +
        '&password_param=' + encodeURIComponent(credentials.password) +
        '&remember-me=' + credentials.rememberMe + '&submit=Login';
      return $http.post('api/authentication', data, {
        cache: false,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).success(function (response) {
        return response;
      });
    }

    function logout () {


      // logout from the server
      $http.post('api/logout', {cache: false}).success(function (response) {
        delete $localStorage.authenticationToken;
        // to get a new csrf token call the api
        $http.get('api/account', {cache: false});
        return response;
      });

    }
  }
  
  angular
    .module('tyallsApp')
    .factory('AuthServerProvider', AuthServerProvider);

})();
