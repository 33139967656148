(function() {

  'use strict';

  WfiState.$inject = [];

  function WfiState() {

    var _selectedProperty = null;
    var _profile = null;

    var service = {
      getSelectedProperty: getSelectedProperty,
      getProfile: getProfile,
      setSelectedProperty: setSelectedProperty,
      setProfile: setProfile,
    };

    return service;

    function getSelectedProperty() {
      return _selectedProperty;
    }

    function setSelectedProperty(selectedProperty) {
      return _selectedProperty = selectedProperty;
    }

    function getProfile() {
      return _profile;
    }

    function setProfile(profile) {
      return _profile = profile;
    }
  }

  angular
    .module('tyallsApp')
    .factory('WfiState', WfiState);

})();
