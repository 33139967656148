/*
 * Modal Routed Component
 */
(function() {
  'use strict';

  angular
    .module('tyallsApp')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider 
        .state('app.prop.app-admin.create-user-group-dialog', {
          // url: '/user-group/create', // modal -- no url
          data: {
            authorities: []
          },
          resolve: {
            onCreateUserGroup: ['$transition$', function($transition$) {
              return $transition.params().onCreateUserGroup;
            }]
          },
          onEnter: ['onCreateUserGroup', '$state', '$uibModal', function( onCreateUserGroup, $state, $uibModal) {
            $uibModal.open({
              templateUrl: 'app/entities/app-admin/create-user-group-dialog/app-admin-create-user-group-dialog.html',
              controller: 'AppAdminCreateUserGroupDialogController',
              controllerAs: '$ctrl',
              backdrop: 'static',
              size: 'md',
              resolve: {
                onCreateUserGroup: { value: onCreateUserGroup },
              }
            }).result.then(function() {
              $state.go('^');
            }, function() {
              $state.go('^');
            });
          }]
        })
      ;
    }]);
})();
