(function() {

    'use strict';

    ReadAuditBasketController.$inject = [ 'DailySummaryService', '$state' ];

    function ReadAuditBasketController( DailySummaryService, $state ) {
        var ctrl = this; 

        ctrl.$onInit = function() {
        };

        ctrl.goToTask = function(index) {

            var wfi = ctrl.auditBasket.wfis[index];

            DailySummaryService
        .getTaskForMention(ctrl.propertyId, wfi.processInstanceId)
        .then(function(resp) {
            $state.go('app.prop.task', {
                'propertyId': ctrl.propertyId,
                'date': wfi.dueDate,
                'tasks': resp.data,
                'returnTo': function () {
                    $state.go('app.prop.audit-basket-viewer.read-audit-basket',
                        {
                            'propertyId': ctrl.propertyId,
                            'auditBasket': ctrl.auditBasket,
                            'id': ctrl.auditBasket.id
                        }
              );
                },
            });
        });
        };
    }
  
    angular
    .module('tyallsApp')
    .controller('ReadAuditBasketController', ReadAuditBasketController);
})();
