(function () {
    'use strict';

    var summaryCardRep = {
        templateUrl: 'app/entities/shared/calendar-rep/summary-card-rep.html',
        bindings: {
            day: '<',
            dailyTaskCount: '<',
            nonDailyTaskCount: '<',
            summary: '<',
            onSelectDetailDate: '&'
        },
        controller: 'SummaryCardRepController',
    }

    angular
        .module('tyallsApp')
        .component('summaryCardRep', summaryCardRep)
    ;
})();
