/*
 * Routed Component
 */
(function () {
    'use strict'

    var file = {
        templateUrl: 'app/entities/file/file.html',
        bindings: {
            properties: '<'  // [THIS.RESOLVE]
        },
        controller: 'FileController',
    }

    angular
        .module('tyallsApp')
        .component('file', file)
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('app.file', {
                    url: '/file',
                    data: {
                        authorities: [],
                        pageTitle: 'File'
                    },
                    component: 'file',
                    resolve: {
                        properties: ['PropertyService', function (PropertyService) {
                            return PropertyService.getAllPropertiesForUser()
                                .then(function (response) {
                                    return response.data;
                                });
                        }]
                    }
                });
        }]);
}());
