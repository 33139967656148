(function() {
  'use strict';


  AppAdminController.$inject = [];

  function AppAdminController() {
    var ctrl = this;

    ctrl.$onInit = function() {
    }

    ctrl.$onChanges = function(changes) {
    }
  }

  angular
    .module('tyallsApp')
    .controller('AppAdminController', AppAdminController);
})();
