(function() {
  'use strict';

  AuditService.$inject = ['$http', 'getHeaderFilename'];

  function AuditService($http, getHeaderFilename) {
    var service = {
      searchAudit: searchAudit,
    };

    return service;

    function searchAudit(criteria, options) {
      // POST /api/audit-query
      var config = {};
      if (!!options && options['format'] === 'pdf') {
        config['headers'] = { 'Accept': 'application/octet-stream' };
        config['responseType'] = 'arraybuffer'
        config['transformResponse'] = function(data, headers) {
          var response = {
            data: data,
            filename: getHeaderFilename(headers)
          };
          return response;
        };
      } else if (!!options && options['format'] === 'csv') {
        config['headers'] = { 'Accept': 'text/csv' };
        config['responseType'] = 'arraybuffer'
        config['transformResponse'] = function(data, headers) {
          var response = {
            data: data,
            filename: getHeaderFilename(headers)
          };
          return response;
        };
      } else {
        config['headers'] = { 'Accept': 'application/json' };
      }
       
      return $http.post('/api/audit-query', criteria, config);
    }
  }

  angular
    .module('tyallsApp')
    .factory('AuditService', AuditService);
})();
