/*
 * Routed Component
 */
(function() {
    'use strict';

    var listAuditBasket = {
        templateUrl: 'app/entities/audit-basket-viewer/list-audit-basket.html',
        bindings: {
            propertyId: '<',          // [PARENT.RESOLVE] user selection
            auditBaskets: '<',        // [THIS.RESOLVE]
        },
        controller: 'ListAuditBasketController',
    };

    angular
		.module('tyallsApp')
		.component('listAuditBasket', listAuditBasket)
		.config(['$stateProvider', function($stateProvider) {
    $stateProvider
				.state('app.prop.audit-basket-viewer.list-audit-basket', {
    url: '/audit-baskets',
    data: {
        authorities: [],
        pageTitle: 'Audit Baskets'
    },
    views: {
        'audit-basket-viewer-content@^' : 'listAuditBasket',
    },
    resolve: {
        propertyId: ['$transition$', function($transition$) {
            return $transition$.params().propertyId;
        }],
        auditBaskets: [ 'AuditBasketService', 'propertyId', function(AuditBasketService, propertyId) {
            if (propertyId === null) {
                return [];
            }
            return AuditBasketService.listByPropertyId(propertyId).then(function(response) {
                return response.data.filter(
									function(el) {
										// must have at least one wfi
    return true;
										// return el.wfis[0].property === Number(propertyId);
}
								);
            });
        }],
    }
});

}]);
})();

