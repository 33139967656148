/*
 * Routed Component
 */
(function() {

  'use strict';

  var listAuditor = {
    templateUrl: 'app/entities/prop-admin/auditor-console/list-auditor.html',
    bindings: {
      propertyId: '<',  // [UI-VIEW.ATTR]
    },
    controller: 'ListAuditorController',
  }
  
  angular
    .module('tyallsApp')
    .component('listAuditor', listAuditor)
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider
        .state('app.prop.prop-admin.auditor-console.list-auditor', {
          url: '/auditors',
          data: {
            authorities: [],
            pageTitle: 'Auditors'
          },
          views: {
            'auditor-console-content@^' : 'listAuditor',
          }
        })
    }]);
}());
