(function() {
  'use strict';

  AppAdminCreateUserGroupDialogController.$inject = ['onCreateGroup', '$uibModalInstance' ];

  function AppAdminCreateUserGroupDialogController(onCreateGroup, $uibModalInstance ) {
    var ctrl = this;

    ctrl.$onInit = function() {
      ctrl.serverMessage = undefined; 
      ctrl.onCreateGroup = onCreateGroup.value;
    }

    ctrl.clear = function() {
      $uibModalInstance.dismiss('cancel');
    }

    ctrl.confirm = function() {
      ctrl.isSaving = true;

      ctrl.onPositiveConfirm()
        .then(onSaveSuccess)
        .catch(onSaveError)
        .finally(function() {
          ctrl.isSaving = false;
        });

    }

    function onSaveSuccess (result) {
      $uibModalInstance.close(result);
    }

    function onSaveError (reason) {
      ctrl.serverMessage = reason.data.message;
    }
  }

  angular
    .module('tyallsApp')
    .controller('AppAdminCreateUserGroupDialogController', AppAdminCreateUserGroupDialogController);
})();
