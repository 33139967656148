(function () {
    'use strict';

    angular
        .module('tyallsApp')
        .config(httpConfig);

    httpConfig.$inject = ['$urlRouterProvider', '$httpProvider', 'httpRequestInterceptorCacheBusterProvider', '$urlMatcherFactoryProvider'];

    function httpConfig($urlRouterProvider, $httpProvider, httpRequestInterceptorCacheBusterProvider, $urlMatcherFactoryProvider) {

       httpRequestInterceptorCacheBusterProvider.setMatchlist([
        /.*protected.*/,
        /.*authentication.*/], true);

        $urlRouterProvider.otherwise('/');

        $httpProvider.interceptors.push('authExpiredInterceptor');

        // this will introduce a false delay for debugging
        // $httpProvider.interceptors.push('delayInterceptor');
        // jhipster-needle-angularjs-add-interceptor JHipster will add new application http interceptor here

        $urlMatcherFactoryProvider.type('boolean', {
            name: 'boolean',
            decode: function (val) {
                return val === true || val === 'true';
            },
            encode: function (val) {
                return val ? 1 : 0;
            },
            equals: function (a, b) {
                return this.is(a) && a === b;
            },
            is: function (val) {
                return [true, false, 0, 1].indexOf(val) >= 0;
            },
            pattern: /bool|true|0|1/
        });
    }
})(); 
