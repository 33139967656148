(function() {
  
  'use strict';

  MembershipCardListController.$inject = [];

  function MembershipCardListController() {
    var ctrl = this; 

    ctrl.$onInit = function() {
    }

  }

  angular
    .module('tyallsApp')
    .component('membershipCardList', {
      templateUrl: 'app/entities/prop-admin/user-console/membership-card-list.html',
      bindings: {
        memberships: '<',
      },
      controller: MembershipCardListController,
    }
    );
}());
