(function() {

  'use strict';

  CreateAuditBasketController.$inject = ['$state', '$q', 'CacheFactory'];

  function CreateAuditBasketController($state, $q, CacheFactory) {


    const ctrl = this;
    const addSelectedWorkflowInstances = function ($event) {
      angular.forEach($event.workflowInstances, function (value) {
        this.push(value);

      }, ctrl.selectedWorkflowInstances);

      return $q.when('x');
    };

    const addSelectedAuditors = function($event) {
      angular.forEach($event.auditors, function(value) {
        this.push(value);
      }, ctrl.selectedAuditors);

      ctrl.selectedAuditors = removeDuplicates(ctrl.selectedAuditors, 'username');

      return $q.when('x');
    };

    ctrl.$onInit = function() {
      ctrl.serverMessage = undefined;
      ctrl.selectedWorkflowInstances = [];
      ctrl.selectedAuditors = [];
    }

    ctrl.saveAuditBasket = function() {
      // build a request
      const request = {};
      const formattedWfis = [];
      const formattedAuditors = [];

      angular.forEach(ctrl.selectedWorkflowInstances, function(value) {
        this.push({
          'processDefinitionId': value.workflowDefinitionId,
          'propertyId': ctrl.propertyId,
          'dueDate': value.dueDate,
        });
      }, formattedWfis);
      
      angular.forEach(ctrl.selectedAuditors, function(value) {
        this.push(value.id);
      }, formattedAuditors);

      request.auditors = formattedAuditors;
      request.wfis = formattedWfis;

      ctrl.onCreateAuditBasket({
        $event: { 
          'auditBasketRequest' : request
        }
      })
        .then(function(response) {
          if (ctrl.propertyId) {
            var cacheKey = "/api/properties/" + ctrl.propertyId + "/audit-baskets";
            var cache = CacheFactory.get("appCache");
            if (cache) {
              cache.remove(cacheKey);
            }
          }

          $state.go('^.list-audit-basket', {}, {reload: true});
        })
        .catch(function(reason) {
          ctrl.serverMessage = reason.data;
        })
      ;
    }

    ctrl.removeAuditor = function(auditor) {
      angular.forEach(ctrl.selectedAuditors, function(value, index) {
        if (value.username === auditor.username) {
          this.splice(index, 1);
        }
      }, ctrl.selectedAuditors);
    }
    
    ctrl.removeWorkflowInstance = function(workflowInstance) {
      angular.forEach(ctrl.selectedWorkflowInstances, function(value, index) {
        if (value.workflowName === workflowInstance.workflowName && value.dueDate === workflowInstance.dueDate) {
          this.splice(index, 1);
        }
      }, ctrl.selectedWorkflowInstances);
    }

    ctrl.goToAddWorkflowInstance = function() {
      $state.go('.add-workflow-instance-dialog', {
        'propertyId': ctrl.propertyId,
        'onSuccessfulEdit': addSelectedWorkflowInstances,
      });
    }
    
    ctrl.goToAddAuditor = function() {
      $state.go('.add-auditor-dialog', {
        'propertyId': ctrl.propertyId,
        'onSuccessfulEdit': addSelectedAuditors,
      });
    }

    function removeDuplicates(myArr, prop) {
      return myArr.filter(function(obj, pos, arr){
        return arr.map(function(mapObj) { return mapObj[prop]}).indexOf(obj[prop]) === pos;
      });
    }

    ctrl.$onChanges = function(changes) {
      if (changes.propertyId) {
      }
    }
  }

  angular
    .module('tyallsApp')
    .controller('CreateAuditBasketController', CreateAuditBasketController);
}());
