(function() {
  'use strict';

  LaunchConsoleController.$inject = [];

  function LaunchConsoleController() {
    var ctrl = this;

    ctrl.$onInit = function() {

      ctrl.onResolveDisabled({
        $event: {
          disabled: false
        }
      });

      ctrl.onResolveProperties({
        $event: {
          properties: ctrl.properties
        }
      });
    }

    ctrl.$onChanges = function(changes) {
    }
  }

  angular
    .module('tyallsApp')
    .controller('LaunchConsoleController', LaunchConsoleController);
})();
