(function() {
  
  'use strict';

  MembershipCardController.$inject = [];

  function MembershipCardController() {
    var ctrl = this; 
  }

  angular
    .module('tyallsApp')
    .component('membershipCard', {
      templateUrl: 'app/entities/prop-admin/user-console/membership-card.html',
      bindings: {
        membership: '<',
      },
      controller: MembershipCardController,
    }
    );
}());
