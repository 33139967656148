(function() {
  'use strict';

  WorkflowDefinitionService.$inject = ['$http', 'Upload', 'getHeaderFilename', '$window', '$document'];

  function WorkflowDefinitionService($http, Upload, getHeaderFilename, $window, $document) {
    var service = {
      query: query,
      save: save,
      createWfd: createWfd,
      getAllWfds: getAllWfds,
      getUploadStream: getUploadStream,
    };

    return service;

    function query() {
      return $http.get('/api/workflow-definitions');
    }

    function getAllWfds() {
      return $http.get('/api/workflow-definitions');
    }

    function getUploadStream(wfdId) {
      // This is used for the BPMN
      return $http.get('/api/workflow-definitions/' + wfdId, {
        responseType:'arraybuffer',
        transformResponse: function(data, headers) {
          return {
            data: data,
            filename: getHeaderFilename(headers)
          };
        }
      })
        .success(function(result) {

          if ($window.navigator.msSaveOrOpenBlob) {
            $window.navigator.msSaveOrOpenBlob(new Blob([result.data]), result.filename);
          }
          else {
            var url = URL.createObjectURL(new Blob([result.data]));
            var a = $document[0].createElement('a');
            a.href = url;
            a.download = result.filename;
            a.target = '_blank';
            a.click();
          }
        })
        .error(function(error) {
          $log.error(error.data);
        });
    }

    function createWfd(wfd) {
      return save(wfd.propertyId, wfd.wfdFile, wfd.dailyState);
    }


    function save(propertyId, file, dailyState) {

      var fields = {
        'propertyId': propertyId,
        'dailyState': dailyState,
        'stitch': false,
      };

      return Upload.upload({
        url: '/api/workflow-definitions',
        fields: fields,
        file: file
      }).progress(function(evt) {
        var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
        // $log.info('progress: ', progressPercentage + '% ' + evt.config.file.name);
      }).success(function(data, status, headers, config) {
        // $log.info('file ' + config.file.name + 'uploaded. Response: ' + data);
      });

    }
  }

  angular
    .module('tyallsApp')
    .factory('WorkflowDefinitionService', WorkflowDefinitionService);

})();
