/*
 * Routed Component
 */
(function() {
  'use strict';

  var propAdmin = {
    templateUrl: 'app/entities/prop-admin/prop-admin.html',
    bindings: {
      onResolveDisabled: '&',   // [UI-VIEW.ATTR]
      onResolveProperties: '&', // [UI-VIEW.ATTR] this is how child state return possible properties to parent
      properties: '<',          // [THIS.RESOLVE] passed in from state.resolve 
      propertyId: '<',          // [PARENT.RESOLVE] user selection, inherited from app.prop
    },
    controller: 'PropAdminController'
  };

  angular
    .module('tyallsApp')
    .component('propAdmin', propAdmin)
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider
        .state('app.prop.prop-admin', {
          url: '/prop-admin',
          redirectTo: 'app.prop.prop-admin.user-console',
          views: {
            'prop@^' : 'propAdmin',
          },
          data: {
            authorities: [],
            pageTitle: 'PropAdmin'
          },
          resolve: {
            // override the property list fetched in parent
            properties: [ 'PropertyService', function(PropertyService) {
              return PropertyService.getAllPropertiesForPropAdmin()
                .then(function(response) {
                  return response.data; 
                });
            }],
          }
        })

    }]);
})();
