/*
 * Modal Routed Component
 */
(function () {
    'use strict';

    angular
        .module('tyallsApp')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('app.app-admin.detail-user-dialog', {
                    // url: '/user/create',  // modal -- no url
                    params: {
                        user: null,
                        onAddUserToProperty: null,
                        onRemoveUserFromProperty: null,
                    },
                    resolve: {
                        properties: ['PropertyService', function (PropertyService) {
                            return PropertyService.getAllPropertiesForAdmin()
                                .then(function (response) {
                                    return response.data;
                                });
                        }],
                        onAddUserToProperty: ['$transition$', function ($transition$) {
                            return $transition$.params().onAddUserToProperty;
                        }],
                        onRemoveUserFromProperty: ['$transition$', function ($transition$) {
                            return $transition$.params().onRemoveUserFromProperty;
                        }],
                        user: ['$transition$', function ($transition$) {
                            return $transition$.params().user;
                        }]

                    },
                    data: {
                        authorities: []
                    },
                    onEnter: ['onAddUserToProperty', 'onRemoveUserFromProperty', 'user', 'properties', '$state', '$uibModal', function (onAddUserToProperty, onRemoveUserFromProperty, user, properties, $state, $uibModal) {
                        $uibModal.open({
                            templateUrl: 'app/entities/app-admin/detail-user-dialog/app-admin-detail-user-dialog.html',
                            controller: 'AppAdminDetailUserDialogController',
                            controllerAs: '$ctrl',
                            backdrop: 'static',
                            size: 'md',
                            resolve: {
                                user: {value: user},
                                properties: {value: properties},
                                onAddUserToProperty: {value: onAddUserToProperty},
                                onRemoveUserFromProperty: {value: onRemoveUserFromProperty},
                            }
                        }).result.then(function (response) {
                            $state.go('^');
                        }, function () {
                            $state.go('^');
                        });
                    }]
                })

        }]);

})();
