(function () {
    'use strict';

    AuditBasketService.$inject = ['$http'];

    function AuditBasketService($http) {
        var service = {
            listByPropertyId: listByPropertyId,
            listByPropertyIdAndAuditBasketId: listByPropertyIdAndAuditBasketId,
            listByAuditor: listByAuditor,
            saveAuditBasket: saveAuditBasket,
        };

        return service;

        function listByAuditor() {
            return $http.get('/api/audit-baskets', {cache: true});
        }

        function listByPropertyId(propertyId) {
            return $http.get('/api/properties/' + propertyId + '/audit-baskets',
                {cache: true});
        }

        function listByPropertyIdAndAuditBasketId(propertyId, auditBasketId) {
            return $http.get('/api/properties/' + propertyId + '/audit-baskets/' + auditBasketId, {cache: true});
        }

        function saveAuditBasket(propertyId, auditBasket) {
            return $http.post('/api/properties/' + propertyId + '/audit-baskets', auditBasket, {cache: true});
        }
    }

    angular
        .module('tyallsApp')
        .factory('AuditBasketService', AuditBasketService);
})();
