(function() {
  'use strict';

  ImageService.$inject = ['$http'];
  function ImageService($http) {
    var service = {
      getImage: getImage,
    };

    return service;

    function getImage(processInstanceId) {
      // GET /admin/profiles
      return $http.get('api/wfd/' + processInstanceId + '/image');
    }
  }

  angular
    .module('tyallsApp')
    .factory('ImageService', ImageService);

})();
