(function() {
  'use strict';

  InviteService.$inject = ['$http'];

  function InviteService($http) {
    var service = {
      query: query,
      acceptInvite: acceptInvite,
      getWithPropertyId: getWithPropertyId,
    };

    return service;

    function query() {
      return $http.get('/api/invites');
    }

    function getWithPropertyId(propertyId) {
      return $http.get('/api/properties/' + propertyId + '/invites');
    }

    function acceptInvite(invite) {
      return $http.post('api/properties/invite-user/finish', invite.inviteKey);         
    }
  }

  angular
    .module('tyallsApp')
    .factory('InviteService', InviteService);

})();
