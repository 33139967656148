(function () {

    'use strict';

    SharedDateCreateSupportingDocumentDialogController.$inject = [
        'onCreateSupportingDocument',
        '$uibModalInstance',
        'CacheFactory'];

    function SharedDateCreateSupportingDocumentDialogController(onCreateSupportingDocument, $uibModalInstance, CacheFactory) {
        var ctrl = this;

        ctrl.$onInit = function () {
            ctrl.newSupportingDoc = {};

            ctrl.serverMessage = undefined;
            ctrl.onCreateSupportingDocument = onCreateSupportingDocument.value;
        }

        ctrl.clear = function () {
            $uibModalInstance.dismiss('cancel');
        }

        ctrl.save = function () {
            ctrl.isSaving = true;

            ctrl.onCreateSupportingDocument({
                supportingDocument: ctrl.newSupportingDoc,
            })
                .then(onSaveSuccess)
                .catch(onSaveError)
                .finally(function () {
                    ctrl.isSaving = false;
                    ctrl.clearCache();
                });

        }

        ctrl.clearCache = function () {
            var cache = CacheFactory.get("appCache");
            if (cache) {
                cache.removeAll()
            }
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
        }

        function onSaveError(reason) {
            ctrl.serverMessage = reason.data.message;
        }

    }

    angular.module('tyallsApp')
        .controller('SharedDateCreateSupportingDocumentDialogController', SharedDateCreateSupportingDocumentDialogController);

})();
