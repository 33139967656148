(function () {

    'use strict';

    MentionListController.$inject = ['$filter'];

    function MentionListController($filter) {
        var ctrl = this;
        ctrl.filteredCandidates = [];

        ctrl.$onInit = function () {
            ctrl.filteredCandidates = angular.copy(ctrl.candidates);
            ctrl.filteredCandidates = $filter('filter')(ctrl.filteredCandidates, {enabled: true});
        }

        ctrl.$onChanges = function (changes) {
        }

        ctrl.deleteMention = function (userId) {
            var deleteMention = {
                userId: userId
            }
            ctrl.onDeleteMention({mention: deleteMention});
            ctrl.filteredCandidates = $filter('candidatesFilter')(ctrl.candidates, ctrl.mentions);
        }

        ctrl.addMention = function (userId) {
            var newMention = {
                userId: userId
            };
            ctrl.onAddMention({mention: newMention});
            ctrl.filteredCandidates = $filter('candidatesFilter')(ctrl.candidates, ctrl.mentions);
        }

    }

    angular
        .module('tyallsApp')
        .controller('MentionListController', MentionListController);
}());

