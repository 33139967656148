(function() {

  'use strict';

  ListAuditorController.$inject = [ 'UserService', '$filter' ];

  function ListAuditorController( UserService, $filter ) {
    var ctrl = this; 

    ctrl.$onInit = function() {
      ctrl.auditors = [];
    }

    ctrl.$onChanges = function(changes) {
      if (changes.propertyId) {
        UserService.getAuditorsWithPropertyId(changes.propertyId.currentValue)
          .then(function(response) {
            ctrl.auditors = response.data;
          })
          .catch(function(reason) {
            console.log(reason);      
          });
      }
    }


  }

  angular
    .module('tyallsApp')
    .controller('ListAuditorController', ListAuditorController);
}());
