/*
 * Routed Component
 */
(function () {
    'use strict';

    var reporterMonth = {
        templateUrl: 'app/entities/shared/reporter-month/reporter-month.html',
        bindings: {
            onResolveDisabled: '&',   // [UI-VIEW.ATTR]
            onResolveProperties: '&', // [UI-VIEW.ATTR] this is how child state return possible properties to parent
            properties: '<',          // [THIS.RESOLVE] passed in from state.resolve
            propertyId: '<',          // [PARENT.RESOLVE] user selection
            account: '<',             // [THIS.RESOLVE]
        },
        controller: 'SharedController',
    };

    angular
        .module('tyallsApp')
        .component('reporterMonth', reporterMonth)
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('app.prop.reporter-month', {
                    url: '/reporter-month',
                    views: {
                        'prop@^': 'reporterMonth',
                    },
                    data: {
                        authorities: [],
                        pageTitle: 'Reports'
                    },
                    resolve: {
                        properties: ['PropertyService', function (PropertyService) {
                            return PropertyService.getAllPropertiesForUser()
                                .then(function (response) {
                                    return response.data;
                                });
                        }],
                        // refactor this
                        account: ['PrincipalService', function (PrincipalService) {
                            return PrincipalService.identity();
                        }]
                    }
                });
        }]);

}());
