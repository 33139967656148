(function() {

  'use strict';

  angular
    .module('tyallsApp')
    .factory('WorkflowInstanceService', WorkflowInstanceService);

  WorkflowInstanceService.$inject = [ '$http' ];

  function WorkflowInstanceService( $http ) {

    var service = {
      getWorkflowInstanceByPropertyAndDueDate: getWorkflowInstanceByPropertyAndDueDate,
      getWorkflowInstanceByProperty: getWorkflowInstanceByProperty,
    };

    return service;

    function getWorkflowInstanceByPropertyAndDueDate( propertyId, dueDate) {
      var url = '/api/properties/' + propertyId + '/workflow-instances/?due-date=' + moment(dueDate).format('YYYY-MM-DD');
      return $http
          .get(url, {cache: false});
    }
    
    function getWorkflowInstanceByProperty( propertyId) {
      var url = '/api/properties/' + propertyId + '/workflow-instances';
      return $http
          .get(url, {cache: false});
    }
  }
})();
