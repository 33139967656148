/*
 * Routed Component
 */
(function () {
    'use strict';

    var dailyLauncher = {
        templateUrl: 'app/entities/launcher/daily-launcher.html',
        bindings: {
            propertyId: '<',         // [PARENT.RESOLVE]
        },
        controller: 'DailyLauncherController'
    };

    angular
        .module('tyallsApp')
        .component('dailyLauncher', dailyLauncher)
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('app.prop.launch-console.daily-launcher', {
                    url: '/daily-launcher',
                    views: {
                        'daily-launcher@^': 'dailyLauncher',
                    },
                    data: {
                        authorities: [],
                        pageTitle: 'Daily Launcher'
                    },
                })
        }]);
}());
