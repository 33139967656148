/*
 * Routed Component
 */
(function () {
    'use strict';

    var prop = {
        templateUrl: 'app/entities/shared/prop.html',
        bindings: {
            propertyId: '<',  // [THIS.RESOLVE]
        },
        controller: 'PropController',
    };

    angular
        .module('tyallsApp')
        .component('prop', prop)
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('app.prop', {
                    abstract: true,   // must always be abstract
                    url: '/prop/:propertyId', // optional
                    params: {
                        propertyId: {
                            squash: true,  // optional
                            value: null,
                            type: 'any',
                        },
                    },
                    component: 'prop',
                    resolve: {
                        propertyId: ['$transition$', function ($transition$) {
                            return $transition$.params().propertyId;
                        }],
                        // DO NOT RESOLVE PROPERTIES HERE
                        // DELEGATE TO CHILD
                    }
                })
        }]);
}());
