(function() {
  'use strict';

  UserGroupService.$inject = ['$http'];

  function UserGroupService($http) {
    var service = {
      query: query,
      createUserGroup: createUserGroup,
      getWithPropertyId: getWithPropertyId,
    };

    return service;

    function query() {
      // GET /admin/user-groups
      return $http.get('/admin/user-groups');
    }

    function getWithPropertyId(propertyId) {
      return $http.get('/api/properties/' + propertyId + '/user-groups');
    }

    function createUserGroup(userGroup) {
      return $http.post('/admin/user-groups/', userGroup);
    }
  }

  angular
    .module('tyallsApp')
    .factory('UserGroupService', UserGroupService);

})();
