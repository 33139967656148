/*
 * Routed Component
 */
(function() {
  'use strict';

  var portfolio = {
    templateUrl: 'app/entities/portfolio/portfolio.html',
    bindings: {
      property: '<'  // [THIS.RESOLVE] 
    },
    controller: 'PortfolioController',
  }

  angular
    .module('tyallsApp')
    .component('portfolio', portfolio)
    .config(['$stateProvider',function($stateProvider) {
      $stateProvider
        .state('app.portfolio', {
          url: '/portfolio',
          data: {
            authorities: [],
            pageTitle: 'Portfolio'
          },
          component: 'portfolio',
          resolve: {
            property: [ 'PropertyService', function(PropertyService) {
              return PropertyService.getAllPropertiesForUser()
                .then(function(response) {
                  return response.data;
                });
            }]
          }
        })
      ;

    }]);
})();
