(function () {
    'use strict'

    var appNav = {
        templateUrl: 'app/app-nav/app-nav.html',
        bindings: {},
        controller: 'AppNavController'
    };

    angular
        .module('tyallsApp')
        .component('appNav', appNav);
}());
