(function () {

    'use strict';

    angular
        .module('tyallsApp')
        .controller('FileuploadController', FileuploadController);

    FileuploadController.$inject = ['Upload', '$log', '$resource'];

    function FileuploadController(Upload, $log, $resource) {
        var vmFile = this;
        vmFile.file = null;
        vmFile.files = [];

        vmFile.upload = upload;
        vmFile.deleteUpload = deleteUpload;

        function deleteUpload(fileupload) {
            var Fileupload = $resource('/api/fileuploads/:id', {});

            Fileupload.delete({id: fileupload.id})
                .$promise
                .then(function () {
                        $log.info('deleted file id: ' + fileupload.id);
                        var index = vmFile.files.indexOf(fileupload);
                        vmFile.files.splice(index, 1);
                    }
                );
        }

        function upload() {
            Upload.upload({
                url: '/api/fileuploads',
                fields: {},
                file: vmFile.file
            }).progress(
                function (evt) {
                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    $log.info('progress: ' + progressPercentage + '%');
                }).success(function (data, status, headers, config) {
                vmFile.files.push(data);
                $log.info('file ' + config.file.name + 'uploaded. Response: ' + data);
            });
        }
    }
})();


