/*
 * Routed Component
 */
(function() {
  'use strict';

  var createAuditBasket = {
    templateUrl: 'app/entities/prop-admin/audit-basket-console/create-audit-basket.html',
    bindings: {
      propertyId: '<',           // [PARENT.RESOLVE]
      onCreateAuditBasket: '&',
    },
    controller: 'CreateAuditBasketController',
  }

  angular
    .module('tyallsApp')
    .component('createAuditBasket', createAuditBasket)
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider 
        .state('app.prop.prop-admin.audit-basket-console.create-audit-basket', {
          url: '/create',
          params: { 
            onCreateAuditBasket: null,
          }, // non-url params
          data: {
            authorities: []
          },
          views: {
            'audit-basket-console-content@^': {              
              component: 'createAuditBasket',
            }
          },
        });

    }]);

})();
