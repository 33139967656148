/*
 * Routed Component
 */
(function () {

    'use strict';

    var taskList = {
        templateUrl: 'app/entities/shared/task/task-list.html',
        bindings: {
            onResolveDisabled: '&',   // [UI-VIEW.ATTR]
            onResolveProperties: '&', // [UI-VIEW.ATTR] this is how child state return possible properties to parent
            properties: '<',          // [THIS.RESOLVE] passed in from state.resolve
            propertyId: '<',          // [PARENT.RESOLVE] user selection
            account: '<',             // [THIS.RESOLVE]
            onIteration: '<',         // [THIS.RESOLVE]
            returnTo: '<',            // [THIS.RESOLVE]
            tasks: '<',               // [THIS.RESOLVE]
            taskDate: '<',            // [THIS.RESOLVE]
            // $transition$: '<',
        },
        controller: 'TaskListController',
    }

    angular
        .module('tyallsApp')
        .component('taskList', taskList)
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('app.prop.task-list', {
                    url: '/:date',
                    views: {
                        'prop@^': 'taskList',
                    },
                    params: {
                        onIteration: null,
                        returnTo: null,
                        tasks: null,
                    }, // non-url params
                    resolve: {
                        properties: ['PropertyService', function (PropertyService) {
                            return PropertyService.getAllPropertiesForUser()
                                .then(function (response) {
                                    return response.data;
                                });
                        }],
                        onIteration: ['$transition$', function ($transition$) {
                            return $transition$.params().onIteration;
                        }],
                        returnTo: ['$transition$', function ($transition$) {
                            return $transition$.params().returnTo;
                        }],
                        tasks: ['$transition$', function ($transition$) {
                            return $transition$.params().tasks;
                        }],
                        taskDate: ['$transition$', function ($transition$) {
                            return $transition$.params().date;
                        }],
                    },
                    data: {
                        authorities: [],
                        pageTitle: 'Task-List',
                    },

                });
        }])
})();
