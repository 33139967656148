(function() {
    'use strict';

    angular
        .module('tyallsApp')
        .service('getHeaderFilename', getHeaderFilename);

    getHeaderFilename.$inject = [];

    function getHeaderFilename() {
        return function(headers) {
            var header = headers('content-disposition');
            var result = header.split(';')[1].trim().split('=')[1];
            return result.replace(/"/g, '');
        };
    }

})();
