(function() {

  'use strict';

  UserContainerController.$inject = [];

  function UserContainerController() {
    var ctrl = this; 

    ctrl.$onInit = function() {
      ctrl.doRowFilter = false;
      ctrl.sortType = 'username';  // set the default sort type
      ctrl.sortReverse = false;    // set the default sort order
      ctrl.searchFilter = '';      // set the default search/filter term
    }

    ctrl.removeUserFromUserGroup = function($event) {
      return ctrl.onRemoveUserGroup({
        $event: {
          userId: $event.userId,
          userGroupId: $event.userGroupId,
        }
      });
    }
    
    ctrl.removeUserFromPropertyProfile = function($event) {
      return ctrl.onRemovePropertyProfile({
        $event: {
          userId: $event.userId,
          propertyProfileId: $event.propertyProfileId,
        }
      });
    }

    ctrl.addUserToUserGroup = function($event) {
      return ctrl.onAddUserGroup({
        $event: {
          userId: $event.userId,
          userGroup: $event.userGroup,
        }
      });
    }

    ctrl.addUserToPropertyProfile = function($event) {
      return ctrl.onAddPropertyProfile({
        $event: {
          userId: $event.userId,
          propertyProfile: $event.propertyProfile,
        }
      });
    }
  }

  angular
    .module('tyallsApp')
    .controller('UserContainerController', UserContainerController);
}());
