(function() {

  'use strict';

  AccountService.$inject = [ '$resource' ];

  function AccountService( $resource ) {
    var service = $resource('api/account', {}, {
      'get': {
        method: 'GET', params: {}, isArray: false,
        interceptor: {
          response: function (response) {
            // expose response
            return response;
          }
        }
      }
    });
    return service;
  }
  
  angular
    .module('tyallsApp')
    .factory('AccountService', AccountService);
})();
