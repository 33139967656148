/*
 * Routed Component
 */
(function () {
    'use strict';

    var invites = {
        templateUrl: 'app/entities/invites/invites.html',
        bindings: {
            // account: '<'
        },
        controller: 'InviteController',
    }

    angular
        .module('tyallsApp')
        .component('invites', invites)
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('app.invites', {
                    url: '/invites',
                    data: {
                        authorities: [],
                        pageTitle: 'Invites'
                    },
                    views: {
                        'content@': {
                            component: 'invites',
                        }
                    }
                });
        }]);
})();
