(function() {
    'use strict';

    angular
        .module('tyallsApp')
        .directive('fileModel', fileModel);

    fileModel.$inject = ['$parse'];

    function fileModel ($parse) {
        var directive = {
            restrict: 'A',
            link: function(scope, element, attrs) {
                var model = $parse(attrs.fileModel);
                element.bind('change', function() {
                    scope.$apply(model.assign(scope, element[0].files[0]));
                });
            }
        };

        return directive;
    }

})();
