(function() {
    'use strict';

    angular.module('tyallsApp')
        .directive('accountCard', accountCard);

    accountCard.$inject = [];

    function accountCard() {
        return {
            restrict: 'E',
            scope: {
                account: '='
            },
            template: ['<section id="user-info" style="margin-bottom: 10px">',
                '{{account.username}}   {{account.firstName}}',
                '<ul>profiles: [ <li ng-repeat="profile in account.profiles">{{profile}}</li> ]</ul>',
                '<ul>groups: [ <li ng-repeat="group in account.groups">{{group}}</li> ]</ul>',
                '<ul>properties: [ <li ng-repeat="property in account.properties">{{property}}</li> ]</ul>',
                '</section>'].join('')
        };
    }
})();


