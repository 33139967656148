(function () {
    'use strict';


    AppAdminCreateWfdDialogController.$inject = ['ProcessDefinitionDTOService', '$scope', 'onCreateWfd', 'properties', '$uibModalInstance'];

    function AppAdminCreateWfdDialogController(ProcessDefinitionDTOService, $scope, onCreateWfd, properties, $uibModalInstance) {
        var ctrl = this;

        ctrl.$onInit = function () {
            ctrl.selectedWfd = {};
            ctrl.workflowDefinitionKeys = [];
            ctrl.serverMessage = undefined;
            ctrl.properties = properties.value;
            ctrl.onCreateWfd = onCreateWfd.value;

            ctrl.dailyStates = [
                {'id': 'DAILY', 'desc': 'Daily'},
                {'id': 'NONDAILY', 'desc': 'Non Daily'},
            ];
        }

        // $scope.$watch(function() { return ctrl.selectedWfd.propertyId}, function(newValue) {
        //   if (newValue) {
        //     ProcessDefinitionDTOService
        //       .getAllNonDailyProcessDefinitionDTO(newValue)
        //       .then(function(response) {
        //         console.log(response.data);
        //         ctrl.workflowDefinitions = [];
        //         angular.forEach(response.data, function(value) {
        //           ctrl.workflowDefinitions.push(value.key);
        //         });
        //
        //         // set the timezoneid
        //         ctrl.timezoneId = ctrl.properties.filter(function(item){ return item.id === newValue; })[0].timezoneId;
        //
        //         console.log(ctrl.timezoneId);
        //
        //
        //       })
        //       .catch(function(reason) {
        //
        //       });
        //   }
        // });

        ctrl.clear = function () {
            $uibModalInstance.dismiss('cancel');
        }

        ctrl.save = function () {
            ctrl.isSaving = true;

            ctrl.onCreateWfd({
                wfd: ctrl.selectedWfd
            })
                .then(onSaveSuccess)
                .catch(onSaveError)
                .finally(function () {
                    ctrl.isSaving = false;
                });
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
        }

        function onSaveError(reason) {
            ctrl.serverMessage = reason.data.message;
        }
    }

    angular
        .module('tyallsApp')
        .controller('AppAdminCreateWfdDialogController', AppAdminCreateWfdDialogController);
})();
