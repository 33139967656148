(function() {

  'use strict';

  var auditBasketConsole = {
    templateUrl: 'app/entities/prop-admin/audit-basket-console/audit-basket-console.html',
    bindings: {
      propertyId: '<',                // [PARENT.RESOLVE]
    },
    controller: 'AuditBasketConsoleController',
  }
  
  angular
    .module('tyallsApp')
    .component('auditBasketConsole', auditBasketConsole)
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider
        .state('app.prop.prop-admin.audit-basket-console', {
            url: '/audit-basket-console',
            redirectTo: 'app.prop.prop-admin.audit-basket-console.list-audit-basket',
            views: {
                'audit-basket-console@^': {
                    component: 'auditBasketConsole',
                }
            },
        })

    }]);
}());
