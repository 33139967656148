(function () {
    'use strict';

    SharedController.$inject = ['bsLoadingOverlayService', '$state', 'DailySummaryService', 'moment'];

    function SharedController(bsLoadingOverlayService, $state, DailySummaryService, moment) {
        var ctrl = this;

        ctrl.$onInit = function () {
            ctrl.pivotDate = moment();
            ctrl.forceRefresh = false;

            ctrl.onResolveDisabled({
                $event: {
                    disabled: false
                }
            });

            ctrl.onResolveProperties({
                $event: {
                    properties: ctrl.properties
                }
            });
        }

        ctrl.updatePivotDate = function (timestamp) {
            ctrl.pivotDate = timestamp;
        }

        ctrl.fetchReporterSummaryData = function (start, end) {
            return DailySummaryService
                .getDailySummaryForProperty(ctrl.propertyId, start, end);
        }

        ctrl.fetchReviewerSummaryData = function (start, end) {
            return DailySummaryService
                .getDailySummary(ctrl.account.username, ctrl.propertyId, start, end);
        }

        ctrl.selectReviewerDetailDate = function ($event) {
            bsLoadingOverlayService.start();

            // get the tasks
            DailySummaryService
                .getDailyTasks(ctrl.account.username, ctrl.propertyId, $event.date)
                .then(function (resp) {
                    bsLoadingOverlayService.stop();
                    $state.go('app.prop.task-list', {
                        'propertyId': ctrl.propertyId,
                        'date': $event.date,
                        'tasks': resp.data,
                        'returnTo': function () {
                            $state.go('app.prop.reviewer-month');
                        },
                        'onIteration': ctrl.selectReviewerDetailDate
                    });
                });
        }

        ctrl.selectReporterDetailDate = function ($event) {
            bsLoadingOverlayService.start();
            console.log("Reporter")
            DailySummaryService
                .getDailyTasksReporter(ctrl.propertyId, $event.date)
                .then(function (resp) {
                    bsLoadingOverlayService.stop();
                    console.log(resp);
                    $state.go('app.prop.task-list', {
                        'propertyId': ctrl.propertyId,
                        'date': $event.date,
                        'tasks': resp.data,
                        'returnTo': function () {
                            $state.go('app.prop.reporter-month');
                        },
                        'onIteration': ctrl.selectReporterDetailDate
                    });
                });
        }

        ctrl.onClaim = function ($event) {
            ctrl.forceRefresh = !ctrl.forceRefresh;
        }
    }

    angular
        .module('tyallsApp')
        .controller('SharedController', SharedController);
})();
