(function() {

  'use strict';

  var userAdminConsole = {
    templateUrl: 'app/entities/app-admin/user-admin-console/user-admin-console.html',
    bindings: {
      users: '<',
      properties: '<',
    },
    controller: 'UserAdminConsoleController',
  }

  angular
    .module('tyallsApp')
    .component('userAdminConsole', userAdminConsole) 
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider
        .state('app.app-admin.user-admin-console', {
          url: '/users',
          views: {
            'user-admin-console@^': {
              component: 'userAdminConsole',
            }
          },
          resolve: {
            properties: [ 'PropertyService', function( PropertyService ) {
              return PropertyService.getAllPropertiesForAdmin()
                .then(function(response) {
                  return response.data; 
                });
            }],
            users: [ 'UserService', function( UserService ) {

              return UserService.query()
                .then(function(response){
                  return response.data; 
                });
            }],
          }
        })

    }]);
}());
