/*
 * Routed Component
 */
(function () {
    'use strict';

    var mention = {
        templateUrl: 'app/entities/mentions/mentions.html',
        bindings: {
            mentionsPage: '<',           // [THIS.RESOLVE]
            idToPropertyLookup: '<'  // [THIS.RESOLVE]
        },
        controller: 'MentionController',
    };

    angular
        .module('tyallsApp')
        .component('mention', mention)
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('app.mentions', {
                    url: '/mentions',
                    data: {
                        authorities: [],
                        pageTitle: 'Mentions'
                    },
                    component: 'mention',
                    resolve: {
                        mentionsPage: ['MentionService', function (MentionService) {
                            return MentionService.query(0, 10, true).then(function (resp) {
                                return resp.data;
                            });
                        }],
                        idToPropertyLookup: ['PropertyService', function (PropertyService) {
                            return PropertyService
                                .getAllPropertiesForUser()
                                .then(function (response) {
                                    var idToPropertyLookup = {};
                                    angular.forEach(response.data, function (value) {
                                        this[value.id] = value;
                                    }, idToPropertyLookup);
                                    return idToPropertyLookup;
                                })

                        }]

                    }
                })

        }]);
}());
