/*
 * Routed Component
 */
(function() {

  'use strict';

  var auditorConsole = {
    templateUrl: 'app/entities/prop-admin/auditor-console/auditor-console.html',
    bindings: {
      propertyId: '<',  // [PARENT.RESOLVE]
    },
    controller: 'AuditorConsoleController',
  }

  angular
    .module('tyallsApp')
    .component('auditorConsole', auditorConsole)
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider
        .state('app.prop.prop-admin.auditor-console', {
          redirectTo: '.auditor-console.list-auditor',
          views: {
            'auditor-console@^': { 
              component: 'auditorConsole',
            }
          }
        })

    }]);
}());
