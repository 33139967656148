(function () {

    'use strict';

    var commentConsole = {
        templateUrl: 'app/entities/shared/wfi-selector/comment-console.html',
        bindings: {
            commentData: '<',
            propertyId: '<',
        },
        controller: 'CommentConsoleController',
    }

    angular
        .module('tyallsApp')
        .component('commentConsole', commentConsole)
    ;
}());
