(function () {
    'use strict';

    PropController.$inject = ['$state'];

    function PropController($state) {
        var ctrl = this;

        ctrl.$onInit = function () {
            ctrl.propertyId = Number(ctrl.propertyId);
        }

        ctrl.selectProperty = function ($event) {
            ctrl.propertyId = Number($event.property);
            // reload with propertyId set
            $state.go($state.current, {propertyId: ctrl.propertyId});
        }

        ctrl.$onChanges = function (changes) {
        }

        // defer whether to disable to the child state (prop-admin or app-admin)
        ctrl.setDisabled = function ($event) {
            ctrl.disabled = $event.disabled;
        }

        // defer the available properties to the child state (prop-admin or app-admin)
        ctrl.setProperties = function ($event) {
            ctrl.properties = $event.properties;
        }
    };

    angular
        .module('tyallsApp')
        .controller('PropController', PropController);
}());
