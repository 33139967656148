/*
 *  * Routed Component
 *   */
(function() {

	'use strict';

	var auditBasketViewer = {
		templateUrl: 'app/entities/audit-basket-viewer/audit-basket-viewer.html',
		bindings: {
			onResolveDisabled: '&',   // [UI-VIEW.ATTR]
			onResolveProperties: '&', // [UI-VIEW.ATTR] this is how child state return possible properties to parent
			properties: '<',          // [THIS.RESOLVE] passed in from state.resolve 
			propertyId: '<',          // [PARENT.RESOLVE] user selection
			auditBaskets: '<',        // [THIS.RESOLVE]
		},
		controller: 'AuditBasketViewerController',
	}

	angular
		.module('tyallsApp')
		.component('auditBasketViewer', auditBasketViewer)
		.config(['$stateProvider', function($stateProvider) {
			$stateProvider
				.state('app.prop.audit-basket-viewer', {
					redirectTo: '.prop.audit-basket-viewer.list-audit-basket',
					views: {
						'prop@^': { 
							component: 'auditBasketViewer',
						}
					},
					resolve: {
						propertyId: ['$transition$', function($transition$) {
							return $transition$.params().propertyId;
						}],
						properties: ['PropertyService', function(PropertyService) {
							return PropertyService.getAllPropertiesForUser()
								.then(function(response) {
									return response.data;
								});
						}],

					}
				});

		}]);
}());

