(function() {
  'use strict';

  PropertyService.$inject = ['$http'];
  function PropertyService($http) {
    var service = {
      getAllPropertiesForUser: getAllPropertiesForUser,
      getAllPropertiesForPropAdmin: getAllPropertiesForPropAdmin,
      getAllPropertiesForAdmin: getAllPropertiesForAdmin,
    };

    return service;

    function getAllPropertiesForUser() {
      // GET /api/properties
      return $http.get('/api/properties', {cache: true});
    }
    
    function getAllPropertiesForPropAdmin() {
      return $http.get('/api/properties?filter=PROP_ADMIN', {cache: true});
    }

    function getAllPropertiesForAdmin() {
      // GET /admin/properties
      return $http.get('/admin/properties', {cache: true});
    }
  }

  angular
    .module('tyallsApp')
    .factory('PropertyService', PropertyService);

})();
