(function () {
    'use strict';


    AppAdminCreateUserDialogController.$inject = ['onCreateUser', 'properties', '$uibModalInstance'];

    function AppAdminCreateUserDialogController(onCreateUser, properties, $uibModalInstance) {
        var ctrl = this;

        ctrl.$onInit = function () {
            ctrl.selectedUser = {};

            ctrl.serverMessage = undefined;
            ctrl.properties = properties.value;
            ctrl.onCreateUser = onCreateUser.value;
        }

        ctrl.clear = function () {
            $uibModalInstance.dismiss('cancel');
        }

        ctrl.save = function () {
            ctrl.isSaving = true;

            ctrl.onCreateUser({
                user: ctrl.selectedUser
            })
                .then(onSaveSuccess)
                .catch(onSaveError)
                .finally(function () {
                    ctrl.isSaving = false;
                });
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
        }

        function onSaveError(reason) {
            ctrl.serverMessage = reason.data.message;
        }
    }

    angular
        .module('tyallsApp')
        .controller('AppAdminCreateUserDialogController', AppAdminCreateUserDialogController);
})();
