/*
 * Modal Routed Component
 */
(function() {
  'use strict';

  angular
    .module('tyallsApp')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider
        .state('app.prop.app-admin.create-user-invite-dialog', {
          // url: '/user/create-invite',  // modal -- no url
          params: { 
            propertyId: null,
            onCreateUserInvite: null,
          },
          data: {
            authorities: []
          },
          resolve: {
            propertyId: ['$transition$', function($transition$) {
              return $transition$.params().propertyId;
            }],
            onCreateUserInvite: ['$transition$', function($transition$) {
              return $transition$.params().onCreateUserInvite;
            }]
          },
          onEnter: ['onCreateUserInvite', 'propertyId', '$state', '$uibModal', function(onCreateUserInvite, propertyId, $state, $uibModal) {
            $uibModal.open({
              templateUrl: 'app/entities/app-admin/create-user-invite-dialog/app-admin-create-user-invite-dialog.html',
              controller: 'AppAdminCreateUserInviteDialogController',
              controllerAs: '$ctrl',
              backdrop: 'static',
              size: 'md',
              resolve: {
                propertyId: { value: propertyId },
                onCreateUserInvite: { value: onCreateUserInvite },
              }
            }).result.then(function() {
              $state.go('^');
            }, function() {
              $state.go('^');
            });
          }]
        });

    }]);
})();
