(function() {

  'use strict';

  angular
    .module('tyallsApp')
    .directive('portfolioSnapshot', portfolioSnapshot);

  portfolioSnapshot.$inject = [];

  function portfolioSnapshot() {
    return {
      restrict: 'E',
      scope: {
        snapshot: '=',
      },
      templateUrl: 'app/entities/portfolio/portfolio-snapshot.html',
      link: link
    };

    function link(scope, el, attr) {
      scope.wiCount = 0;
      scope.wiCompletedCount = 0;
      scope.wiNonCompletedCount = 0;
      scope.wiOverdueCount = 0;

      scope.$watch('snapshot', function(newValue) {
        if (newValue) {
          activate();
        }
      });

      activate();

      function activate() {

        // reset all values
          scope.wiCount = 0;
          scope.wiCompletedCount = 0;
          scope.wiNonCompletedCount = 0;
          scope.wiOverdueCount = 0;


        // loop over all dates and get the summary for each
        angular.forEach(scope.snapshot, function(value, key) {
          scope.wiCount += value.wiCount;
          scope.wiCompletedCount += value.wiCompletedCount;
          scope.wiNonCompletedCount += value.wiNonCompletedCount;
          scope.wiOverdueCount += value.wiOverdueCount;
        })

      }

    };
  }
}());
