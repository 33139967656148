(function () {
  'use strict';

  PortfolioController.$inject = ['DailySummaryService', 'moment', '$q', 'bsLoadingOverlayService',];

  function PortfolioController(DailySummaryService, moment, $q, bsLoadingOverlayService) {
    var ctrl = this;

    ctrl.$onInit = function () {
      ctrl.property = ctrl.property;
      ctrl.startDate = null;
      ctrl.endDate = null;
      ctrl.portfolio = null;
    }

    ctrl.onSubmit = function () {

      // for each property, get the summary for the date range
      bsLoadingOverlayService.start();
      var promises = [];
      for (var i = 0; i < ctrl.property.length; i++) {
        promises.push(
            DailySummaryService
                .getDailySummaryDetailForProperty(ctrl.property[i].id,
                    moment(ctrl.startDate).format('YYYY-MM-DD'),
                    moment(ctrl.endDate).format('YYYY-MM-DD'))
        );

      }

      $q
          .all(promises)
          .then(function (values) {
            ctrl.portfolio = values;
          })
          .catch(function (reason) {
            console.log("Error processing summaries");
          }).finally(function () {
        bsLoadingOverlayService.stop();
      });
    }
  }

  angular
      .module('tyallsApp')
      .controller('PortfolioController', PortfolioController);

})();
