(function() {

  'use strict';

  PropertyProfileCardListController.$inject = ['$state'];

  function PropertyProfileCardListController($state) {
    var ctrl = this; 
    
    ctrl.goToAddPropertyProfile = function() {
      $state.go('.add-property-profile-dialog', {
        'id': ctrl.user.id,
        'user': ctrl.user,
        'propertyId': ctrl.propertyId,
        'onSuccessfulEdit': addPropertyProfile,
      });
    }

    ctrl.removePropertyProfile = function($event) {
      return ctrl.onRemovePropertyProfile({
        $event: {
          userId : ctrl.user.id,
          propertyProfileId : $event.propertyProfileId,
        }
      }); 
    }
    
    var addPropertyProfile = function($event) {
      return ctrl.onAddPropertyProfile({
        $event: {
          userId : ctrl.user.id,
          propertyProfile : $event.propertyProfile,
        }
      })
      ; 
    }
  }

  angular
    .module('tyallsApp')
    .controller('PropertyProfileCardListController', PropertyProfileCardListController);
}());

