/*
 * Routed Component
 */
(function () {

    'use strict';

    var home = {
        templateUrl: 'app/home/home.html',
        bindings: {
            account: '<'
        },
        controller: 'HomeController'
    };

    angular.module('tyallsApp')
        .component('home', home)
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('app.home', {
                    url: '/',
                    component: 'home',
                    // views: {
                    //   'home@app': home
                    // },
                    data: {
                        authorities: []
                    },
                });

        }]);


    function stateConfig($stateProvider) {

    }
})();
