(function () {

    'use strict';

    AuditBasketConsoleController.$inject = ['AuditBasketService'];

    function AuditBasketConsoleController(AuditBasketService) {
        var ctrl = this;

        ctrl.$onInit = function () {
            ctrl.hasError = false;
            ctrl.auditBasketsLoaded = false;
            ctrl.auditBaskets = null;
        };

        ctrl.$onChanges = function (changes) {
            ctrl.hasError = false;
            ctrl.auditBasketsLoaded = false;
            ctrl.auditBaskets = null;
            if (changes.propertyId) {
                AuditBasketService.listByPropertyId(ctrl.propertyId)
                    .then(function (response) {
                        ctrl.auditBaskets = response.data;
                        ctrl.auditBasketsLoaded = true;
                    })
                    .catch(function (err) {
                        ctrl.hasError = true;
                        ctrl.auditBaskets = null;
                        console.log('Has error' + ctrl.hasError);
                        console.log('Unable to load audit baskets', err);

                    }
                    )
                ;
            }
        };

        ctrl.createAuditBasket = function ($event) {
            return AuditBasketService.saveAuditBasket(ctrl.propertyId, $event.auditBasketRequest)
                .then(function (response) {
                    ctrl.auditBaskets.push(response.data);
                });
        };
    }

    angular
        .module('tyallsApp')
        .controller('AuditBasketConsoleController', AuditBasketConsoleController);
})();
