(function () {

    'use strict';

    NonDailyLauncherController.$inject = ['WorkflowInstanceService', 'UploadFileService'];

    function NonDailyLauncherController(WorkflowInstanceService, UploadFileService) {
        var ctrl = this;

        ctrl.$onInit = function () {
            ctrl.property = ctrl.property;
            ctrl.selectedDueDate = null;
            ctrl.launched = [];
            ctrl.launchList = [];

            activate();
        }

        ctrl.hasConfirmedLaunchRequest = function () {
            if (!ctrl.launchRequests) return false;

            // confirmed launch;
            var confirmedLaunch = false;

            angular.forEach(ctrl.launchRequests, function (request) {
                confirmedLaunch = confirmedLaunch || (request.confirm && request.confirm === true);
            }, confirmedLaunch);

            return confirmedLaunch;
        }

        var activate = function () {
            ctrl.launchRequests = [];

            // build out the launchRequests collection
            angular.forEach(ctrl.availableWfds, function (value, key) {
                var request = {};
                request.processDefinitionId = value.id;
                request.processDefinitionName = value.name;
                // request.isLaunched = ctrl.isLaunched(request) ? 'Launched' : 'No';
                // add the dueDate to the request
                // request.dueDate = ctrl.launched.filter(function(l){return l.processDefinitionId === value.id})[0].dueDate
                this.push(request);

            }, ctrl.launchRequests);

        }

        ctrl.doUpload = function () {
            // vm.uploading = true;
            // bsLoadingOverlayService.start();

            // build a list of promises
            var promises = [];
            for (var key in ctrl.launchRequests) {

                if (!!ctrl.launchRequests[key].confirm) {
                    ctrl.launchRequests[key].status = 'launching...';
                    UploadFileService
                        .handleUpload(
                            ctrl.propertyId,
                            ctrl.launchRequests[key].processDefinitionId,
                            ctrl.launchRequests[key].selectedDueDate,
                            ctrl.launchRequests[key].file)
                        .then(function (response) {
                            // find the launchRequest that matches
                            // response.data.processDefinitionId

                            angular.forEach(ctrl.launchRequests, function (request) {
                                if (request.processDefinitionId === response.data.processDefinitionId) {
                                    request.status = 'done';
                                    request.dueDate = response.data.dueDate;
                                    request.isLaunched = 'Launched';
                                    reset(response.data.processDefinitionId);
                                }
                            });
                        });
                }
            }
        }

        var reset = function (processDefinitionId) {
            angular.forEach(ctrl.launchRequests, function (request) {
                if (request.processDefinitionId === processDefinitionId) {
                    request.confirm = false;
                }
            });
        }

        ctrl.selectProperty = function (event) {
            // ctrl.selectedProperty = ctrl.property.filter(function(value) { return value.id === event.propertyId})[0];
        }

        ctrl.isLaunched = function (available) {
            if (available === null || ctrl.launched === null) return false;

            return ctrl
                .launched
                .filter(function (value) {
                    var ret = value.processDefinitionId === available.processDefinitionId;
                    return ret;
                }).length > 0;

        }
    }

    angular
        .module('tyallsApp')
        .controller('NonDailyLauncherController', NonDailyLauncherController);
})();
