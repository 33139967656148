/*
 * Routed Component
 */
(function() {
  'use strict';

  // share the component with prop-admin
  var readAuditBasket = {
    templateUrl: 'app/entities/audit-basket-viewer/read-audit-basket.html',
    bindings: {
      propertyId: '<',
      auditBasket: '<',
    },
    controller: 'ReadAuditBasketController',
  }

  angular
    .module('tyallsApp')
    .component('readAuditBasket', readAuditBasket)
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider 
        .state('app.prop.audit-basket-viewer.read-audit-basket', {
          url: '/audit-baskets/:id',
          params: {
            auditBasket: null,
          }, // non-url params
          data: {
            authorities: []
          },
          views: {
            'audit-basket-viewer-content@^' : 'readAuditBasket',
          },
          resolve: {
            auditBasket: ['AuditBasketService', '$transition$', function(AuditBasketService, $transition$) {
              var auditBasketId = $transition$.params().auditBasket.id;
              var propertyId = $transition$.params().propertyId;
              return AuditBasketService.listByPropertyIdAndAuditBasketId(propertyId, auditBasketId)
              .then(function(response){
                return response.data; 
              });
            }],
            propertyId: ['$transition$', function($transition$) {
              return $transition$.params().propertyId;
            }],
          },
        });

    }]);

})();
