(function() {

  'use strict';
  
  UserAdminConsoleController.$inject = [ 'UserService', '$state' ];

  function UserAdminConsoleController( UserService, $state ) {
    var ctrl = this; 

    ctrl.$onInit = function() {
    }

    ctrl.goToCreateUser = function() {
      $state.go('app.app-admin.create-user-dialog', { 
        propertyId: ctrl.propertyId,
        onCreateUser: ctrl.createUser,
      });
    }

    ctrl.goToUserDetail = function(user) {
      $state.go('app.app-admin.detail-user-dialog', {
        user: user,
        onAddUserToProperty: ctrl.addUserToProperty,
        onRemoveUserFromProperty: ctrl.removeUserFromProperty,
      });
    };
    
    ctrl.addUserToProperty = function($event) {
      return UserService.addUserToProperty($event.user, $event.property);
    }
    
    ctrl.removeUserFromProperty = function($event) {
      return UserService.removeUserFromProperty($event.user, $event.property);
    }

    // cb -- let caller handle catch, must return promise
    ctrl.createUser = function($event) {
      return UserService.createUser($event.user);
    }

    ctrl.requestToggleEnable = function(user) {
      var titleText = 'Confirm Activate/Deactivate User';
      var bodyText = 'Are you sure you want to Activate/Deactivate user (' + user.username + ')?  This action will reset all memberships and property-profiles.';
      // confirm that the user actually want this
      $state.go('.confirm-toggle-user-dialog', {
        titleText: titleText,
        bodyText: bodyText,
        onPositiveConfirm: ctrl.toggleEnable.bind(ctrl, user)
      });  
    }

    ctrl.toggleEnable = function(user) {
      if (user.enabled) {
        disableUser(user);
      } else {
        enableUser(user);
      }
    }

    function disableUser(user) {
      UserService
        .disableUser(user)
        .then(function(response) {
          // update vm.users
          angular.forEach(ctrl.users, function(value, index) {
            if (value.id === response.data.id) {
              ctrl.users[index] = response.data;
            }
          })
        })
        .catch(function(reason) {
          console.log(reason);
        });
    }

    function enableUser(user) {
      UserService
        .enableUser(user)
        .then(function(response) {
          // update vm.users
          angular.forEach(ctrl.users, function(value, index) {
            if (value.id === response.data.id) {
              ctrl.users[index] = response.data;
            }
          })
        })
        .catch(function(reason) {
          console.log(reason);
        });
    }

  }

  angular
    .module('tyallsApp')
    .controller('UserAdminConsoleController', UserAdminConsoleController);
}());

