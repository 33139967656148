(function() {

  'use strict';
  
  ScheduleAdminConsoleController.$inject = [ 'ScheduleService', '$state' ];

  function ScheduleAdminConsoleController( ScheduleService, $state ) {
    var ctrl = this; 

    ctrl.$onInit = function() {
    }

    ctrl.goToCreateSchedule = function() {
      $state.go('app.app-admin.create-schedule-dialog', { 
        propertyId: ctrl.propertyId,
        onCreateSchedule: ctrl.createSchedule,
      });
    }

    // cb -- let caller handle catch, must return promise
    ctrl.createSchedule = function($event) {
      return ScheduleService.createSchedule($event.schedule);
    }
  }

  angular
    .module('tyallsApp')
    .controller('ScheduleAdminConsoleController', ScheduleAdminConsoleController);
}());

