(function () {

    'use strict';

    ListUserController.$inject = ['UserService', '$q'];

    function ListUserController(UserService, $q) {
        var ctrl = this;

        ctrl.$onInit = function () {
        }

        ctrl.$onChanges = function (changes) {
        }

        // cb -- let caller handle catch
        ctrl.removePropertyProfile = function ($event) {
            return UserService.removeUserFromPropertyProfile($event.userId, $event.propertyProfileId)
                .then(function (response) {
                    angular.forEach(ctrl.users, function (outerValue) {
                        if (outerValue.id === response.data.id) {  // matched user
                            angular.forEach(outerValue.propertyProfiles, function (innerValue, index) {
                                if (innerValue.id === $event.propertyProfileId) {
                                    outerValue.propertyProfiles.splice(index, 1);
                                }

                            })
                        }
                    });
                });
        }

        // cb -- let caller handle catch
        ctrl.removeUserGroup = function ($event) {
            return UserService.removeUserFromUserGroup($event.userId, $event.userGroupId)
                .then(function (response) {
                    angular.forEach(ctrl.users, function (outerValue) {
                        if (outerValue.id === response.data.id) {  // matched user
                            angular.forEach(outerValue.userGroups, function (innerValue, index) {
                                if (innerValue.id === $event.userGroupId) {
                                    outerValue.userGroups.splice(index, 1);
                                }

                            })
                        }
                    })
                });
        }

        // cb -- let caller handle catch
        ctrl.addPropertyProfile = function ($event) {
            return UserService.addUserToPropertyProfile($event.userId, $event.propertyProfile.id)
                .then(function (response) {
                    angular.forEach(ctrl.users, function (outerValue) {
                        if (outerValue.id === $event.userId) {  // matched user
                            var exists = false;
                            angular.forEach(outerValue.propertyProfiles, function (innerValue, index) {
                                if (innerValue.id === $event.propertyProfile.id) {
                                    exists = true;
                                }
                            });
                            if (!exists) outerValue.propertyProfiles.push($event.propertyProfile);
                        }
                    });
                });
        }

        // cb -- let caller handle catch
        ctrl.addUserGroup = function ($event) {
            return UserService.addUserToUserGroup($event.userId, $event.userGroup.id)
                .then(function (response) {
                    angular.forEach(ctrl.users, function (outerValue) {
                        if (outerValue.id === $event.userId) {  // matched user
                            var exists = false;
                            angular.forEach(outerValue.userGroups, function (innerValue, index) {
                                if (innerValue.id === $event.userGroup.id) {
                                    exists = true;
                                }
                            });
                            if (!exists) outerValue.userGroups.push($event.userGroup);
                        }
                    })
                });
        }
    }

    angular
        .module('tyallsApp')
        .controller('ListUserController', ListUserController);
}());

