(function () {

    'use strict';

    WfiDetailTabsController.$inject = ['UploadFileService', '$state'];

    function WfiDetailTabsController(UploadFileService, $state) {
        var ctrl = this;

        ctrl.$onInit = function () {
        }


        ctrl.$onChanges = function (changes) {
        }

        ctrl.downloadFile = function (file) {
            UploadFileService.getUploadStream(ctrl.currentTaskDto.propertyId, file.id)
                .then(function onSuccess(response) {
                    ctrl.file = new Blob([(response.data)], {type: 'application/pdf'});
                    ctrl.fileUrl = URL.createObjectURL(ctrl.file);
                }, function onFailure() {
                    $log.error('oops');
                });
        }


        var createComment = function ($event) {
            return ctrl.onCreateComment({
                $event: {
                    comment: $event.comment
                }
            });
        }

        var createSupportingDocument = function ($event) {
            return ctrl.onCreateSupportingDocument({
                $event: {
                    supportingDocument: $event.supportingDocument
                }
            });
        }

        ctrl.goToCreateComment = function () {
            // go to state
            var params = {
                'propertyId': ctrl.currentTaskDto.propertyId,
                'onCreateComment': createComment,
            };

            $state.go('.create-comment-dialog', params);
        }

        ctrl.goToCreateSupportingDocument = function () {
            // go to state
            var params = {
                'propertyId': ctrl.currentTaskDto.propertyId,
                'onCreateSupportingDocument': createSupportingDocument,
            };

            $state.go('.create-supporting-document-dialog', params);
        }

    }

    angular
        .module('tyallsApp')
        .controller('WfiDetailTabsController', WfiDetailTabsController);
}());

