(function () {

    'use strict';

    FileController.$inject = ['$scope',
        'UploadFileService',
        'ProcessDefinitionDTOService',
        '$log', 'PrincipalService',
        'bsLoadingOverlayService', 'moment', 'CacheFactory'];

    function FileController($scope, UploadFileService, ProcessDefinitionDTOService, $log, PrincipalService, bsLoadingOverlayService, moment, CacheFactory) {
        var ctrl = this;

        ctrl.$onInit = function () {
            ctrl.processDefinitions = null;
            ctrl.properties = ctrl.properties;
            getAccount();
            resetData();
            activate();
        }

        function activate() {
            // if only 1 property, then select it
            if (ctrl.properties.length === 1) {
                ctrl.selectedProperty = ctrl.properties[0];
            }
        }

        function getAccount() {
            PrincipalService.identity().then(function (account) {
                ctrl.account = account;
            });
        }

        ctrl.clearCache = function () {
            console.log("Clearing Cache!");
            var cache = CacheFactory.get("appCache");
            if (cache) {
                console.log("Cache Cleared!");
                cache.removeAll()
            }
        }

        ctrl.doUpload = function () {
            ctrl.uploading = true;
            bsLoadingOverlayService.start();

            UploadFileService.handleUpload(ctrl.selectedProperty.id, ctrl.selectedWorkflow.id, ctrl.selectedDueDate, ctrl.selectedFile)
                .success(function () {
                    bsLoadingOverlayService.stop();
                    ctrl.uploading = false;
                    ctrl.reset();
                    ctrl.clearCache();
                })
                .error(function (error) {
                    bsLoadingOverlayService.stop();
                });
        }

        ctrl.reset = function () {
            $scope.uploadForm.$setPristine();
            $scope.uploadForm.$setUntouched();
            resetData();
        }

        function resetData() {
            ctrl.selectedFile = null;
            ctrl.selectedDueDate = moment().toDate();
            ctrl.selectedWorkflow = null;
        }

        $scope.$watch(function () {
            return ctrl.selectedProperty
        }, function (newValue) {
            if (newValue) {
                $log.debug('Updating available workflowIds for propertyId: ', ctrl.selectedProperty.id);
                ProcessDefinitionDTOService.getAllProcessDefinitionDTO(ctrl.selectedProperty.id)
                    .then(function onSuccess(resp) {
                        $log.debug('returning ', angular.toJson(resp.data, true));
                        ctrl.processDefinitions = resp.data;
                        ctrl.selectedWorkflow = null;
                    });
            }
        });
    }

    angular
        .module('tyallsApp')
        .controller('FileController', FileController);
})();
