(function () {
    'use strict'

    AppNavController.$inject = ['VERSION', '$state', 'AuthService', 'PrincipalService', 'LoginService'];

    function AppNavController(VERSION, $state, AuthService, PrincipalService, LoginService) {
        var ctrl = this;

        ctrl.$onInit = function () {
            ctrl.isNavbarCollapsed = true;
            ctrl.isAuthenticated = PrincipalService.isAuthenticated;

            ctrl.VERSION = VERSION;
            ctrl.$state = $state;

            angular.element(document).ready(function () {
                feather.replace();
            });
        }

        ctrl.login = function () {
            ctrl.collapseNavbar();
            LoginService.open();
        }

        ctrl.logout = function () {
            ctrl.collapseNavbar();
            AuthService.logout();
            $state.go('app.home');
        }

        ctrl.toggleNavbar = function () {
            ctrl.isNavbarCollapsed = !ctrl.isNavbarCollapsed;
        }

        ctrl.collapseNavbar = function () {
            ctrl.isNavbarCollapsed = true;
        }
    }

    angular
        .module('tyallsApp')
        .controller('AppNavController', AppNavController);
}());
