(function() {

  'use strict';

  var propertyProfileCardList = {
    templateUrl: 'app/entities/prop-admin/user-console/property-profile-card-list.html',
    bindings: {
      propertyProfiles: '<',
      user: '<',
      onRemovePropertyProfile: '&',
      onAddPropertyProfile: '&',
      propertyId: '<'
    },
    controller: 'PropertyProfileCardListController',
  }

  angular
    .module('tyallsApp')
    .component('propertyProfileCardList', propertyProfileCardList); 
  ;
}());
