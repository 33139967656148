(function() {

  'use strict';

  var userGroupCard = {
    templateUrl: 'app/entities/prop-admin/user-console/user-group-card.html',
    bindings: {
      user: '<',
      userGroup: '<',
      onRemoveUserGroup: '&'
    },
    controller: 'UserGroupCardController',
  }

  angular
    .module('tyallsApp')
    .component('userGroupCard', userGroupCard);
  ;
}());
