(function () {

    'use strict';

    WfdAdminConsoleController.$inject = ['WorkflowDefinitionService', '$state'];

    function WfdAdminConsoleController(WorkflowDefinitionService, $state) {
        var ctrl = this;

        ctrl.$onInit = function () {
        }

        ctrl.getBpmn = function (wfdId) {
            ('%o', wfdId);


            WorkflowDefinitionService.getUploadStream(wfdId)
                .then(function onSuccess(response) {
                    ctrl.file = new Blob([(response.data)], {type: 'application/pdf'});
                    ctrl.fileUrl = URL.createObjectURL(ctrl.file);
                }, function onFailure() {
                    $log.error('oops');
                });
        }

        ctrl.goToCreateWfd = function () {
            $state.go('app.app-admin.create-wfd-dialog', {
                propertyId: ctrl.propertyId,
                onCreateWfd: ctrl.createWfd,
            });
        }

        // cb -- let caller handle catch, must return promise
        ctrl.createWfd = function ($event) {
            return WorkflowDefinitionService.createWfd($event.wfd);
        }
    }

    angular
        .module('tyallsApp')
        .controller('WfdAdminConsoleController', WfdAdminConsoleController);
}());

