(function() {

    'use strict';

    angular
        .module('tyallsApp')
        .factory('moment', MomentFactory);

    MomentFactory.$inject = ['$window'];

    function MomentFactory($window) {
        return $window.moment; 
    }
})();
