(function () {
    'use strict';

    var wfiDetailTabs = {
        templateUrl: 'app/entities/shared/wfi-selector/wfi-detail-tabs.html',
        bindings: {
            currentTaskDto: '<',
            onCreateComment: '&',
            onCreateSupportingDocument: '&',
        },
        controller: 'WfiDetailTabsController',
    }

    angular
        .module('tyallsApp')
        .component('wfiDetailTabs', wfiDetailTabs)
    ;
})();
