(function () {

    'use strict';


    angular
        .module('tyallsApp')
        .filter('candidatesFilter', candidatesFilter);

    candidatesFilter.$inject = [];

    function candidatesFilter() {
        return function (collection, params) {
            return collection.filter(function (item) {
                // filter each item against existing selected mentions
                return params.filter(function (mention) {
                    return item.id === mention.userId;
                }).length > 0 ? false : true;
            });
        }
    }
}());
