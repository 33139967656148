/*
 * Modal Routed Component
 */
(function () {
    'use strict';

    angular
        .module('tyallsApp')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('app.app-admin.create-user-dialog', {
                    // url: '/user/create',  // modal -- no url
                    params: {
                        onCreateUser: null,
                    },
                    resolve: {
                        properties: ['PropertyService', function (PropertyService) {
                            return PropertyService.getAllPropertiesForAdmin()
                                .then(function (response) {
                                    return response.data;
                                });
                        }],
                        onCreateUser: ['$transition$', function ($transition$) {
                            return $transition$.params().onCreateUser;
                        }]

                    },
                    data: {
                        authorities: []
                    },
                    onEnter: ['onCreateUser', 'properties', '$state', '$uibModal', function (onCreateUser, properties, $state, $uibModal) {
                        $uibModal.open({
                            templateUrl: 'app/entities/app-admin/create-user-dialog/app-admin-create-user-dialog.html',
                            controller: 'AppAdminCreateUserDialogController',
                            controllerAs: '$ctrl',
                            backdrop: 'static',
                            size: 'md',
                            resolve: {
                                properties: {value: properties},
                                onCreateUser: {value: onCreateUser},
                            }
                        }).result.then(function (response) {
                            $state.go('^');
                        }, function () {
                            $state.go('^');
                        });
                    }]
                })

        }]);

})();
