/*
 * Modal Routed Component
 */
(function () {
    'use strict'

    angular
        .module('tyallsApp')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('app.prop.task.create-supporting-document-dialog', {
                    // url: '',  // modal -- no url
                    params: {
                        propertyId: null,
                        onCreateSupportingDocument: null,
                    }, // non-url params
                    resolve: {
                        onCreateSupportingDocument: ['$transition$', function ($transition$) {
                            return $transition$.params().onCreateSupportingDocument;
                        }],
                        propertyId: ['$transition$', function ($transition$) {
                            return $transition$.params().propertyId;
                        }],
                    },
                    onEnter: ['$state', '$uibModal', 'onCreateSupportingDocument', 'propertyId', function ($state, $uibModal, onCreateSupportingDocument, propertyId) {
                        $uibModal.open({
                            templateUrl: 'app/entities/shared/create-supporting-document-dialog/shared-date-create-supporting-document-dialog.html',
                            controller: 'SharedDateCreateSupportingDocumentDialogController',
                            controllerAs: '$ctrl',
                            backdrop: 'static',
                            size: 'md',
                            resolve: {
                                candidates: ['UserService', function (UserService) {
                                    return UserService.listByUserAndProperty(propertyId).then(function (resp) {
                                        return resp.data;
                                    });
                                }],
                                onCreateSupportingDocument: {value: onCreateSupportingDocument},
                            }
                        }).result.then(function (result) {
                            // onCreateSupportingDocument({
                            //   supportingDocument: result
                            // });
                            $state.go('^');
                        }, function () {
                            $state.go('^');
                        });
                    }]
                })
        }]);

}());
