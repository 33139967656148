/*
 * Modal Routed Component
 */
(function() {
  'use strict';

  angular
    .module('tyallsApp')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider 
        .state('app.prop.prop-admin.audit-basket-console.create-audit-basket.add-workflow-instance-dialog', {
          // url: '',  // modal -- no url
          params: { 
            propertyId: null,
            onSuccessfulEdit: null,
          }, // non-url params
          data: {
            authorities: []
          },
          resolve: {
            propertyId: ['$transition$', function($transition$) {
              return $transition$.params().propertyId;
            }],
            onSuccessfulEdit: ['$transition$', function($transition$) {
              return $transition$.params().onSuccessfulEdit;
            }]
          },
          onEnter: ['onSuccessfulEdit', 'propertyId', '$state', '$uibModal', function(onSuccessfulEdit, propertyId, $state, $uibModal) {
            $uibModal.open({
              templateUrl: 'app/entities/prop-admin/audit-basket-console/dialogs/add-workflow-instance/add-workflow-instance-dialog.html',
              controller: 'AddWorkflowInstanceDialogController',
              controllerAs: '$ctrl',
              backdrop: 'static',
              size: 'md',
              resolve: {
                availableWorkflowDefinitions: [ 'ProcessDefinitionDTOService', function(ProcessDefinitionDTOService) {
                  return ProcessDefinitionDTOService
                    .getAllProcessDefinitionDTO(propertyId)
                    .then(function(response) { 
                      return response.data;   
                    });
                }],
                onSuccessfulEdit: { value: onSuccessfulEdit },
              }
            }).result.then(function(response) {
              // onSuccessfulEdit({
              //     workflowInstances: response 
              // });
              $state.go('^');
            }, function(reason) {
              $state.go('^');
            });
          }]
        });

    }]);

})();
