(function () {
    'use strict'

    CalendarRepController.$inject = ['moment', '$log', 'bsLoadingOverlayService'];

    function CalendarRepController(moment, $log, bsLoadingOverlayService) {
        var ctrl = this;

        ctrl.$onInit = function () {
            _activate();
        }

        function _activate() {
            ctrl.selected = ctrl.pivotDate; // scope bound to controller
            ctrl.selected = _removeTimeAndSetSunday(ctrl.selected || moment());
            // ctrl.navMonth = ctrl.selected.clone();
            ctrl.navMonth = ctrl.selected;

            var start = ctrl.selected.clone();
            start.date(1);
            _removeTimeAndSetSunday(start.day(0));

            // build the month
            _buildMonth(start.clone(), ctrl.navMonth.clone())
                .then(function processSummaryCardData(data) {
                    ctrl.processedData = data;
                });
        }

        ctrl.selectDetailDate = function (date) {
            ctrl.onSelectDetailDate({
                $event: {
                    date: date,
                }
            });
        }

        ctrl.$onChanges = function (changes) {
            if (changes.forceRefresh && !changes.forceRefresh.isFirstChange()) {
                _activate();
            }
            if (changes.selectedPropertyIdRefresh && !changes.selectedPropertyIdRefresh.isFirstChange()) {
                _activate();
            }
        }


        // ---------- PRIVATE FUNCTIONS -----------
        // scope.select = function(day) {
        //     scope.selected = day.date;
        // };

        ctrl.next = function () {
            var next = ctrl.navMonth.clone();
            _removeTimeAndSetSunday(next.month(next.month() + 1).date(1));
            ctrl.navMonth.month(ctrl.navMonth.month() + 1);
            _buildMonth(next, ctrl.navMonth)
                .then(function processSummaryCardData(data) {
                    ctrl.processedData = data;
                });
        };

        ctrl.previous = function () {
            var previous = ctrl.navMonth.clone();
            _removeTimeAndSetSunday(previous.month(previous.month() - 1).date(1));
            ctrl.navMonth.month(ctrl.navMonth.month() - 1);
            _buildMonth(previous, ctrl.navMonth)
                .then(function processSummaryCardData(data) {
                    ctrl.processedData = data;
                });
        }

        function _removeTimeAndSetSunday(date) {
            return date.day(0).hour(0).minute(0).second(0).millisecond(0);
        }

        function _buildMonth(datePointer, navMonth) {

            ctrl.weeks = []; // reset the weeks
            var done = false;
            var monthIndex = datePointer.month();
            var count = 0;
            var startDate = datePointer.clone();
            var endDate;
            while (!done) {
                ctrl.weeks.push({days: _buildWeek(datePointer.clone(), navMonth.clone())});
                datePointer.add(1, "w");
                done = count++ > 2 && monthIndex !== datePointer.month(); // do at least 4 iterations and month boundary crossed in this iteration
                monthIndex = datePointer.month();  // update the logic to track month boundary crossed
            }

            // endDate needs updating
            endDate = datePointer.subtract(1, "d");

            // fetch the summary data
            bsLoadingOverlayService.start();
            return ctrl.getSummaryDataCb({
                start: startDate.format('YYYY-MM-DD'),
                end: endDate.format('YYYY-MM-DD')
            })
                .then(function onSuccess(resp) {
                    bsLoadingOverlayService.stop();
                    return resp;
                }, function onFailure() {
                    bsLoadingOverlayService.stop();
                    $log.error('ouch');
                });
        }

        function _buildWeek(datePointer, month) {
            var days = [];
            for (var i = 0; i < 7; i++) {
                days.push({
                    // name: datePointer.format("dd").substring(0,1),
                    name: datePointer.format("YYYY-MM-DD"),
                    number: datePointer.date(),
                    isCurrentMonth: datePointer.month() === month.month(),
                    isToday: datePointer.isSame(new Date(), "day"),
                    date: datePointer
                });
                datePointer = datePointer.clone();
                datePointer.add(1, "d");
            }
            return days;
        }
    }

    angular
        .module('tyallsApp')
        .controller('CalendarRepController', CalendarRepController);
}());
