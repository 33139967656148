(function() {
    'uses strict';

    angular
        .module('tyallsApp')
        .factory('PDFJSAnnotate', PdfjsAnnotateFactory);

    PdfjsAnnotateFactory.$inject = ['$window'];

    function PdfjsAnnotateFactory($window) {
        return $window.PDFAnnotate;
    }
})();
