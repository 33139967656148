(function() {
    'uses strict';

    angular
        .module('tyallsApp')
        .factory('Util', Util);

    Util.$inject = [];

    function Util() {

        var service = {
            createDateFromDateTimeArray: createDateFromDateTimeArray
        };

        return service;
       
        /* example data
         * [ 2017, 6, 17, 16, 52, 6, 648000000 ]
         *
         * this was 17 June 2017 at 16:52:06
         */
        function createDateFromDateTimeArray(array) {
            // create a date from a LocalDateTime
            var date = new Date(
                array[0],
                array[1]-1,
                array[2],
                array[3],
                array[4],
                array[5]
            );
            return date;
        }
    }
})();
