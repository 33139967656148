/*
 * Modal Routed Component
 */
(function () {
    'use strict'

    angular
        .module('tyallsApp')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('app.prop.task.create-comment-dialog', {
                    // url: '',  // modal -- no url
                    params: {
                        propertyId: null,
                        onCreateComment: null,
                    }, // non-url params
                    resolve: {
                        onCreateComment: ['$transition$', function ($transition$) {
                            return $transition$.params().onCreateComment;
                        }],
                        propertyId: ['$transition$', function ($transition$) {
                            return $transition$.params().propertyId;
                        }],
                    },
                    onEnter: ['$state', '$uibModal', 'onCreateComment', 'propertyId', 'UserService', function ($state, $uibModal, onCreateComment, propertyId, UserService) {
                        $uibModal.open({
                            templateUrl: 'app/entities/shared/create-comment-dialog/shared-date-create-comment-dialog.html',
                            controller: 'SharedDateCreateCommentDialogController',
                            controllerAs: '$ctrl',
                            backdrop: 'static',
                            size: 'md',
                            resolve: {
                                candidates: ['UserService', function (UserService) {
                                    return UserService.listByUserAndProperty(propertyId).then(function (resp) {
                                        return resp.data;
                                    });
                                }],
                                onCreateComment: {value: onCreateComment},
                            }
                        }).result.then(function (result) {
                            // onCreateComment({
                            //   comment: result
                            // });
                            $state.go('^');
                        }, function () {
                            $state.go('^');
                        });
                    }]
                })
        }]);
}());
