(function () {

    'use strict';

    var mentionInput = {
        templateUrl: 'app/entities/shared/create-comment-dialog/mention-input.html',
        bindings: {
            onAddMentionUserId: '&',
            candidates: '<'
        },
        controller: 'MentionInputController',
    }

    angular
        .module('tyallsApp')
        .component('mentionInput', mentionInput)
    ;
})();
