(function() {

    'use strict';

    var auditBasketContainer = {
        templateUrl: 'app/entities/prop-admin/audit-basket-console/audit-basket-viewer/audit-basket-container.html',
        bindings: {
            auditBaskets: '<',
            hasError: '<',
            auditBasketsLoaded: '<',
            property: '<'
        },
        controller: 'AuditBasketContainerController',
    };
  
    angular
    .module('tyallsApp')
    .component('auditBasketContainer', auditBasketContainer)
  ;
})();
