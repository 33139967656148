(function() {
  'use strict';

  var propertySelector = {
    templateUrl: 'app/components/util/property-selector.html', 
    bindings: {
      idToPropertyLookup: '<',  
      defaultPropertyId: '<',   
      onSelectProperty: '&',    
      disabled: '<',            
    },
    controller: 'PropertySelectorController',
  };

  angular
    .module('tyallsApp')
    .component('propertySelector', propertySelector);
})();
