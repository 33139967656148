(function () {

    'use strict';

    var mentionList = {
        templateUrl: 'app/entities/shared/create-comment-dialog/mention-list.html',
        bindings: {
            candidates: '<',
            mentions: '<',
            onAddMention: '&',
            onDeleteMention: '&',
        },
        controller: 'MentionListController',
    }

    angular
        .module('tyallsApp')
        .component('mentionList', mentionList)
    ;
})();
