(function() {
  'use strict';

  AddPropertyProfileDialogController.$inject = ['onSuccessfulEdit', 'user', '$uibModalInstance', 'availablePropertyProfiles',  '$filter'];

  function AddPropertyProfileDialogController(onSuccessfulEdit, user, $uibModalInstance, availablePropertyProfiles, $filter) {
    var ctrl = this;

    ctrl.$onInit = function() {

      ctrl.serverMessage = undefined;
      ctrl.user = user.value;
      ctrl.onSuccessfulEdit = onSuccessfulEdit.value;
      
      ctrl.availablePropertyProfiles = filterPropertyProfiles(availablePropertyProfiles, ctrl.user.propertyProfiles);
    }

    function filterPropertyProfiles(availablePropertyProfiles, filterPropertyProfiles) {
      var filtered = [];
      for (var arr in availablePropertyProfiles) {
        var found = false;
        for (var filter in filterPropertyProfiles) {
          if (filterPropertyProfiles[filter].id === availablePropertyProfiles[arr].id) {
            found = true;
            break;
          }
        };
        if (!found) filtered.push(availablePropertyProfiles[arr]);
      };

      return filtered;
    }

    ctrl.clear = function() {
      $uibModalInstance.dismiss('cancel');
    }

    ctrl.save = function() {
      ctrl.isSaving = true;

      ctrl.onSuccessfulEdit({
        propertyProfile: ctrl.propertyProfile,
      })
        .then(onSaveSuccess)
        .catch(onSaveError)
        .finally(function() {
          ctrl.isSaving = false;
        });
    }
    
    function onSaveSuccess (result) {
      $uibModalInstance.close(result);
    }

    function onSaveError (reason) {
      ctrl.serverMessage = reason.data.message;
    }

  }

  angular
    .module('tyallsApp')
    .controller('AddPropertyProfileDialogController', AddPropertyProfileDialogController);
})();
