(function () {

    'use strict';

    WfiSelectorController.$inject = [];

    function WfiSelectorController() {
        var ctrl = this;

        ctrl.$onInit = function () {
        }

        ctrl.$onChanges = function (changes) {
        }

        ctrl.selectTask = function (task) {
            ctrl.onSelectTask({
                task: task
            });
        }
    }

    angular
        .module('tyallsApp')
        .controller('WfiSelectorController', WfiSelectorController);
}());

