(function () {
    'use strict';


    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('app.account', {
            abstract: true,
            // parent: 'app'
        });
    }

    angular
        .module('tyallsApp')
        .config(stateConfig);
})();
