(function() {

    'use strict';

    AuditBasketContainerController.$inject = [ '$state' ];

    function AuditBasketContainerController( $state ) {
        var ctrl = this;

        ctrl.$onInit = function () {
        };

        ctrl.goToReadAuditBasket = function (auditBasket) {
            $state.go('app.prop.audit-basket-viewer.read-audit-basket',
                {
                    'auditBasket': auditBasket,
                    'id': auditBasket.id,
                });
        };

        ctrl.goToCreateAuditBasket = function () {
            $state.go('app.prop.prop-admin.audit-basket-console.create-audit-basket', {
                'onCreateAuditBasket': ctrl.createAuditBasket,
            });
        };
        ctrl.$onChanges = function (changes) {
        };
    }
    angular
    .module('tyallsApp')
    .controller('AuditBasketContainerController', AuditBasketContainerController);
})();
