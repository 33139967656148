(function() {
  'use strict';

  FilterUserRows.$inject = [];

  function FilterUserRows() {
    return function(users, doFilter, isFiltered) {
      if (!doFilter) {
        return users;
      } else {

        var out = [];
        for(var i = 0; i < users.length; i++) {
          if (!!isFiltered[users[i].id]) {
            out.push(users[i]);
          }
        }
      }
      return out;
    }
  }

  angular
    .module('tyallsApp')
    .filter('filterUserRows', FilterUserRows);

})();  
