(function() {

  'use strict';
  
  UserGroupCardListController.$inject = ['$state'];

  function UserGroupCardListController($state) {
    var ctrl = this; 
    
    ctrl.goToAddUserGroup = function() {
      $state.go('.add-user-group-dialog', {
        'id': ctrl.user.id,
        'user': ctrl.user,
        'propertyId': ctrl.propertyId,
        'onSuccessfulEdit': addUserGroup,
      });
    }

    ctrl.removeUserGroup = function($event) {
      return ctrl.onRemoveUserGroup({
        $event: {
          userId : ctrl.user.id,
          userGroupId : $event.userGroupId,
        }
      });
    }

    var addUserGroup = function($event) {
      return ctrl.onAddUserGroup({
        $event: {
          userId : ctrl.user.id,
          userGroup : $event.userGroup,
        }
      });
    }
  }

  angular
    .module('tyallsApp')
    .controller('UserGroupCardListController', UserGroupCardListController);
}());

