(function () {

    'use strict';

    HomeController.$inject = ['$scope', 'PrincipalService', 'LoginService'];

    function HomeController($scope, PrincipalService, LoginService) {
        var ctrl = this;

        // ctrl.account = null;
        // ctrl.isAuthenticated = null;
        ctrl.login = LoginService.open;

        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });

        ctrl.$onInit = function () {
            getAccount();
        };

        function getAccount() {
            PrincipalService.identity().then(function (account) {
                ctrl.account = account;
                ctrl.isAuthenticated = PrincipalService.isAuthenticated;
            });
        }
    }

    angular.module('tyallsApp')
        .controller('HomeController', HomeController);

})();
