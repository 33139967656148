(function() {                  
  'use strict';

  LoginDialogController.$inject = ['$rootScope', '$state', '$timeout', 'AuthService', '$uibModalInstance'];

  function LoginDialogController ($rootScope, $state, $timeout, AuthService, $uibModalInstance) {
    var ctrl = this;

    ctrl.authenticationError = false;
    ctrl.credentials = {};
    ctrl.password = null;
    ctrl.rememberMe = true;
    ctrl.username = null;
    ctrl.resetUsername = null;
    ctrl.isResetingPassword = false;
    ctrl.hasBeenReset = false
    ctrl.isLoading = false;

    $timeout(function () {
      angular.element('#username').focus();
    });

    ctrl.cancel = function () {
      ctrl.credentials = {
        username: null,
        password: null,
        rememberMe: true
      };
      ctrl.authenticationError = false; 
      $uibModalInstance.dismiss('cancel');
    }       

    ctrl.login = function(event) {        
      event.preventDefault();         
      AuthService.login({
        username: ctrl.username,          
        password: ctrl.password,          
        rememberMe: ctrl.rememberMe       
      }).then(function () {           
        ctrl.authenticationError = false; 
        $uibModalInstance.close();      
        if ($state.current.name === 'register' || $state.current.name === 'activate' ||
          $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
            $state.go('app.home');              
        }

        $rootScope.$broadcast('authenticationSuccess');

        // previousState was set in the authExpiredInterceptor before being redirected to login modal.
        // since login is successful, go to stored previousState and clear previousState
        if (AuthService.getPreviousState()) {
          var previousState = AuthService.getPreviousState();
          AuthService.resetPreviousState();
          $state.go(previousState.name, previousState.params, {reload: true});
        }
        window.DD_RUM && window.DD_RUM.addRumGlobalContext('usr', {
          username: ctrl.username,
        });
      }).catch(function () {
        ctrl.authenticationError = true;
      });
    }

    ctrl.requestResetPassword = function () {
      ctrl.isResetingPassword = true;
    }

    ctrl.close = function () {
      $uibModalInstance.dismiss('cancel');
    }

    ctrl.requestReset = function (event) {

      event.preventDefault();
      ctrl.isLoading = true;
      AuthService.resetPasswordInit(ctrl.resetUsername).then(function () {
        ctrl.isResetingPassword = false;
        ctrl.hasBeenReset = true;
        ctrl.isLoading = false;
      }).catch(function (response) {
        console.log(JSON.stringify(response));
        ctrl.isResetingPassword = false;
        ctrl.hasBeenReset = true;
        ctrl.isLoading = false;
      });
    }
  }

  angular
    .module('tyallsApp')
    .controller('LoginDialogController', LoginDialogController);
})();
