(function() {
  'use strict';

  MentionService.$inject = ['$http'];

  function MentionService($http) {
    var service = {
      query: query,
      markMentionAsRead: markMentionAsRead,
    };

    return service;

    function markMentionAsRead(propertyId, mentionId) {
      return $http
        .put('/api/properties/' + propertyId + '/mentions/' + mentionId + '/read');
    }

    function query(page, limit, showUnreadOnly) {
      return $http.get('/api/mentions?page=' + page + '&limit=' + limit + '&unreadonly=' + showUnreadOnly);
    }
  }

  angular
    .module('tyallsApp')
    .factory('MentionService', MentionService);

})();
