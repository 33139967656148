/*
* Routed Component
*/
(function () {

    'use strict';

    var listAuditBasketAdmin = {
        templateUrl: 'app/entities/prop-admin/audit-basket-console/list-audit-basket-admin.html',
        bindings: {
            propertyId: '<',           // [PARENT.RESOLVE]
            onCreateAuditBasket: '&',  // [UI-VIEW.ATTR]
            auditBaskets: '<',         // [UI-VIEW.ATTR]
            hasError: '<',
            auditBasketsLoaded: '<',
        },
        controller: 'ListAuditBasketAdminController',
    }

    angular
        .module('tyallsApp')
        .component('listAuditBasketAdmin', listAuditBasketAdmin)
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('app.prop.prop-admin.audit-basket-console.list-audit-basket', {
                    data: {
                        authorities: [],
                        pageTitle: 'User Console'
                    },
                    views: {
                        'audit-basket-console-content@^': {
                            component: 'listAuditBasketAdmin',
                        }
                    },
                })

        }]);
}());
