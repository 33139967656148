(function() {
  'use strict';

  RemoveUnassignableProfiles.$inject = [];

  function RemoveUnassignableProfiles() {
    return function(propertyProfiles) {
      var out = [];
      for(var i = 0; i < propertyProfiles.length; i++) {
        if ( 
          propertyProfiles[i].profileName !== 'REVIEWER' && 
          propertyProfiles[i].profileName !== 'REPORTER' && 
          propertyProfiles[i].profileName !== 'INITIATOR' ) {continue;};

        out.push(propertyProfiles[i]);
      }
      return out;
    }
  }

  angular
    .module('tyallsApp')
    .filter('removeUnassignableProfiles', RemoveUnassignableProfiles);

})();  
