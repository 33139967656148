(function() {
    'uses strict';

    angular
        .module('tyallsApp')
        .factory('PDFJS', PdfjsFactory);

    PdfjsFactory.$inject = ['$window'];

    function PdfjsFactory($window) {
        return $window.PDFJS;
    }
})();
