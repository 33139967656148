/*
 * Modal Routed Component
 */
(function() {
  'use strict';

  angular
    .module('tyallsApp')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider 
        .state('app.prop.prop-admin.user-console.list-user.add-user-group-dialog', {
          // url: '',  // modal -- no url
          params: { 
            user: null,
            propertyId: null,
            onSuccessfulEdit: null,
          }, // non-url params
          resolve: {
            user: ['$transition$', function($transition$) {
              return $transition$.params().user;
            }],
            propertyId: ['$transition$', function($transition$) {
              return $transition$.params().propertyId;
            }],
            onSuccessfulEdit: ['$transition$', function($transition$) {
              return $transition$.params().onSuccessfulEdit;
            }]
          },
          data: {
            authorities: []
          },
          onEnter: ['onSuccessfulEdit', 'user', 'propertyId', '$state', '$uibModal', function(onSuccessfulEdit, user, propertyId, $state, $uibModal) {
            $uibModal.open({
              templateUrl: 'app/entities/prop-admin/user-console/dialogs/add-user-group/add-user-group-dialog.html',
              controller: 'AddUserGroupDialogController',
              controllerAs: '$ctrl',
              backdrop: 'static',
              size: 'md',
              resolve: {
                availableGroups: [ 'UserGroupService', function(UserGroupService) {
                  return UserGroupService.getWithPropertyId(propertyId)
                    .then(function(response) {
                      return response.data;
                    });
                }],
                user: { value: user },
                onSuccessfulEdit: { value: onSuccessfulEdit },
              }
            }).result.then(function(response) {
              // onSuccessfulEdit({
              //   userGroup: response,
              // });
              $state.go('^');
            }, function(reason) {
              $state.go('^');
            });
          }]
        })
    }])
})();
