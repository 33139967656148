(function() {
  'use strict';

  AppAdminCreateUserInviteDialogController.$inject = ['propertyId', '$uibModalInstance', 'UserInviteService' ];

  function AppAdminCreateUserInviteDialogController(propertyId, $uibModalInstance, UserInviteService) {
    var ctrl = this;

    ctrl.$onInit = function() {
      ctrl.propertyId = propertyId;
    }

    ctrl.clear = function() {
      $uibModalInstance.dismiss('cancel');
    }

    ctrl.save = function() {
      ctrl.isSaving = true;

      var propertyAndEmailVm = {
        propertyId: ctrl.propertyId,
        email: ctrl.selectedUser.email
      };
      UserInviteService
        .save(propertyAndEmailVm)
        .then(onSaveSuccess)
        .catch(onSaveError);
    }

    function onSaveSuccess (result) {
      $uibModalInstance.close(result);
      ctrl.isSaving = false;
    }

    function onSaveError () {
      ctrl.isSaving = false;
    }
  }

  angular
    .module('tyallsApp')
    .controller('AppAdminCreateUserInviteDialogController', AppAdminCreateUserInviteDialogController);

})();
