(function () {
    'use strict';


    AuthService.$inject = ['$rootScope',
        '$state',
        '$sessionStorage',
        '$q',
        'PrincipalService',
        'AuthServerProvider',
        'LoginService',
        'PasswordResetInitService',
        'PasswordResetFinishService',
        'CacheFactory'];

    function AuthService($rootScope, $state, $sessionStorage, $q, PrincipalService, AuthServerProvider, LoginService, PasswordResetInitService, PasswordResetFinishService, CacheFactory) {
        var service = {
            authorize: authorize,
            getPreviousState: getPreviousState,
            login: login,
            logout: logout,
            resetPasswordFinish: resetPasswordFinish,
            resetPasswordInit: resetPasswordInit,
            resetPreviousState: resetPreviousState,
            storePreviousState: storePreviousState,
        };

        return service;

        function authorize(force) {
            var authReturn = PrincipalService.identity(force).then(authThen);

            return authReturn;

            function authThen() {
                var isAuthenticated = PrincipalService.isAuthenticated();

                // an authenticated user can't access to login and register pages
                if (isAuthenticated && $rootScope.toState.parent === 'account' && ($rootScope.toState.name === 'login' || $rootScope.toState.name === 'register')) {
                    $state.go('app.home');
                }

                // recover and clear previousState after external login redirect (e.g. oauth2)
                if (isAuthenticated && !$rootScope.fromState.name && getPreviousState()) {
                    var previousState = getPreviousState();
                    resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }

                if ($rootScope.toState.data.authorities && $rootScope.toState.data.authorities.length > 0 && !PrincipalService.hasAnyAuthority($rootScope.toState.data.authorities)) {
                    if (isAuthenticated) {
                        // user is signed in but not authorized for desired state
                        $state.go('app.accessdenied');
                    } else {
                        // user is not authenticated. stow the state they wanted before you
                        // send them to the login service, so you can return them when you're done
                        storePreviousState($rootScope.toState.name, $rootScope.toStateParams);

                        // now, send them to the signin state so they can log in
                        $state.go('app.accessdenied').then(function () {
                            LoginService.open();
                        });
                    }
                }
            }
        }

        function login(credentials, callback) {
            var cb = callback || angular.noop;
            var deferred = $q.defer();

            AuthServerProvider.login(credentials)
                .then(loginThen)
                .catch(function (err) {
                    this.logout();
                    deferred.reject(err);
                    return cb(err);
                }.bind(this));

            function loginThen(data) {
                PrincipalService.identity(true).then(function (account) {
                    deferred.resolve(data);
                });
                var cache = CacheFactory.get("appCache");
                if (cache) {
                    cache.removeAll()
                }
                return cb();
            }

            return deferred.promise;
        }


        function logout() {
            AuthServerProvider.logout();
            PrincipalService.authenticate(null);
            var cache = CacheFactory.get("appCache");
            if (cache) {
                cache.removeAll()
            }
        }

        function getPreviousState() {
            var previousState = $sessionStorage.previousState;
            return previousState;
        }

        function resetPreviousState() {
            delete $sessionStorage.previousState;
        }

        function storePreviousState(previousStateName, previousStateParams) {
            var previousState = {"name": previousStateName, "params": previousStateParams};
            $sessionStorage.previousState = previousState;
        }

        function resetPasswordFinish(keyAndPassword, callback) {
            var cb = callback || angular.noop;

            return PasswordResetFinishService.save(keyAndPassword, function () {
                return cb();
            }, function (err) {
                return cb(err);
            }).$promise;
        }

        function resetPasswordInit(mail, callback) {
            var cb = callback || angular.noop;

            return PasswordResetInitService.save(mail, function () {
                return cb();
            }, function (err) {
                return cb(err);
            }).$promise;
        }
    }

    angular
        .module('tyallsApp')
        .factory('AuthService', AuthService);
})();
