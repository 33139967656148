(function () {

  'use strict';

  TaskDTOService.$inject = ['$http', '$q', 'Util', 'moment'];

  function TaskDTOService($http, $q, Util) {
    var service = {
      getTaskDTO: getTaskDTO,
      completeTask: completeTask,
      getCandidateTasksForGroup: getCandidateTasksForGroup,
      claim: claim
    };

    return service;

    function claim(propertyId, taskId) {
      // PUT /api/properties/{propertyId}/tasks/{taskId}/claim  
      return $http.put('/api/properties/' + propertyId + '/tasks/' + taskId + '/claim');
    }

    function getTaskDTO(propertyId, taskId) {
      // GET /api/properties/{propertyId}/tasks/{taskId}  
      return $http.get('/api/properties/' + propertyId + '/tasks/' + taskId, {cache: false}).then(function (resp) {
            // process the response to parse the dates
            resp.data.dueDate = moment.parseZone(resp.data.dueDate);

            return $q.when(resp);
          }
      );
    }

    function completeTask(propertyId, taskId) {
      // PUT /api/properties/{propertyId}/tasks/{taskId}/complete  
      return $http.put('/api/properties/' + propertyId + '/tasks/' + taskId + '/complete');
    }

    function getCandidateTasksForGroup(groupId) {
      // GET /api/groups/{id}/tasks
      // return $q.when(data);
      return $http.get('/api/groups/' + groupId + '/tasks', {cache: false}).then(function (resp) {
        angular.forEach(resp.data, function (task) {
          task.dueDate = moment.parseZone(task.dueDate);
          task.dueDateString = task.dueDate.format('YYYY-MM-DD')
        });
        return $q.when(resp);

      });
    }

  }

  angular
      .module('tyallsApp')
      .factory('TaskDTOService', TaskDTOService);

})();
