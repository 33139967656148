(function () {

    'use strict';

    CommentConsoleController.$inject = ['UserService'];

    function CommentConsoleController(UserService) {
        var ctrl = this;


        ctrl.$onInit = function () {
            UserService.listByUserAndProperty(ctrl.propertyId).then(function (response) {
                ctrl.idToUserLookup = {};
                angular.forEach(response.data, function (value) {
                    this[value.id] = value;
                }, ctrl.idToUserLookup);
            })
                .catch(function (reason) {
                });
        }

        ctrl.$onChanges = function (changes) {
        }

        ctrl.dumpMentionIds = function (comment) {
            var userIds = [];
            angular.forEach(comment.mentions, function (mention) {
                this.push(ctrl.idToUserLookup[mention.userId].username);
            }, userIds);
            return userIds.join(',');
        }

    }

    angular
        .module('tyallsApp')
        .controller('CommentConsoleController', CommentConsoleController);
}());

