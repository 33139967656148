(function () {
    'use strict';

    angular
        .module('tyallsApp')
        .directive('myCanvas', myCanvas);

    myCanvas.$inject = ['PDFJS', 'PDFJSAnnotate', '$log', '$timeout'];

    function myCanvas(PDFJS, PDFJSAnnotate, $log, $timeout) {
        return {
            restrict: 'AEC',
            scope: {
                data: '<',
                annotationsToRender: '<',
                documentId: '<',
                updateUi: '=',
                isSaving: '=?'
            },
            link: link
        };

        function link(scope, elem, attr) {

            var UI = PDFJSAnnotate.UI;
            scope.renderedPages = [];
            var PAGE_HEIGHT;
            var FIRST_PAGE_ROTATION;
            var RENDER_OPTIONS = {};
            var okToRender = false;
            var NUM_PAGES = 0;

            // PDFJS.workerSrc = './app/vendor-pdf.js';
            PDFJS.workerSrc = './pdfjs/pdf.worker.js';
            PDFJS.disableWorker = false;
            var worker = new PDFJS.PDFWorker('pdf-worker');

            var tooltype = 'cursor';

            elem.on('$destroy', function () {
                // RENDER_OPTIONS.pdfDocument.destroy();
            });


            scope.$watch('data', function (newVals, oldVals, scope) {

                scope.wasWatched = true;
                var binaryData = newVals;

                // the savedAnnotations is initialised by PrimarryDocumentViewer
                // then updated by PDFJSAnnotate as annotations drawn
                var myService = {
                    documentId: scope.documentId,
                    savedAnnotations: scope.annotationsToRender
                };

                // get/update the store adapter
                PDFJSAnnotate.setStoreAdapter(new PDFJSAnnotate.MyLocalStoreAdapter(myService));

                RENDER_OPTIONS.documentId = scope.documentId;
                RENDER_OPTIONS.pdfDocument = null;
                RENDER_OPTIONS.scale = 1;
                RENDER_OPTIONS.rotate = 0;
                RENDER_OPTIONS.binaryData = binaryData;

                // reset the document to top
                NUM_PAGES = 0;
                scope.renderedPages = [];
                okToRender = false;
                var currentPage = document.getElementById('viewer').dataset.currentPage;
                render(true, currentPage);
            });

            scope.$watchGroup(['data', 'annotationsToRender'],
                function (newVals, oldVals, scope) {

                    if (scope.wasWatched) {
                        scope.wasWatched = false;
                        return;
                    }

                    var binaryData = newVals[0];

                    // the savedAnnotations is initialised by PrimarryDocumentViewer
                    // then updated by PDFJSAnnotate as annotations drawn
                    var myService = {
                        documentId: scope.documentId,
                        savedAnnotations: scope.annotationsToRender
                    };

                    // get/update the store adapter
                    PDFJSAnnotate.setStoreAdapter(new PDFJSAnnotate.MyLocalStoreAdapter(myService));

                    RENDER_OPTIONS.documentId = scope.documentId;
                    RENDER_OPTIONS.pdfDocument = null;
                    RENDER_OPTIONS.scale = 1;
                    RENDER_OPTIONS.rotate = 0;
                    RENDER_OPTIONS.binaryData = binaryData;

                    // reset the document to top
                    NUM_PAGES = 0;
                    scope.renderedPages = [];
                    okToRender = false;

                    if (scope.updateUi) {
                        render(true);
                    }
                    scope.isSaving = false;
                });


            // ----------START ROTATE/SCALE STUFF----------
            (function () {
                function setScaleRotate(scale, rotate) {
                    scale = parseFloat(scale, 10);
                    rotate = parseInt(rotate, 10);

                    if (RENDER_OPTIONS.scale !== scale || RENDER_OPTIONS.rotate !== rotate) {
                        RENDER_OPTIONS.scale = scale;
                        RENDER_OPTIONS.rotate = rotate;

                        scope.renderedPages = [];  // reset the cached pages
                        render();
                    }
                }

                function handleScaleChange(e) {
                    setScaleRotate(e.target.value, RENDER_OPTIONS.rotate);
                }

                function handleRotateRightClick() {
                    setScaleRotate(RENDER_OPTIONS.scale, RENDER_OPTIONS.rotate + 90);
                }

                function handleRotateLeftClick() {
                    setScaleRotate(RENDER_OPTIONS.scale, RENDER_OPTIONS.rotate - 90);
                }

                document.querySelector('.toolbar-container select#scale').selected = RENDER_OPTIONS.scale;
                document.querySelector('.toolbar-container select#scale').addEventListener('change', handleScaleChange);
                document.querySelector('.toolbar-container #rotateButtonLeft').addEventListener('click', handleRotateLeftClick);
                document.querySelector('.toolbar-container #rotateButtonRight').addEventListener('click', handleRotateRightClick);
            })();
            // ----------END ROTATE/SCALE STUFF----------

            // ----------START TOOLBAR STUFF----------
            (function () {

                var tooltype = 'cursor';
                if (tooltype) {
                    setActiveToolbarItem(tooltype, document.querySelector('.toolbar-container button[data-tooltype=cursor]'));
                }


                function setActiveToolbarItem(type, button) {
                    var active = document.querySelector('.toolbar-container button.active');
                    if (active) {
                        active.classList.remove('active');

                        switch (tooltype) {
                            case 'cursor':
                                UI.disableEdit();
                                break;
                            case 'point':
                                UI.disablePoint();
                                break;
                            case 'area':
                                UI.disableRect();
                                break;
                        }
                    }
                    if (button) {
                        button.classList.add('active');
                    }

                    // update last used type
                    tooltype = type;

                    switch (type) {
                        case 'cursor':
                            UI.enableEdit();
                            break;
                        case 'point':
                            UI.enablePoint();
                            break;
                        case 'area':
                            UI.enableRect(type);
                            break;
                    }
                }

                function handleToolbarClick(e) {
                    if (e.target.nodeName === 'BUTTON') {
                        setActiveToolbarItem(e.target.getAttribute('data-tooltype'), e.target);
                    }
                }

                document.querySelector('.toolbar-container').addEventListener('click', handleToolbarClick);
            })();
            // ----------END TOOLBAR STUFF----------

            // ----------START SCROLL STUFF----------
            (function () {

                function handleScroll(e) {
                    // var margin = 600;
                    var margin = 100;
                    var visiblePageNum = Math.round((e.target.scrollTop + margin) / PAGE_HEIGHT) + 1;
                    var visiblePage = document.querySelector('.page[data-page-number="' + visiblePageNum + '"][data-loaded="false"]');

                    if (Number.isNaN(visiblePageNum) === false && scope.renderedPages.indexOf(visiblePageNum) === -1) {
                        okToRender = true;
                        scope.renderedPages.push(visiblePageNum);
                    } else {
                        okToRender = false;
                    }
                    document.getElementById('viewer').dataset.currentPage = visiblePageNum;
                    if (visiblePage && okToRender) {
                        $timeout(function () {
                            UI.renderPage(visiblePageNum, RENDER_OPTIONS);
                        });
                    }
                }

                document.getElementById('content-wrapper').addEventListener('scroll', handleScroll);
            })();

            // ----------END SCROLL STUFF-----------

            function setDefaultVisiblePage() {
                var margin = 50;
                var currentHeight = document.getElementById('content-wrapper').scrollTop;
                var visiblePageNum = Math.round((currentHeight + margin) / PAGE_HEIGHT) + 1;
                if (Number.isNaN(visiblePageNum)) {
                    visiblePageNum = 1;
                }
                return visiblePageNum;
            }

            function scrollToPage(pageNumber) {
                var visiblePageNum = pageNumber;
                if (visiblePageNum) {
                    var margin = 50;
                    var currentHeight = document.getElementById('content-wrapper').scrollTop;
                    visiblePageNum = Math.round((currentHeight + margin) / PAGE_HEIGHT) + 1;
                }
                var newScroll = currentHeight - ((visiblePageNum - 1) * (PAGE_HEIGHT - 155));
                document.getElementById('content-wrapper').scrollTop = newScroll;
            }

            function render(initScroll, intiPage) {

                var loadingTask = PDFJS.getDocument({
                    url: RENDER_OPTIONS.binaryData,
                    worker: worker,
                });
                loadingTask.promise.then(function (pdf) {

                    var visiblePageNum = setDefaultVisiblePage();

                    if (intiPage) {
                        scrollToPage(intiPage);
                    } else if (initScroll) {
                        document.getElementById('content-wrapper').scrollTop = 0;
                    } else {
                        scrollToPage();
                    }
                    document.getElementById('viewer').dataset.currentPage = visiblePageNum;

                    RENDER_OPTIONS.pdfDocument = pdf;

                    var viewer = document.getElementById('viewer');
                    viewer.innerHTML = '';
                    NUM_PAGES = pdf.pdfInfo.numPages;
                    for (var i = 0; i < NUM_PAGES; i++) {
                        var pageNum = i + 1;
                        var page = UI.createPage(pageNum);
                        viewer.appendChild(page);
                    }

                    scope.renderedPages.push(visiblePageNum);

                    UI.renderPage(visiblePageNum, RENDER_OPTIONS).then(function (results) {
                        var pdfPage = results[0];
                        var annotations = results[1];

                        var viewport = pdfPage.getViewport(RENDER_OPTIONS.scale, RENDER_OPTIONS.rotate);
                        // set height to be height of page 1
                        PAGE_HEIGHT = viewport.height;
                        if (pdfPage.pageInfo.rotate !== 0) {
                            PAGE_HEIGHT = viewport.width;
                        }
                    }).then(function () {
                        if (scope.firstRender) {
                            scope.firstRender = false;
                        }
                    });
                });
            }

        }
    }
})();
