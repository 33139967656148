(function () {

    'use strict';

    MentionDetailController.$inject = ['$filter'];

    function MentionDetailController($filter) {
        var ctrl = this;

        ctrl.delete = function () {
            ('calling MentionDetailController: %o', ctrl.mention.userId);
            ctrl.onDeleteMentionUserId({userId: ctrl.mention.userId});
        }

        ctrl.getUsernameFromId = function (id) {

            var results = $filter('filter')(ctrl.idToUserLookup, {id: id}, true);
            return results[0].username;
        }
    }

    angular
        .module('tyallsApp')
        .controller('MentionDetailController', MentionDetailController);
}());

