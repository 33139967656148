(function() {
  'use strict';

  ProfileService.$inject = ['$http'];

  function ProfileService($http) {
    var service = {
      getAllProfilesForUser: getAllProfilesForUser,
      getAllProfilesForAdmin: getAllProfilesForAdmin,
    };

    return service;

    function getAllProfilesForUser() {
      // GET /api/properties
      return $http.get('/api/profiles');
    }

    function getAllProfilesForAdmin() {
      // GET /admin/properties
      return $http.get('/admin/profiles');
    }
  }

  angular
    .module('tyallsApp')
    .factory('ProfileService', ProfileService);
})();
