(function () {

    'use strict';

    AuditController.$inject = ['DailySummaryService', '$state', '$document', '$window', '$scope',
        'ProcessDefinitionDTOService', 'bsLoadingOverlayService', 'moment', '$q', 'AuditService'];

    function AuditController(DailySummaryService, $state, $document, $window, $scope, ProcessDefinitionDTOService, bsLoadingOverlayService, moment, $q, AuditService) {
        var ctrl = this;

        ctrl.$onInit = function () {
            ctrl.properties = ctrl.properties.data;
            ctrl.selectedProperties = [];

            ctrl.workflowDefinitions = [];
            ctrl.selectedWorkflowDefinitions = [];

            ctrl.searchResults = [];
            ctrl.hasSelectedWorkflow = false;
        }

        ctrl.checkStartDate = function () {
            var sdate = new Date(ctrl.queryForm.startDate.$viewValue);
            var edate = new Date(ctrl.queryForm.endDate.$viewValue);
            if (ctrl.queryForm.startDate.$valid && sdate > edate) {
                ctrl.queryForm.endDate.$setValidity('end-date-after-start-date', false);
            } else {
                ctrl.queryForm.endDate.$setValidity('end-date-after-start-date', true);
            }
        }

        ctrl.doSearch = function () {
            bsLoadingOverlayService.start();

            performAuditQuery(false)
                .then(function (results) {
                    ctrl.searchResults = results.data;
                    bsLoadingOverlayService.stop();
                })
                .catch(function () {
                    bsLoadingOverlayService.stop();
                });
        }

        ctrl.downloadPdf = function () {
            bsLoadingOverlayService.start();

            performAuditQuery({format: 'pdf'})
                .success(function (response) {
                    if ($window.navigator.msSaveOrOpenBlob) {
                        $window.navigator.msSaveOrOpenBlob(new Blob([response.data]), response.filename);
                    } else {
                        var url = URL.createObjectURL(new Blob([response.data]));
                        var a = $document[0].createElement('a');
                        a.href = url;
                        a.download = response.filename;
                        a.target = '_blank';
                        a.click();
                    }
                    bsLoadingOverlayService.stop();
                })
                .catch(function () {
                    bsLoadingOverlayService.stop();
                });
        }

        ctrl.downloadCsv = function () {
            bsLoadingOverlayService.start();

            performAuditQuery({format: 'csv'})
                .success(function (response) {
                    if ($window.navigator.msSaveOrOpenBlob) {
                        $window.navigator.msSaveOrOpenBlob(new Blob([response.data]), response.filename);
                    } else {
                        var url = URL.createObjectURL(new Blob([response.data]));
                        var a = $document[0].createElement('a');
                        a.href = url;
                        a.download = response.filename;
                        a.target = '_blank';
                        a.click();
                    }
                    bsLoadingOverlayService.stop();
                })
                .catch(function () {
                    bsLoadingOverlayService.stop();
                });
        }

        ctrl.fetchWorkflowDefinitions = function () {
            // fetch all the workflowDefinitions for each property
            var promises = [];
            for (var i = 0; i < ctrl.selectedProperties.length; i++) {
                promises.push(ProcessDefinitionDTOService.getAllProcessDefinitionDTO(ctrl.selectedProperties[i].id));
            }

            return $q.all(promises)
        }

        ctrl.changeState = function (result) {
            console.log("result: %o", result);

            // map the propertyCode to the propertyId
            var propertyId;
            angular.forEach(ctrl.selectedProperties, function (property) {
                if (property.propertyCode === result.propertyCode) {
                    propertyId = property.id;
                }
            });

            DailySummaryService
                .getTaskForMention(propertyId, result.wfiId)
                .then(function (resp) {
                    $state.go('app.prop.task', {
                        'propertyId': propertyId,
                        'date': result.dueDate,
                        'tasks': resp.data,
                        'returnTo': function () {
                            $state.go('app.audit');
                        },
                    });
                });
        }

        function performAuditQuery(options) {
            // consolidate criteria
            const selectedWorkflowDefinitionIds = [];
            angular.forEach(ctrl.selectedWorkflowDefinitions, function (value) {
                this.push(value.id);
            }, selectedWorkflowDefinitionIds);

            const selectedPropCodes = [];
            angular.forEach(ctrl.selectedProperties, function (value) {
                this.push(value.propertyCode);
            }, selectedPropCodes);
            const criteria = {
                'properties': selectedPropCodes,
                'selectAllWorkflows': !ctrl.hasSelectedWorkflow,
                'workflowDefinitionIds': selectedWorkflowDefinitionIds,
                'startDate': moment(ctrl.startDate).format('DD/MM/YYYY'),
                'endDate': moment(ctrl.endDate).format('DD/MM/YYYY'),
            };

            return AuditService.searchAudit(criteria, options);
        }

        $scope.$watch(function () {
            return ctrl.selectedProperties
        }, function (newValue) {
            if (newValue) {
                ctrl.fetchWorkflowDefinitions()
                    .then(function (results) {
                        // loop over all the results and extract the data and flatmap
                        ctrl.workflowDefinitions = [];
                        angular.forEach(results, function (value) {
                            angular.forEach(value.data, function (pd) {
                                // lookup propertyCode
                                var propertyCode;
                                angular.forEach(ctrl.selectedProperties, function (property) {
                                    if (property.id === pd.propertyId) {
                                        propertyCode = property.propertyCode;
                                    }
                                }, propertyCode);
                                pd.propertyCode = propertyCode;
                                ctrl.workflowDefinitions.push(pd);
                            });
                        });
                    })
                    .catch(function (response) {

                    });
            }
        });
    }

    angular
        .module('tyallsApp')
        .controller('AuditController', AuditController);

})();
