(function() {                  
  'use strict';

  LoginService.$inject = ['$uibModal'];

  function LoginService ($uibModal) {
    var service = {        
      open: open
    };

    var modalInstance = null;
    var resetModal = function () {  
      modalInstance = null;           
    };  

    return service;

    function open () {     
      if (modalInstance !== null) return;
      // modals must use controllers!
      modalInstance = $uibModal.open({
        animation: true,                
        templateUrl: 'app/components/login/login-dialog.html',
        controller: 'LoginDialogController',  
        controllerAs: '$ctrl',             
      });
      modalInstance.result.then(      
        resetModal,
        resetModal
      );
    }   
  }

  angular
    .module('tyallsApp')
    .factory('LoginService', LoginService);

})();
