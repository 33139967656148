(function () {

    'use strict';

    MentionInputController.$inject = [];

    function MentionInputController() {
        var ctrl = this;

        ctrl.userId = '';

        ctrl.addMention = function () {
            ctrl.onAddMentionUserId({userId: ctrl.userId});
        }
    }

    angular
        .module('tyallsApp')
        .controller('MentionInputController', MentionInputController);
}());

