(function() {

  'use strict';

  ProcessDefinitionDTOService.$inject = ['$http'];

  function ProcessDefinitionDTOService($http) {
    var service = {
      getAllProcessDefinitionDTO: getAllProcessDefinitionDTO,
      getAllDailyProcessDefinitionDTO: getAllDailyProcessDefinitionDTO,
      getAllNonDailyProcessDefinitionDTO: getAllNonDailyProcessDefinitionDTO,
      getAllProcessDefinitionDTOByDueDate: getAllProcessDefinitionDTOByDueDate,
    };

    return service;

    function getAllProcessDefinitionDTO(propertyId) {
      // GET /api/process-definitions/property/{propertyId}
      return $http.get('/api/process-definitions/property/' + propertyId, {cache: true});
    }
    
    function getAllDailyProcessDefinitionDTO(propertyId) {
      // GET /api/process-definitions/property/{propertyId}
      return $http.get('/api/process-definitions/property/' + propertyId + "?daily-state=DAILY", {cache: true});
    }
    
    function getAllNonDailyProcessDefinitionDTO(propertyId) {
      // GET /api/process-definitions/property/{propertyId}
      return $http.get('/api/process-definitions/property/' + propertyId + "?daily-state=NONDAILY", {cache: true});
    }
    
    function getAllProcessDefinitionDTOByDueDate(propertyId, dueDate) {
      // GET /api/process-definitions/property/{propertyId}
      return $http.get('/api/process-definitions/property/' + propertyId
          + '?due-date=' + moment(dueDate).format('DD-MM-YYYY'), {cache: true}
      );
    }
  }

  angular
    .module('tyallsApp')
    .factory('ProcessDefinitionDTOService', ProcessDefinitionDTOService);
})();

