(function () {
    'use strict';

    var calendarRep = {
        templateUrl: 'app/entities/shared/calendar-rep/calendar-rep.html',
        bindings: {
            propertyId: '<',
            pivotDate: '<',
            onUpdatePivotDate: '&',
            getSummaryDataCb: '&',
            onSelectDetailDate: '&',
            selectedPropertyIdRefresh: '<',
            forceRefresh: '<',
        },
        controller: 'CalendarRepController',
    }

    angular
        .module('tyallsApp')
        .component('calendarRep', calendarRep);
})();
