(function () {
    'use strict';

    MentionController.$inject = ['$state', 'DailySummaryService', 'MentionService', 'bsLoadingOverlayService'];

    function MentionController($state, DailySummaryService, MentionService, bsLoadingOverlayService) {
        var ctrl = this;

        ctrl.filterUnread = function (item) {
            if (!!ctrl.showUnreadOnly === false) return true;
            return !!item.readDate === ctrl.showUnreadOnly ? false : true;
        }

        ctrl.$onInit = function () {
            ctrl.showUnreadOnly = true;
        }

        ctrl.getPage = function (page) {
            bsLoadingOverlayService.start();
            MentionService.query(page, 10, ctrl.showUnreadOnly)
                .then(function (resp) {
                    ctrl.mentionsPage = resp.data;
                })
                .finally(function () {
                    bsLoadingOverlayService.stop();
                });
        }

        ctrl.changeState = function (propertyId, wfiId, mentionId) {
            MentionService
                .markMentionAsRead(propertyId, mentionId)
                .then(function () {
                    return DailySummaryService
                        .getTaskForMention(propertyId, wfiId);
                })
                .then(function (resp) {
                    $state.go('app.prop.task', {
                        'propertyId': propertyId,
                        'date': resp.data[0].dueDate,
                        'tasks': resp.data,
                        'returnTo': function () {
                            $state.go('app.mentions', {}, {reload: true});
                        },
                    });
                });
        }
    }

    angular
        .module('tyallsApp')
        .controller('MentionController', MentionController);
})();
