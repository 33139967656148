(function() {

  'use strict';

  ListAuditBasketAdminController.$inject = [ '$state' ];

  function ListAuditBasketAdminController( $state ) {
    var ctrl = this; 

    ctrl.$onInit = function() {
    }

    ctrl.goToCreateAuditBasket = function() {
      $state.go('app.prop.prop-admin.audit-basket-console.create-audit-basket', {
        'onCreateAuditBasket': ctrl.createAuditBasket,
      });
    }

    ctrl.$onChanges = function(changes) {
      console.log(JSON.stringify(changes));
    }
  }

  angular
    .module('tyallsApp')
    .controller('ListAuditBasketAdminController', ListAuditBasketAdminController);
}());
