/*
 * Routed Component
 */
(function () {
    'use strict';

    var nonDailyLauncher = {
        templateUrl: 'app/entities/launcher/non-daily-launcher.html',
        bindings: {
            propertyId: '<',         // [PARENT.RESOLVE]
            availableWfds: '<',
        },
        controller: 'NonDailyLauncherController'
    };

    angular
        .module('tyallsApp')
        .component('nonDailyLauncher', nonDailyLauncher)
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('app.prop.launch-console.non-daily-launcher', {
                    url: '/non-daily-launcher',
                    views: {
                        'non-daily-launcher@^': 'nonDailyLauncher',
                    },
                    data: {
                        authorities: [],
                        pageTitle: 'Non Daily Launcher'
                    },
                    resolve: {
                        availableWfds: ['ProcessDefinitionDTOService', 'propertyId', function (ProcessDefinitionDTOService, propertyId) {
                            return ProcessDefinitionDTOService
                                .getAllNonDailyProcessDefinitionDTO(propertyId)
                                .then(function (response) {
                                    return response.data;
                                })
                                ;
                        }]
                    }
                })
        }]);
}());
