(function() {
    'use strict';

    AddAuditorDialogController.$inject = ['onSuccessfulEdit', 'availableAuditors', '$uibModalInstance'];

    function AddAuditorDialogController(onSuccessfulEdit, availableAuditors, $uibModalInstance) {
        var ctrl = this;

        ctrl.$onInit = function() {
            ctrl.auditors = [];

            ctrl.availableAuditors = availableAuditors;
            ctrl.serverMessage = undefined;
            ctrl.onSuccessfulEdit = onSuccessfulEdit.value;
        };

        ctrl.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        ctrl.save = function() {
            ctrl.isSaving = true;

      // return the selected wfis
            var selectedAuditors = [];
      
            angular.forEach( ctrl.availableAuditors, function(value) {
                if (value.selected === true) {
                    this.push(value);
                }
            }, selectedAuditors);

            ctrl.onSuccessfulEdit({
                auditors: selectedAuditors, 
            })
        .then(onSaveSuccess)
        .catch(onSaveError)
        .finally(function() {
            ctrl.isSaving = false;
        });

        };

        function onSaveSuccess (result) {
            $uibModalInstance.close(result);
        }

        function onSaveError (reason) {
            ctrl.serverMessage = reason.data.message;
        }

    }

    angular
    .module('tyallsApp')
    .controller('AddAuditorDialogController', AddAuditorDialogController);
})();
