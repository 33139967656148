(function () {
    'use strict'

    var inbox = {
        templateUrl: 'app/entities/shared/reviewer-month/inbox.html',
        bindings: {
            account: '<',
            onClaim: '&',
            selectedPropertyIdRefresh: '<'
        },
        controller: 'InboxController',
    };

    angular
        .module('tyallsApp')
        .component('inbox', inbox);
}());
