(function () {

    'use strict';

    SummaryCardRepController.$inject = [];

    function SummaryCardRepController() {
        var ctrl = this;

        ctrl.selectDetailDate = function (date) {
            ctrl.onSelectDetailDate({date: date});
        }
    }

    angular
        .module('tyallsApp')
        .controller('SummaryCardRepController', SummaryCardRepController);
}());

