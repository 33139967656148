(function () {

    'use strict';

    InviteConsoleController.$inject = ['InviteService', 'UserService', '$state'];

    function InviteConsoleController(InviteService, UserService, $state) {
        var ctrl = this;

        ctrl.$onInit = function () {
            ctrl.invites = [];
            ctrl.idToUserLookup = {};
        }

        ctrl.$onChanges = function (changes) {
            if (changes.propertyId) {
                InviteService.getWithPropertyId(changes.propertyId.currentValue).then(function (response) {
                    ctrl.invites = response.data;
                })
                    .catch(function (response) {
                        ('oops');
                    });

                // refresh the lookups
                UserService.getWithPropertyId(changes.propertyId.currentValue).then(function (response) {
                    ctrl.idToUserLookup = {};
                    angular.forEach(response.data, function (value) {
                        this[value.id] = value;
                    }, ctrl.idToUserLookup);
                })
                    .catch(function (reason) {

                    });
            }
        }

        ctrl.goToCreateUserInvite = function (propertyId) {
            $state.go('app.prop.app-admin.create-user-invite-dialog', {
                propertyId: ctrl.propertyId
            });
        }
    }

    angular
        .module('tyallsApp')
        .controller('InviteConsoleController', InviteConsoleController);
}());

